//@ts-nocheck
import { useEffect, useState } from 'react';
import './reportSection.css'
import { ActiveReportSectionIcon, CoverageAnalysisIcon, GroundStationAnalysisIcon, Pinicon, ReportSectionIcon } from '../../commonMMSvgs/MissionModelingSvgs';
import GroundStationAccessAnalysis from '../../../MissionModeling/Modals/OrbitalModals/GroundStationAccessAnalysisModal';
import CoverageAnalysisReport from './CoverageAnalysisReport';
import { useSelector } from 'react-redux';
import GroundContactAnalysisReport from './GroundContactAnalysisReport';
import { gs_visualization_presentation } from '../Sidebar/GroundStationCreation/Utils';
import Orbit from '../../../OrbitVisualization/Orbit';

const ReportSection = ({ mm_viewer }) => {
    const coverageAnalysisReport = useSelector(state => state.missionModelling.coverageAnalysisReport)
    const coverageAnalysisOrbit = useSelector(state => state.missionModelling.coverageAnalysisOrbit)
    const groundStationAnalysisReport = useSelector(state => state.missionModelling.groundStationAnalysisReport)
    const gsVisualisationData = useSelector(state => state.missionModelling.gsVisualisationData)

    const latestReport = useSelector(state => state.missionModelling.latestReport)
    const isGeoFeatureView = useSelector(state => state.missionModelling.isGeoFeatureView);
    const [openReportSection, setOpenReposrtSection] = useState(false)
    const [selectedReport, setSelectedReport] = useState(latestReport)
    const allOrbitData = useSelector(state => state.missionModelling.allOrbit)


    useEffect(() => {
        if (openReportSection) {
            gs_visualization_presentation().reset_default_view(mm_viewer)
            if (selectedReport === 'Coverage' && Object.keys(coverageAnalysisReport).length > 0 && Object.keys(coverageAnalysisOrbit).length > 0) {
                // gs_visualization_presentation().strip_mapping(coverageAnalysisReport, mm_viewer, coverageAnalysisOrbit)
                gs_visualization_presentation().strip_mapping(coverageAnalysisReport, mm_viewer, {})
                gs_visualization_presentation().init_all_satellites(allOrbitData, mm_viewer, "Orbit_Object")
            }
            if (selectedReport === 'Ground Contact' && Object.keys(groundStationAnalysisReport).length > 0 && Object.keys(gsVisualisationData).length > 0) {
                gs_visualization_presentation().init_orbit_for_gs_analysis(mm_viewer, gsVisualisationData?.satellite_details, "GS_ORBIT")
            }

        } else {
            gs_visualization_presentation().reset_default_view(mm_viewer)
        }
    }, [openReportSection, selectedReport, coverageAnalysisOrbit, gsVisualisationData])

    useEffect(() => {
        if ((Object.keys(coverageAnalysisReport).length > 0) || (Object.keys(groundStationAnalysisReport).length > 0)) {
            setSelectedReport(latestReport)
            setOpenReposrtSection(true)
        }
    }, [coverageAnalysisReport, groundStationAnalysisReport, latestReport])


    useEffect(() => {
        if (isGeoFeatureView) {
            setOpenReposrtSection(false)
        }
    }, [isGeoFeatureView])

    return (
        <div>
            <div className={`nav_section ${openReportSection ? 'nav_active' : 'close_nav'}`} onClick={() => {
                setOpenReposrtSection(!openReportSection)
            }}>
                {openReportSection ? <ActiveReportSectionIcon /> : <ReportSectionIcon />}
            </div>
            <div className={`report_section_container ${openReportSection ? '' : 'close_report_section'}`}>
                <div className='report_setion_title_part'>
                    <span>
                        Reports
                    </span>
                    {/* <Pinicon /> */}
                </div>

                <div className='report_selection_part'>

                    <div className={`${selectedReport === 'Coverage' ? 'selected_report coverage_report' : 'normal_report_selection'}`} onClick={() => {
                        (Object.keys(coverageAnalysisReport).length > 0) && setSelectedReport("Coverage")
                    }}>
                        <CoverageAnalysisIcon />
                    </div>

                    <div className={`${selectedReport === 'Ground Contact' ? 'selected_report gs_report' : 'normal_report_selection'}`} onClick={() => {
                        (Object.keys(groundStationAnalysisReport).length > 0) && setSelectedReport("Ground Contact")
                    }}>
                        <GroundStationAnalysisIcon />
                    </div>
                </div>
                {selectedReport === "Coverage" && <CoverageAnalysisReport mm_viewer={mm_viewer} />}
                {selectedReport === "Ground Contact" && <GroundContactAnalysisReport mm_viewer={mm_viewer} />}
            </div>
        </div>
    );
};

export default ReportSection;
