//@ts-nocheck
import { Fragment, useEffect, useState } from 'react';
import { CloseArrow, CloseSvg, ConstellationIcon, DeleteIcon, DuplicateIcon, EditIcon, HighlightedSatelliteIcon, InfoIcon, MenuIcon, NormalSatellite, OpenArrow, OpenSatIcon, PlaneIcon, RenameIcon, SaveSvg, SettingIcon } from '../../../commonMMSvgs/MissionModelingSvgs';
import './satelliteCreationTree.css'
import { List } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import DeorbitCoverage from '../../report/DeorbitCoverage';
import MissionModellingService from '../../../MissionModelling.service';
import { useParams } from 'react-router-dom';
import * as mmActions from '../../../../../store/MissionModellingSlice.js'
import ModalInput from '../../../commonComponents/ModalInput';
import ActionsMenu from '../../../commonComponents/ActionsMenu';
import { CircularProgress } from '@mui/material';
import { object } from 'prop-types';
import { gs_visualization_presentation } from '../GroundStationCreation/Utils';
import Dialog from '../../../../CommonComponents/Dialog';

const SatelliteCreationTree = ({ openedCreationModal, mm_viewer }) => {
    const dispatch = useDispatch()
    const params = useParams()

    const {
        mission_id,
        scenario_id
    } = params;

    const constellations = useSelector(state => state.missionModelling.constellations)
    const orbitEclipseReportStatus = useSelector(state => state.missionModelling.orbitEclipseReportStatus)
    const creatingConstellation = useSelector(state => state.missionModelling.creatingConstellation);

    const [activeDataIdList, setActiveDataIdList] = useState<object>({
        activeConstellation: '',
        activeGroup: '',
        activeSatellite: ''
    })
    const [activeMenuItem, setActiveMenuItem] = useState('')
    const [renameState, setRenameState] = useState({
        selectedConstellationId: '',
        selectedConstellationName: ''
    })
    const [show_menu, set_show_menu] = useState<string | boolean>(false)

    const [showDeleteDialog, setShowDeleteDialog] = useState()

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.constellation_tree_main')) {
                setActiveMenuItem('');
            }
        };
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleDeleteConstellation = (constellation) => {
        const type = constellation.type
        dispatch(MissionModellingService.deleteConstellation(constellation.constellationId, mission_id, scenario_id, type))
    }

    const handleDuplicateConstellation = (constellation) => {
        console.log("Scenario Id -> ", scenario_id)
        // dispatch(MissionModellingService.duplicateConstellation(constellation.constellationId, mission_id, scenario_id))
    }

    const handleRenameSave = (constellation) => {
        let body = {
            newName: renameState?.selectedConstellationName
        }
        dispatch(MissionModellingService.renameConstellation(constellation.constellationId, mission_id, scenario_id, body))
    }

    const handleRenameClose = () => {
        setRenameState({
            selectedConstellationId: '',
            selectedConstellationName: ''
        })
    }

    const handleInfo = (constellation) => {
        setActiveMenuItem('')
        dispatch(mmActions.resetIsModalMinimized())
        dispatch(mmActions.setDeorbirEclipseReportName(constellation?.name))
        dispatch(MissionModellingService.getDeorbitEclipseReport(mission_id, constellation.constellationId, scenario_id))
    }

    const handleEdit = (item) => {
        setActiveMenuItem('')
        dispatch(mmActions.resetIsModalMinimized())
        dispatch(mmActions.setEditConstellationObject(item))
    }

    const handleRename = (item) => {
        setActiveMenuItem('')
        setRenameState({
            selectedConstellationId: item?.constellationId,
            selectedConstellationName: item?.name
        })
    }

    const handleDuplicate = (constellation) => {
        dispatch(MissionModellingService.duplicateConstellation(constellation.constellationId, mission_id, scenario_id))
        setActiveMenuItem('')
    }

    const handleDelete = (item) => {
        setActiveMenuItem('')
        setShowDeleteDialog(item)
    }

    const addConstellationForCoverage = (constellation) => {
        if ((openedCreationModal.coverageAnalysisModal || openedCreationModal.groundStationanlysisModal) && !creatingConstellation) {
            dispatch(mmActions.setSelectedConstellationsForCoverage(constellation))
        }
    }

    const handleActiveSatelliteList = (item) => {
        if (activeDataIdList.activeSatellite !== item?.constellationId) {
            setActiveDataIdList({ activeSatellite: item?.constellationId })
        } else {
            setActiveDataIdList({ activeSatellite: '' })
        }
    }

    const handleActiveConstellationList = (item) => {
        if (activeDataIdList.activeConstellation !== item?.constellationId) {
            setActiveDataIdList({ activeConstellation: item?.constellationId })
        } else {
            setActiveDataIdList({ activeConstellation: '' })
        }
    }

    const handleActiveGroupList = (group) => {
        if (activeDataIdList.activeGroup !== group?.id) {
            setActiveDataIdList({ ...activeDataIdList, activeGroup: group?.id })
        } else {
            setActiveDataIdList({ ...activeDataIdList, activeGroup: '' })
        }
    }

    const handleConstellationConfig = (constellation) => {
        setActiveMenuItem('')
        dispatch(mmActions.setEditConstellationConfig(constellation))
    }

    const handleRemoveConstellation = (constellation) => {
        dispatch(mmActions.removeSelectedConstellationsForCoverage(constellation))
    }
    const highlightSatellites = (item, constellation) => {

        if (item?.['groups']) {
            item?.['groups'].forEach(group => {
                // Iterate through the satellites in each group
                if (group?.['satellites']) {
                    gs_visualization_presentation().highlightSatellite(group?.satellites, mm_viewer, constellation)

                }
            });
        }
    }
    return (
        <>
            {constellations?.map((item, index) => {
                if (item?.type === 'Single') {
                    return (<div className={`constellation_tree_main ${activeDataIdList?.activeSatellite === item?.constellationId ? 'active' : ''}`}>
                        <div className='tree_constellation_tree_main_header'
                            onClick={() => {
                                addConstellationForCoverage(item)
                                highlightSatellites(item, constellations)
                            }}
                            onMouseEnter={() => {
                                set_show_menu(item.constellationId)
                            }}
                            onMouseLeave={() => {
                                set_show_menu(false)
                            }}
                        >
                            <div className='left_heder_section' >
                                <div className='left_header_button' onClick={() => {
                                    handleActiveSatelliteList(item)
                                }}>
                                    <OpenArrow />
                                </div>
                                <NormalSatellite />
                                {(renameState?.selectedConstellationId !== item?.constellationId)
                                    ? <span className='left_heder_section_title' title={item?.name}> {item?.name} </span>
                                    :
                                    <ModalInput
                                        isRename={true}
                                        value={renameState.selectedConstellationName}
                                        handleChange={(value) => {
                                            if (value.length <= 64) {
                                                setRenameState({
                                                    ...renameState,
                                                    selectedConstellationName: value
                                                })
                                            }
                                        }}
                                        handleSave={() => {
                                            handleRenameSave(item)
                                        }}
                                        handleClose={handleRenameClose}
                                    />
                                }

                            </div>
                            {(orbitEclipseReportStatus?.[index]?.['overall_status'] === "IN-PROGRESS") &&
                                <div className='right_header_spinner'>
                                    <CircularProgress size={10} className="text-white" />
                                </div>
                            }
                            <div className={`right_header_button ${show_menu === item?.constellationId && 'show'}`} onClick={() => {
                                (orbitEclipseReportStatus?.[index]?.['overall_status'] === "COMPLETED" || orbitEclipseReportStatus?.[index]?.['overall_status'] === "FAILED") && setActiveMenuItem(item?.constellationId)
                            }}
                                onMouseLeave={() => {
                                    setActiveMenuItem('')
                                }}
                            >
                                {(orbitEclipseReportStatus?.[index]?.['overall_status'] === "COMPLETED" || orbitEclipseReportStatus?.[index]?.['overall_status'] === "FAILED") &&
                                    <ActionsMenu
                                        show_menu={show_menu === item?.constellationId ? true : false}
                                        status={orbitEclipseReportStatus?.[index]?.['overall_status']}
                                        component='constellation'
                                        handleInfo={() => {
                                            handleInfo(item)
                                        }}
                                        handleDelete={() => {
                                            handleDelete(item)
                                        }}
                                        handleDuplicate={() => {
                                            handleDuplicate(item)
                                        }}
                                        handleEdit={() => {
                                            handleEdit(item)
                                        }}
                                        handleRename={() => {
                                            handleRename(item)
                                        }}
                                    />
                                }
                            </div>
                        </div>
                        <div className='tree_constellation_tree_main_body'>
                            <div className='tree_constellation_tree_main_body_item' onClick={() => {
                                handleConstellationConfig(item)
                            }}>
                                <SettingIcon />
                                <span> Config </span>
                            </div>
                        </div>
                    </div>)
                } else {
                    return (
                        <div
                            key={index}
                            className={`constellation_tree_main ${activeDataIdList?.activeConstellation === item?.constellationId ? 'active' : ''}`}
                            onClick={() => {
                                addConstellationForCoverage(item)
                                highlightSatellites(item)
                            }}
                        >
                            <div className='tree_constellation_tree_main_header'
                                onMouseEnter={() => {
                                    set_show_menu(item.constellationId)
                                }}
                                onMouseLeave={() => {
                                    set_show_menu(false)
                                }}
                            >
                                <div className='left_heder_section' >
                                    <div className='left_header_button' onClick={() => {
                                        handleActiveConstellationList(item)
                                    }}>
                                        <OpenArrow />
                                    </div>
                                    <ConstellationIcon />
                                    {(renameState?.selectedConstellationId !== item?.constellationId) ?
                                        <span className='left_heder_section_title' title={item?.name}> {item?.name} </span> :
                                        <ModalInput
                                            isRename={true}
                                            value={renameState.selectedConstellationName}
                                            handleChange={(value) => {
                                                if (value.length <= 64) {
                                                    setRenameState({
                                                        ...renameState,
                                                        selectedConstellationName: value
                                                    })
                                                }
                                            }}
                                            handleSave={() => {
                                                handleRenameSave(item)
                                            }}
                                            handleClose={handleRenameClose}
                                        />
                                    }
                                </div>

                                {(orbitEclipseReportStatus?.[index]?.['overall_status'] === "IN-PROGRESS") &&
                                    <div className='right_header_spinner'>
                                        <CircularProgress size={10} className="text-white" />
                                    </div>
                                }

                                <div className={`right_header_button ${show_menu === item?.constellationId && 'show'}`} onClick={() => {
                                    (orbitEclipseReportStatus?.[index]?.['overall_status'] === "COMPLETED" || orbitEclipseReportStatus?.[index]?.['overall_status'] === "FAILED") && setActiveMenuItem(item?.constellationId)
                                }}
                                    onMouseLeave={() => {
                                        setActiveMenuItem('')
                                    }}
                                >
                                    {(orbitEclipseReportStatus?.[index]?.['overall_status'] === "COMPLETED" || orbitEclipseReportStatus?.[index]?.['overall_status'] === "FAILED") &&
                                        <ActionsMenu
                                            show_menu={show_menu === item?.constellationId ? true : false}
                                            status={orbitEclipseReportStatus?.[index]?.['overall_status']}
                                            component='constellation'
                                            handleInfo={() => {
                                                handleInfo(item)
                                            }}
                                            handleDelete={() => {
                                                handleDelete(item)
                                            }}
                                            handleDuplicate={() => {
                                                handleDuplicate(item)
                                            }}
                                            handleEdit={() => {
                                                handleEdit(item)
                                            }}
                                            handleRename={() => {
                                                handleRename(item)
                                            }}
                                        />
                                    }
                                </div>
                            </div>
                            <div className='tree_constellation_tree_main_body'>
                                <div className='tree_constellation_tree_main_body_item' onClick={() => {
                                    handleConstellationConfig(item)
                                }}>
                                    <SettingIcon />
                                    <span> Config </span>
                                </div>
                                {/* Mapping the Plane */}

                                {
                                    item?.groups?.map((group, index) => {
                                        return (
                                            <div className='constellation_tree_main'>
                                                <div className='tree_constellation_tree_main_header'>
                                                    <div className='left_heder_section' onClick={() => {
                                                        handleActiveGroupList(group)
                                                    }}>
                                                        <div className='left_header_button'>
                                                            {group.id === activeDataIdList.activeGroup ? <OpenArrow /> : <CloseArrow />}
                                                        </div>
                                                        <ConstellationIcon />
                                                        <span className='left_heder_section_title'> {group?.name} </span>
                                                    </div>
                                                </div>
                                                {group.id === activeDataIdList.activeGroup && <div className='tree_constellation_tree_main_body'>
                                                    <div className='tree_constellation_tree_main_body_item'>
                                                        <SettingIcon />
                                                        <span> Config </span>
                                                    </div>
                                                    {/* Mapping The Satellite */}
                                                    {
                                                        group?.satellites?.map((satellite, index) => {
                                                            return (
                                                                <div className='constellation_tree_main'>
                                                                    <div className='tree_constellation_tree_main_header'>
                                                                        <div className='left_heder_section' onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            setActiveDataIdList({
                                                                                ...activeDataIdList,
                                                                                activeSatellite: satellite?.name
                                                                            })
                                                                            gs_visualization_presentation().highlightSatellite(satellite, mm_viewer)
                                                                        }}>
                                                                            <div className='left_header_button'>
                                                                                {/* <OpenArrow /> */}
                                                                            </div>
                                                                            <NormalSatellite />
                                                                            <span className='left_heder_section_title'> {satellite?.name} </span>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className='tree_constellation_tree_main_body'>
                                                                        <div className='tree_constellation_tree_main_body_item'>
                                                                            <SettingIcon />
                                                                            <span> Config </span>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                }
            })}

            <Dialog
                onClick={(event) => {
                    if (event) {
                        handleDeleteConstellation(showDeleteDialog)
                        handleRemoveConstellation(showDeleteDialog)
                        setShowDeleteDialog()
                    }
                    setShowDeleteDialog()
                }}
                openModel={showDeleteDialog}
                comment={`Do you want to delete ${showDeleteDialog?.name}?`}
            />
        </>
    );
};

export default SatelliteCreationTree;
