import jwt_decode from "jwt-decode";
import UserService from "../../service/UserService";
import { setMissions, setProjectsData, setUserData } from "../../store/MissionSlice";

const get_user_data = (dispatch, user_data, navigate, setLoading) => {
    UserService.get_User_By_Tenant(user_data?.['owner_org'])?.then(data => {
        if (data?.data) {
            let update_user_data = {
                ...user_data,
                'isBetaVersion': data.data?.['isBetaVersion'],
                'feature-allowed': data.data?.['feature-allowed'],
                'owner_name': data.data?.['ownerOrg'],
            }
            sessionStorage.setItem("userDetails", JSON.stringify(update_user_data));
            dispatch(setUserData(update_user_data));
        }
    }).catch((err) => {
        console.log(err);
    }).finally(()=>{
        setLoading(false);
        const path = `${process.env.PUBLIC_URL}/`
        navigate(path);
    })
}

export const updateUserToken = (token, owner_name ,dispatch,navigate,setLoading) => {
    UserService.getToken(token)?.then(newToken => {
        const { access_token, refresh_token }= newToken.data
        sessionStorage.setItem('authentication', access_token);
        sessionStorage.setItem('refreshToken', refresh_token);
        // New token decoded 
        const decoded = jwt_decode(access_token);
        if(decoded.hasOwnProperty('owner_org') && decoded?.['owner_org'] !== ''){
            let owner_org_list = decoded?.ownerOrgList && decoded?.ownerOrgList !== ''  ? JSON.parse(decoded?.ownerOrgList) : []
            let user_roles = []
            if(decoded?.['resource_access']?.['ATMOS-RESOURCE-SERVER']?.['roles'].includes('super_admin')){
                user_roles = decoded?.['resource_access']?.['ATMOS-RESOURCE-SERVER']?.['roles']
            }
            owner_org_list.forEach(org => {
                if(decoded?.['owner_org'] === org?.['owner_org']){
                    user_roles = [org?.['role']]
                }
            })
            let updateUserData = {
                ...decoded,
                'owner_name': owner_name,
                'user_roles': user_roles,
                'url': `/`
            }
            get_user_data(dispatch, updateUserData, navigate, setLoading);
        }else{
            let updateUserData = {
                ...decoded,
                'owner_name': '',
                'owner_org': '',
                'user_roles': decoded?.['resource_access']?.['ATMOS-RESOURCE-SERVER']?.['roles'],
                'url': '/super_admin_dashboard'
            }
            sessionStorage.setItem('userDetails', JSON.stringify(updateUserData));
            dispatch(setMissions([]));
            dispatch(setProjectsData([]));
            const path = `${process.env.PUBLIC_URL}/super_admin_dashboard`
            dispatch(setUserData(updateUserData));
            setLoading(false);
            navigate(path);
        }
    }).catch(err => {
        console.error(err);
        setLoading(false);
    });
}