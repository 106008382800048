// @ts-nocheck
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MissionStore } from '../../../store/MissionSlice';

const PyloadServer = ({ enableBusSlider, selectedComp, setSelectedComp, selectedComp_UId, setSelectedComp_UId }) => {

    const [template, setTemplate] = useState<any>({});
    const [getPayloads, setPayloads] = useState<any[]>([]);
    const [getEdges, setEdges] = useState<any[]>([]);

    const isCloneTemplate = useSelector((state: { mission: MissionStore }) => state.mission.isCloneTemplate);
    const isNewTemplate = useSelector((state: { mission: MissionStore }) => state.mission.isNewTemplate);
    const CloneTemplate = useSelector((state: { mission: MissionStore }) => state.mission.CloneTemplate);
    const Satellite = useSelector((state: { mission: MissionStore }) => state.mission.Satellite);

    useEffect(() => {
        setTemplate(Satellite?.Template);
        setPayloads(Satellite?.Payloads);
        setEdges(Satellite?.Edges);
    }, []);

    return <div className="config-data-box h-100">
        {!isCloneTemplate && getPayloads?.map((data, i) => {
            const uniqueKey = `${data?.['user_data']?.['payload_name']}-${i}`;
            return <div className={`config-card ${selectedComp_UId === uniqueKey && 'active'}`}
                onClick={() => {
                    const payload = {
                        ...data?.['atmos-data'],
                        name: data?.['user_data']?.['payload_name']
                    }
                    setSelectedComp(data?.['user_data']?.['payload_name'])
                    setSelectedComp_UId(uniqueKey)
                    enableBusSlider(payload, 'Payload', 'Payload Server', 0)
                }} key={i}>
                <h5 className="c-color1">
                    <span className="me-2">
                        <img src={data["atmos-data"].src} />
                    </span>
                    {data['user_data']?.['payload_name']}
                </h5>
                <div>
                    {data['atmos-data'].name}
                </div>
            </div>
        })}

        {!isCloneTemplate && getEdges?.length > 0 && getEdges?.map((data, i) => {
            const uniqueKey = `${data?.['edge-device']?.['atmos-data']?.['name']}-${i}`;
            const uniquekey1 = `${data?.['edge-ssd']?.['atmos-data']?.['name']}-${i}`;
            return (
                <>
                    <div className={`config-card ${selectedComp_UId === uniqueKey && 'active'}`}
                        onClick={() => {
                            setSelectedComp(data?.['edge-device']?.['atmos-data']?.['name'])
                            setSelectedComp_UId(uniqueKey)
                            enableBusSlider(data?.['edge-device']?.['atmos-data'], 'Edge Device', 'Edge Device', 0)
                        }}>
                        <h5 className="c-color1">
                            Edge
                        </h5>
                        <div>
                            {data?.['edge-device']?.['atmos-data']?.['name']}
                        </div>
                    </div>
                    <div className={`config-card ${selectedComp_UId === uniquekey1 && 'active'}`}
                        onClick={() => {
                            setSelectedComp(data?.['edge-ssd']?.['atmos-data']?.['name'])
                            setSelectedComp_UId(uniquekey1)
                            enableBusSlider(data?.['edge-ssd']?.['atmos-data'], 'Edge SSD', 'Edge SSD', 0)
                        }} key={i}>
                        <h5 className="c-color1">
                            Edge SSD ({data?.['edge-ssd']?.['user_data']?.['numUnits']})
                        </h5>
                        <div>
                            {data?.['edge-ssd']?.['atmos-data']?.['name']}
                        </div>
                    </div>
                </ >
            )
        })}
    </div>;
};
export default PyloadServer;
