// @ts-nocheck
import { useDispatch, useSelector } from "react-redux";

// imports related to file upload 
import { read } from 'shapefile';
import jszip from 'jszip';
import * as topojson from 'topojson-client';
import { kml } from '@tmcw/togeojson';
import TerraformerWKTParser from 'terraformer-wkt-parser';

import * as mapActions from "../../../store/MapSlice";
import * as ucpActions from "../../../store/UnifiedControlPlaneSlice";

import "./UcpComponents.css";

const FeaturesBar = ({ props }) => {

  const dispatch = useDispatch();

  const mode = useSelector(state => state.map.mode);
  // const showAoiConfigPopup = useSelector(state => state.map.showAoiConfigPopup);

  // const aois = useSelector(state => state.unifiedControlPlane.aois);
  // const selectedFeature = useSelector(state => state.unifiedControlPlane.selectedFeature);

  const cleanUpDrawnFeatures = () => {
    dispatch(mapActions.resetLocalAoiItem());
    dispatch(mapActions.resetDrawnFeatureInfo());
    dispatch(mapActions.cleanUpDraw());
    dispatch(ucpActions.resetUploadedFeatures());
  };

  const drawFeatures = (state) => {
    dispatch(mapActions.drawAOI(state.map.mode));
  };

  // Function to convert MultiPolygon to Polygon
  const convertMultiPolygonToPolygon = (multiPolygon) => {
    let polygons = [];
    for (const coordinate of multiPolygon.coordinates) {
      let featureShape = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: coordinate,
        },
      };
      polygons.push(featureShape);
    }
    return polygons;
  };

  const handleFileUpload = async (e) => {
    let geojson = {};
    const file = e.target.files[0];
    const formatFileName = file && file.name.toLowerCase();

    if (formatFileName) {
      if (formatFileName.endsWith('.zip')) {
        const arrayBuffer = await file.arrayBuffer();
        const uploadedFiles = await jszip.loadAsync(arrayBuffer);
        const files = Object.values((uploadedFiles && uploadedFiles.files));
        const features = [];
        if (files.length > 0) {
          for (const file of files) {
            if (file.name.endsWith('.shp')) {
              const shapefileBuffer = await file.async('arraybuffer');
              const shapeData = await read(shapefileBuffer);
              const geometry = shapeData.features[0].geometry;
              if (geometry.type === 'MultiPolygon' && geometry.coordinates.length > 1) {
                features.push(...convertMultiPolygonToPolygon(geometry));
              } else {
                features.push((shapeData && shapeData.features[0]));
              }
            }
          }
        }
        geojson = {
          type: 'FeatureCollection',
          features,
        };
      } else if (formatFileName.endsWith('.geojson') || formatFileName.endsWith('.json')) {
        const text = await file.text();
        geojson = JSON.parse(text);
      } else if (formatFileName.endsWith('.topojson')) {
        const text = await file.text();
        const topojsonData = JSON.parse(text);
        const firstObjectKey = Object.keys(topojsonData.objects)[0];
        const firstObject = topojsonData.objects[firstObjectKey];
        geojson = topojson.feature(topojsonData, firstObject);
      } else if (formatFileName.endsWith('.kml')) {
        const text = await file.text();
        const parser = new DOMParser();
        const kmlData = parser.parseFromString(text, 'application/xml');
        geojson = kml(kmlData);
      } else if (formatFileName.endsWith('.wkt')) {
        const text = await file.text();
        const parsedJson = TerraformerWKTParser.parse(text);
        geojson = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: parsedJson,
              properties: {},
            },
          ],
        };
      } else {
        console.error('Unsupported file format');
      }
    }
    if (Object.keys(geojson).length > 0) {
      // setFileName(formatFileName);
      dispatch(ucpActions.setUploadedFeatures(geojson));
    }
  };

  const selectAction = (action) => {
    // if (Object.keys(selectedFeature).length > 0) dispatch(mmActions.resetSelectedFeature());
    // if (showAoiConfigPopup) dispatch(mapActions.resetAoiConfigPopup());
    if (action === 'simple_select' ||
      action === 'upload_file' ||
      action === 'delete_aoi') {
      dispatch(mapActions.selectMode(action, cleanUpDrawnFeatures));
    } else {
      dispatch(mapActions.selectMode(action, drawFeatures));
      // const local_aoi_name = `Area of Interest ${aois.length + 1}`;
      // dispatch(mapActions.setLocalAoiItem(local_aoi_name));
    }
  };

  const exitGeoFeature = () => {
    // dispatch(mmActions.resetGeoFeatureView());
    dispatch(mapActions.resetMode());
    cleanUpDrawnFeatures();
  };

  return (
      <div className="ucpFeaturesBar">
        <button className={`ucpFeaturesBarBtn ${mode === 'draw_polygon' ? 'active-action' : ''}`}
          onClick={() => selectAction('draw_polygon')}
        >
          <img src={require("../ImgAssets/Draw.svg").default} />
        </button>
        <button className={`ucpFeaturesBarBtn ${mode === 'draw_point' ? 'active-action' : ''}`}
        onClick={() => selectAction('draw_point')}
        >
          <img src={require("../ImgAssets/Point.svg").default} />
        </button>
        <button className={`ucpFeaturesBarBtn ${mode === 'upload_file' ? 'active-action' : ''}`}
          onClick={() => selectAction('upload_file')}
        >
          <input
            id="upload"
            name="uploadFile"
            type="file"
            accept=".zip,.geojson,.json,.topojson,.kml,.wkt"
            onChange={handleFileUpload}
            style={{ display: 'none' }}
          />
          <label
            className="uploadIcon"
            htmlFor="upload">
            <img src={require("../ImgAssets/Upload.svg").default} />
          </label>
        </button>
        <button className={`ucpFeaturesBarBtn ${mode === 'delete_aoi' ? 'active-action' : ''}`}
          onClick={() => selectAction('delete_aoi')}
        >
          <img src={require("../ImgAssets/Delete.svg").default} />
        </button>
      </div>
  );
};

export default FeaturesBar;
