export function getEnvVar(varName: string): string {
    const defaults: { [key: string]: string } = {
        REACT_APP_ENVIRONMENT: "DEVELOPMENT",
        REACT_APP_PRIVATE_KEY: "DEV_KEY",
        REACT_APP_BACKEND_BASE_URL: "http://localhost:21000/api/",
        REACT_APP_KEYCLOAK_URL: "http://20.70.1.251:23000/",
        REACT_APP_KEYCLOAK_REALM: "ATMOS",
        REACT_APP_KEYCLOAK_RESOURCE_CLIENT: "ATMOS-RESOURCE-SERVER",
        REACT_APP_MAPBOX_TOKEN: "pk.eyJ1Ijoibml0ZXNoMjlnb3RoZSIsImEiOiJjbHI5ZG9jaGgwMmNmMmxxaWJjbmFpMWRxIn0.g4GWfpY1-Q0Lmm0-1hKNxg",
        REACT_APP_MAPBOX_STYLE_URL: 'mapbox://styles/nitesh29gothe/cm21te2t7001f01pd19sjbwx0'
    };

    return window._env_ && window._env_[varName] ? window._env_[varName] : defaults[varName];
}
