//@ts-nocheck
import { useEffect, useState } from 'react';
import ModalFooter from '../../../commonComponents/ModalFooter';
import ModalHeader from '../../../commonComponents/ModalHeader';
import ModalInput from '../../../commonComponents/ModalInput';
import { ConfigSatIcon } from '../../../commonMMSvgs/MissionModelingSvgs';
import './satelliteConfig.css'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MissionModellingService from '../../../MissionModelling.service';
import { toast } from 'react-toastify';

const SatelliteConfig = ({ closeModalCreation }) => {
    const dispatch = useDispatch()
    const params = useParams()

    const { mission_id, scenario_id } = params;
    const editConstellationConfig = useSelector(state => state.missionModelling.editConstellationConfig)
    const isModalMinimized = useSelector(state => state.missionModelling.isModalMinimized);

    const [swath, setSwath] = useState('')
    const [orientation, setOrientation] = useState('nadir')

    const checkIsValid = () => {
        if (swath?.length > o && orientation?.length > 0) {
            return true
        } else {
            return false
        }
    }

    const handleSubmit = () => {
        let isValidSwath = false;
        let maxLimit;

        let swathRange = parseFloat(parseFloat(editConstellationConfig.groups[0].referenceOrbit?.semiMajorAxis) - 6371)

        if (swathRange >= 300 && swathRange < 350) {
            if (swath <= 312.34 && swath >= 5) {
                isValidSwath = true;
                maxLimit = 312.34;
            }
        } else if (swathRange >= 350 && swathRange < 400) {
            if (swath <= 364.40 && swath >= 5) {
                isValidSwath = true;
                maxLimit = 364.40;
            }
        } else if (swathRange >= 400 && swathRange < 450) {
            if (swath <= 416.45 && swath >= 5) {
                isValidSwath = true;
                maxLimit = 416.45;
            }
        } else if (swathRange >= 450 && swathRange < 500) {
            if (swath <= 468.51 && swath >= 5) {
                isValidSwath = true;
                maxLimit = 468.51;
            }
        } else if (swathRange >= 500 && swathRange < 550) {
            console.log("Switching --> ", swath);
            if (swath <= 520.57 && swath >= 5) {
                isValidSwath = true;
                maxLimit = 520.57;
            }
        } else if (swathRange >= 550 && swathRange < 600) {
            if (swath <= 572.6 && swath >= 5) {
                isValidSwath = true;
                maxLimit = 572.6;
            }
        } else if (swathRange >= 600 && swathRange < 650) {
            if (swath <= 624.68 && swath >= 5) {
                isValidSwath = true;
                maxLimit = 624.68;
            }
        } else if (swathRange >= 650 && swathRange < 700) {
            if (swath <= 676.74 && swath >= 5) {
                isValidSwath = true;
                maxLimit = 676.74;
            }
        } else if (swathRange >= 700 && swathRange < 750) {
            if (swath <= 728.79 && swath >= 5) {
                isValidSwath = true;
                maxLimit = 728.79;
            }
        } else if (swathRange >= 750 && swathRange < 800) {
            if (swath <= 780.85 && swath >= 5) {
                isValidSwath = true;
                maxLimit = 780.85;
            }
        }


        let satelliteSwath = editConstellationConfig?.groups?.[0]?.satellites?.[0]?.config

        let body = {
            config: {
                swath: swath,
                orientation: orientation
            }
        }

        if (isValidSwath) {
            dispatch(MissionModellingService.editConstellationConfig(editConstellationConfig?.constellationId, mission_id, scenario_id, body, closeModalCreation))
        } else {
            if (maxLimit) {
                toast.error('Swath Limit shoud be between 5 - ', maxLimit)
            } else {
                toast.error('Swath Exceeds the Max Limit')
            }
        }

    }

    useEffect(() => {
        if (Object.keys(editConstellationConfig).length > 0) {
            setSwath(editConstellationConfig?.groups[0]?.satellites[0]?.config?.swath)
        }
    }, [editConstellationConfig])

    return (
        <>
            <ModalHeader
                icon={<ConfigSatIcon />}
                title={'Config'}
                handleClose={() => { closeModalCreation() }}
            />

            {!isModalMinimized && (<>
                <div className='satellite_config_item'>
                    <span>Swath</span>
                    <ModalInput
                        unit='km'
                        value={swath}
                        handleChange={(value) => {
                            setSwath(value)
                        }}
                    />
                </div>
                {/* <div className='satellite_config_item'>
                <span>Orientation</span>
                <ModalInput
                    value={orientation}
                    handleChange={() => { }}
                />
                </div> */}

                <ModalFooter
                    isValid={() => { checkIsValid() }}
                    closingText={'Close'}
                    actionText={'Save'}
                    loadingText={'Saving ...'}
                    handleSubmit={() => { handleSubmit() }}
                    handleClose={() => { closeModalCreation() }}
                />
            </>)}
        </>
    );
};

export default SatelliteConfig;
