import { useState } from "react";
import Draggable from "react-draggable";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

const PolicyCreationModal = ({ props }) => {

  const [policyItemsInfo,setPolicyItemsInfo] = useState({
    Policy_Title: "",
    Triggers: {
      Vessels: false,
      Aircraft: false,
      Unknown_Object: {
        selected: false,
        size: 0,
      }
    },
    Actions: {
      Notify_Ground: false,
      Revisits: {
        selected: false,
        frequency: "",
      },
      Imaging_Custom_Configuration: {
        selected: false,
        data_type: ""
      }
    }
  })

  const [isModalMinimized, setIsModalMinimized] = useState(false);
  const [policyName, setPolicyName] = useState("");

  const closePolicyModal = () => {
    props.setIsPolicyBarVisible(true);
    props.setIsNewPolicyCreationModal(false);
  };

  const policyTypeOptions = ["Object Identification", "Disaster & Event", "Custom"];
  const [selectedPolicyTypeOption, setSelectedPolicyTypeOption] = useState("");

  const [disasterEventTrigger, setDisasterEventTrigger] = useState({ Fire: false, Smoke: false, Storm: false, Flood: false });
  const [objectIdentificationTrigger, setObjectIdentificationTrigger] = useState({ Vessels: false, Aircraft: false, Unknown_Object: false });
  const [actionOptions, setActionOptions] = useState({ Notify_Ground: false, Revisits: false, Imaging_Custom_Configuration: false });

  const revisitsOptions = ["Every Day", "<1 week", ">1 week"];
  const [selectedRevisitsOptions, setSelectedRevisitsOptions] = useState("");

  const imagingConfigOptions = ["Every Day", "<1 week", ">1 week"];
  const [selectedImagingConfigOptions, setSelectedImagingConfigOptions] = useState("");

  const RenderSelectableTriggerOptions = ({ array, item, setDisasterEventTrigger }) => (
    <div style={{ width: "98%" }}>
      <div className={array ? "floatLeft" : "floatLeft lowerOpacity"}>
        <button className="modalInputRoundedBoxButton" onClick={() => setDisasterEventTrigger((prev) => ({ ...prev, [item]: !array }))}></button>
        {array && <img className="singleTickIcon" src={require("../ImgAssets/SingletickIcon.svg").default} />}
        <div className={!array ? "modalInputBoxText" : "modalInputBoxText noPadding"} style={{ fontSize: "11px" }}>
          {item.toString().replace(/_/g, " ")}
        </div>
      </div>
      {item === "Unknown_Object" && (
        <input type="number" className="modalInputFieldFullLen modalInputFieldRightFloat" placeholder="Enter Size in m" style={{ maxWidth: "120px" }}></input>
      )}
    </div>
  );

  const RenderSelectableActionOptions = ({ array, item, setActionOptions }) => (
    <>
      <div className="modalInputBox" style={{ margin: "4px 0px", width: "100%", display: "flex" }}>
        <div style={{ width: "98%" }}>
          <div className={array ? "floatLeft" : "floatLeft lowerOpacity"}>
            <button className="modalInputRoundedBoxButton" onClick={() => setActionOptions((prev) => ({ ...prev, [item]: !array }))}></button>
            {array && <img className="singleTickIcon" src={require("../ImgAssets/SingletickIcon.svg").default} />}
            <div className={!array ? "modalInputBoxText" : "modalInputBoxText noPadding"} style={{ fontSize: "11px" }}>
              {item.toString().replace(/_/g, " ")}
            </div>
          </div>
        </div>
      </div>
      {actionOptions.Revisits && item === "Revisits" && (
        <>
          <div className="modalDivLine modalInputTitleWhite subHeader" style={{ padding: "0px 12px" }}>
            Revisit every
          </div>
          <div className="UcpModalSection">
            <Dropdown
              className="reactDropdownRootClass"
              controlClassName="reactDropdownClass"
              options={revisitsOptions}
              value={selectedRevisitsOptions}
              onChange={setSelectedRevisitsOptions}
              menuClassName="reactDropdownMenuClass"
              arrowClassName="reactDropdownArrowClassName"
              placeholder="Select Frequency"
            />
          </div>
        </>
      )}
      {actionOptions.Imaging_Custom_Configuration && item === "Imaging_Custom_Configuration" && (
        <>
          <div className="UcpModalSection" style={{ padding: "6px 12px" }}>
            <Dropdown
              className="reactDropdownRootClass"
              controlClassName="reactDropdownClass"
              options={imagingConfigOptions}
              value={selectedImagingConfigOptions}
              onChange={setSelectedImagingConfigOptions}
              menuClassName="reactDropdownMenuClass"
              arrowClassName="reactDropdownArrowClassName"
              placeholder="Select Data Type"
            />
          </div>
        </>
      )}
    </>
  );

  return (
    <Draggable>
      <div className="orderCreationModal policyCreationModal">
        <div className="orderCreationModalHeader bottomBorder">
          <div className="orderCreationModalHeaderText">Policy</div>
          <button onClick={closePolicyModal}>
            <img src={require("../ImgAssets/CloseBtn.svg").default} />
          </button>
          <button onClick={() => setIsModalMinimized(!isModalMinimized)} className="minimizeIcon">
            {!isModalMinimized && <img src={require("../ImgAssets/MinimizeIcon.svg").default} />}
            {isModalMinimized && <img src={require("../ImgAssets/MaximizeIcon.svg").default} />}
          </button>
        </div>
        {!isModalMinimized && (
          <>
            <div className="activeSectionModal modalScrollableSection">
              <div className="modalDivLine" style={{ paddingBottom: "12px" }}>
                <div className="UcpModalSection">
                  <Dropdown
                    className="reactDropdownRootClass"
                    controlClassName="reactDropdownClass"
                    options={policyTypeOptions}
                    value={selectedPolicyTypeOption}
                    menuClassName="reactDropdownMenuClass"
                    arrowClassName="reactDropdownArrowClassName"
                    placeholder="Select / Create Policy"
                    onChange={setSelectedPolicyTypeOption}
                  />
                  {console.log(selectedPolicyTypeOption)}
                </div>

                {["Object Identification", "Disaster & Event"].includes(selectedPolicyTypeOption.label) && (
                  <div className="UcpModalSection lowTopPadding">
                    <div className="modalDivLine modalInputTitleWhite subHeader greenSubHeader" style={{ padding: "4px 0px 8px" }}>
                      Trigger
                    </div>
                    <div className="modalInputBox" style={{ margin: "4px 0px", width: "100%", display: "flex" }}>
                      {selectedPolicyTypeOption.label === "Object Identification" && (
                        <>
                          {Object.entries(objectIdentificationTrigger).map(([item, array]) => (
                            <>
                              {item != "Unknown_Object" && (
                                <RenderSelectableTriggerOptions key={item} array={array} item={item} setDisasterEventTrigger={setObjectIdentificationTrigger} />
                              )}
                            </>
                          ))}
                        </>
                      )}
                      {selectedPolicyTypeOption.label === "Disaster & Event" && (
                        <>
                          {Object.entries(disasterEventTrigger).map(([item, array]) => (
                            <RenderSelectableTriggerOptions key={item} array={array} item={item} setDisasterEventTrigger={setDisasterEventTrigger} />
                          ))}
                        </>
                      )}
                    </div>
                    <div className="modalInputBox" style={{ margin: "4px 0px", width: "100%", display: "flex" }}>
                      {selectedPolicyTypeOption.label === "Object Identification" && (
                        <>
                          {Object.entries(objectIdentificationTrigger).map(([item, array]) => (
                            <>
                              {item === "Unknown_Object" && (
                                <RenderSelectableTriggerOptions key={item} array={array} item={item} setDisasterEventTrigger={setObjectIdentificationTrigger} />
                              )}
                            </>
                          ))}
                        </>
                      )}
                    </div>
                    <div className="modalDivLine modalInputTitleWhite subHeader greenSubHeader" style={{ padding: "4px 0px 8px" }}>
                      Action
                    </div>
                    {Object.entries(actionOptions).map(([item, array]) => (
                      <RenderSelectableActionOptions key={item} array={array} item={item} setActionOptions={setActionOptions} />
                    ))}
                  </div>
                )}
                {selectedPolicyTypeOption.label === "Custom" && (
                  <div className="UcpModalSection lowTopPadding">
                    <div className="modalDivLine modalInputTitleWhite subHeader greenSubHeader" style={{ padding: "4px 0px 8px" }}>
                      Policy title
                    </div>
                    <input className="modalInputFieldFullLen" placeholder="Enter your Policy Title"></input>
                    <div className="modalDivLine modalInputTitleWhite subHeader greenSubHeader" style={{ padding: "4px 0px 8px" }}>
                      Trigger Logic
                    </div>
                    <button className="addObjectsBtn" style={{ marginBottom: "12px" }}>
                    <img src={require("../ImgAssets/WhiteUploadIcon.svg").default} style={{marginRight: "8px"}}/>
                      Upload File
                    </button>
                    <div className="modalDivLine modalInputTitleWhite subHeader greenSubHeader" style={{ padding: "4px 0px 8px" }}>
                      Action
                    </div>
                    {Object.entries(actionOptions).map(([item, array]) => (
                      <RenderSelectableActionOptions key={item} array={array} item={item} setActionOptions={setActionOptions} />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <div className="orderCreationModalFooter topBorder">
          <button className="modalFooterCloseBtn">Cancel</button>
          <button className="modalFooterApplyBtn">Apply</button>
        </div>
      </div>
    </Draggable>
  );
};

export default PolicyCreationModal;
