import useUrlParams from '../../hooks/useUrlParams';
import './CommandCenter.scss'
import Hud from './hud/Hud';
import ModalProvider from './modals/ModalProvider';
import Monitor from './monitor/Monitor';
import Planning from './planning/Planning';

const CommandCenter = () => {
  const {
    tab_name
  } = useUrlParams()

  return (
    <div className='command__center_container'>
      {/* // Contact Section // */}
      {tab_name === 'hud' && <Hud />}
      {tab_name?.includes('plan') && <Planning />}
      {tab_name === 'monitor' && <Monitor />}
      {/* // Contact Section // */}
      {/* // Modals Section // */}
      <ModalProvider />
      {/* // Modals Section // */}
    </div>
  )
}

export default CommandCenter