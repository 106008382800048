import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    mode: 'simple_select',
    coordinates: [],
    geometry: {},
    showAoiConfigPopup: false,
    localAoiItem: '',
    showLocalAoiItem: false,
    prompPosition: { x: 0, y: 0 },
};

// Helper function to calculate the prompt position
const calculatePromptPosition = (mapRef, coordinates, isPolygon = false) => {
    let x, y, rectangle;
    if (isPolygon) {
        // Calculate bounding box for polygon
        rectangle = coordinates[0].reduce((acc, coord) => ({
            minX: Math.min(acc.minX, coord[0]),
            maxX: Math.max(acc.maxX, coord[0]),
            minY: Math.min(acc.minY, coord[1]),
            maxY: Math.max(acc.maxY, coord[1]),
        }), { minX: Infinity, maxX: -Infinity, minY: Infinity, maxY: -Infinity });

        // For polygon, take the max X and center Y
        x = rectangle.maxX + 10;
        y = (rectangle.minY + rectangle.maxY) / 2;
    } else {
        // For point, directly use the coordinates
        [x, y] = coordinates;
    }

    // Validate x and y to ensure they are numbers
    if (isNaN(x) || isNaN(y)) {
        console.error("Invalid coordinates:", { x, y });
        return { left: '0px', top: '0px' }; // Return a default value
    }

    // Project the point using mapbox's project method
    const point = mapRef.project([x, y]);
    const canvasWidth = mapRef.getCanvas().clientWidth;
    const promptWidth = 100;

    // Adjust X position if it exceeds canvas width
    if ((canvasWidth - point.x) < promptWidth) {
        point.x = mapRef.project([isPolygon ? rectangle && rectangle.minX : x, y]).x - promptWidth;
    }

    // Adjust Y position based on boundaries
    if (point.y < 50) {
        point.y += 47;
    } else if (point.y > 573 && point.y <= 709) {
        point.y -= 125;
    } else if (point.y > 709) {
        point.y -= 170;
    }

    return { x: point.x + (isPolygon ? 0 : 50), y: point.y };
};

const mapSlice = createSlice({
    name: 'mapSlice',
    initialState,
    reducers: {
        setMapRef: (state, action) => {
            state.mapRef = action.payload;
        },
        setDrawRef: (state, action) => {
            state.drawRef = action.payload;
        },
        cleanUpDraw: (state) => {
            if (state.drawRef) {
                state.drawRef?.deleteAll();
                state.drawRef?.changeMode('simple_select');
            }
        },
        drawAOI: (state, action) => {
            if (state.drawRef) {
                state.drawRef.deleteAll();
                state.drawRef.changeMode(action.payload);
            }
        },
        zoomIn: (state) => {
            if (state.mapRef) {
                state.mapRef.zoomIn();
            }
        },
        zoomOut: (state) => {
            if (state.mapRef) {
                state.mapRef.zoomOut();
            }
        },
        setViewport: (state, action) => {
            if (state.mapRef) {
                state.mapRef.fitBounds(action.payload, {
                    padding: { top: 200, bottom: 200, left: 400, right: 200 }
                });
            }
        },
        setPosition: (state, action) => {
            if (state.mapRef) {
                state.mapRef.flyTo({ center: action.payload });
            }
        },
        setMode: (state, action) => {
            state.mode = action.payload;
        },
        resetMode: (state) => {
            state.mode = 'simple_select';
        },
        setDrawnFeatureInfo: (state, action) => {
            const featureInfo = action.payload;
            let coordinates = [];
            if (featureInfo.length === 2) {
                coordinates.push(featureInfo.coordinates[0]);
                coordinates.push(featureInfo.coordinates[1]);
                state.coordinates = [...state.coordinates, coordinates];
            } else {
                coordinates = featureInfo.coordinates;
                state.coordinates = coordinates;
            }
            state.geometry = featureInfo.geometry;
            state.showAoiConfigPopup = true;

            // Calculate prompt position based on the geometry
            const isPolygon = featureInfo.geometry.type === 'Polygon';
            if (state.mapRef) {
                const position = calculatePromptPosition(state.mapRef, coordinates, isPolygon);
                state.promptPosition = position; // Update prompt position in the state
            }
        },
        resetDrawnFeatureInfo: (state) => {
            state.coordinates = [];
            state.geometry = {};
            state.showAoiConfigPopup = false;
        },
        resetAoiConfigPopup: (state) => {
            state.showAoiConfigPopup = false;
        },
        resetMapSlice: () => {
            return initialState;
        },
        setLocalAoiItem: (state, action) => {
            state.localAoiItem = action.payload;
            state.showLocalAoiItem = true;
        },
        resetLocalAoiItem: (state) => {
            state.localAoiItem = '';
            state.showLocalAoiItem = false;
        },
    },
});

export const {
    setMapRef,
    setDrawRef,
    cleanUpDraw,
    drawAOI,
    zoomIn,
    zoomOut,
    setViewport,
    setPosition,
    setMode,
    resetMode,
    setDrawnFeatureInfo,
    resetDrawnFeatureInfo,
    resetMapSlice,
    setHeaderOption,
    resetHeaderOption,
    resetAoiConfigPopup,
    setLocalAoiItem,
    resetLocalAoiItem,
} = mapSlice.actions;

export default mapSlice.reducer;

// thunk function to ensure that drawing mode is set first to allow drawing
export const selectMode = (mode, callback) => async (dispatch, getState) => {

    await dispatch(setMode(mode));

    if (typeof callback === 'function') {
        callback(getState());
    }
};
