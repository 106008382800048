import { useState } from "react";
import "./AnalyticsFlow.css";
import SubOrdersBarFeaturesList from "../SubOrdersBarFeaturesList";
import RightSideAnalyticsBar from "./RightSideAnalyticsBar";
import AnalyticsDataBoxes from "./AnalyticsDataBoxes";
import AnalyticsOrderSelection from "./AnalyticsOrderSelection";

const AnalyticsFlow = ({ props }) => {
  return (
    <>
      <AnalyticsOrderSelection props={props} />
      <RightSideAnalyticsBar props={props} />
      <AnalyticsDataBoxes props={props} />
    </>
  );
};

export default AnalyticsFlow;
