// @ts-nocheck
import React from 'react';
import InputRange, { InputRangeProps } from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import './TimelineSlider.css'

interface TimelineSliderProps {
    min: number;
    max: number;
    value: {
        min: number;
        max: number;
    }
    onChange: (value: any) => void;
    resizingRef: any;
    disabled: boolean | string;
}

const TimelineSlider: React.FC<TimelineSliderProps> = ({ resizingRef, min, max, value, onChange, disabled }) => {

    return (
        <div className='w-100 cursor-auto' {...InputRangeProps}
            onMouseDown={() => {
                resizingRef.current = true;
            }}
            onMouseUp={() => {
                resizingRef.current = false;
            }}
        >
            <InputRange
                disabled={disabled}
                maxValue={max}
                minValue={min}
                value={value}
                onChange={onChange}
            />
        </div>
    );
}

export default TimelineSlider