import { useState } from "react";

const AnalyticsBarFilters = ({ props }) => {
  return (
    <>
      <div className="analyticsBarFiltersDiv">
        <div className="analyticsBarFilterItems">
          Last 8 months
          <span className="datepicker-toggle">
            <span className="datepicker-toggle-button"></span>
            <input type="date" className="datepicker-input" />
            <img className="noPadding" src={require("../../ImgAssets/WhiteCalendarIcon.svg").default} />
          </span>
        </div>
        <div className="analyticsBarFilterItems">
          All Constellations
          <img src={require("../../ImgAssets/WhiteDropdownArrow.svg").default} />
        </div>
      </div>
    </>
  );
};

export default AnalyticsBarFilters;
