import { useSelector } from "react-redux";
import "./modalFooter.css"
import { CircularProgress } from "@mui/material";

interface ModalFooterProps { isValid: boolean, actionText: string, loadingText: string, closingText: string, handleSubmit: () => void, handleClose: () => void }

const ModalFooter = ({ isValid, actionText, loadingText, closingText, handleSubmit, handleClose }: ModalFooterProps) => {

    const creatingConstellation = useSelector((state: { missionModelling: { creatingConstellation: boolean } }) => state.missionModelling.creatingConstellation)

    return (
        <div className="modalFooter">
            <button
                disabled={creatingConstellation}
                className="modalFooterclose"
                onClick={() => { handleClose() }}
            >
                {closingText}
            </button>
            <button
                disabled={creatingConstellation}
                className={`modalFooterapply ${isValid ? '' : 'disable_analysis_button'}`}
                onClick={() => { isValid && handleSubmit() }}
            >
                {creatingConstellation ? loadingText : actionText}
                {creatingConstellation && <CircularProgress size={10} className="text-white" />}
            </button>
        </div>
    )
};

export default ModalFooter;