import ModalInput from "../../../commonComponents/ModalInput";

const SunSynchronusConstellation = ({ sunSynchronusConstellation, setSunSynchronusConstellation }) => {

    const handleTotalSatChange = (value) => {
        setSunSynchronusConstellation({
            ...sunSynchronusConstellation,
            noOfSatellites: value
        })
    }

    const handleOrbitsChange = (value) => {
        setSunSynchronusConstellation({
            ...sunSynchronusConstellation,
            orbitPeriod: value
        })
    }

    const handleSatAltitudeChange = (value) => {
        setSunSynchronusConstellation({
            ...sunSynchronusConstellation,
            altitudeForEachSatellite: value
        })
    }


    return (
        <div style={{ width: "100%" }}>
            <div className='orbital_body_item'>
                <span>Total Satellites</span>
                <div>
                    <ModalInput
                        handleChange={handleTotalSatChange}
                        value={sunSynchronusConstellation.noOfSatellites} />
                </div>
            </div>
            <div className='orbital_body_item'>
                <span>Orbit(s)</span>
                <div>
                    <ModalInput
                        unit={'Day'}
                        handleChange={handleOrbitsChange}
                        value={sunSynchronusConstellation.orbitPeriod} />
                </div>
            </div>
            <div className='orbital_body_item'>
                <span>Satellite Altitude</span>
                <div>
                    <ModalInput
                        handleChange={handleSatAltitudeChange}
                        value={sunSynchronusConstellation.altitudeForEachSatellite} />
                </div>
            </div>
        </div>
    );
};

export default SunSynchronusConstellation;
