//@ts-nocheck
import { Fragment, useEffect, useState } from "react";
import CustomSelect, { Option } from "../../../../custom_components/CustomSelect";
import useUrlParams from "../../../../hooks/useUrlParams";
import { v4 as uuidv4 } from "uuid";
import {
    Form
} from 'react-bootstrap';
import './Console.scss'
import { toast } from "react-toastify";

interface RawFormProps {

}
const RawForm: React.FC<RawFormProps> = ({ }) => {
    const { mission_name, mission_id, sat_name, sat_id, truetwin_name, truetwin_id, dashboard, scenario_name, scenario_id } = useUrlParams()
    const [rawFormData, setRawFormData] = useState("")
    const [isHeader, setIsHeader] = useState(true)


    useEffect(() => {
        sessionStorage.setItem('currentForm', 'raw');
        sessionStorage.setItem('type', 'completeRawFrame')
        setRawFormPostData(rawFormData, true);
        window.dispatchEvent(new Event('storageUpdate'));
    }, []);

    const setRawFormPostData = (data, isHeader) => {
        const formData = [{
            "TruetwinId": truetwin_id,
            "LinkId": "",
            "scenarioId": scenario_id,
            "Timestamp": null,
            "CommandUid": uuidv4(),
            "Type": sessionStorage.getItem('type'),
            "ScheduleId": "default",
            "TaskId": "default",
            "sequenceData": {
                "commandDataRaw": data,
                "useRawCommand": true
            }
        }];
        sessionStorage.setItem('formData', JSON.stringify(formData));
        return formData;
    }

    useEffect(() => {
        const clearFormFields = () => {
            setRawFormPostData(sessionStorage.getItem('rawBytes'), isHeader)
            sessionStorage.setItem('currentForm', 'raw');
        };

        window.addEventListener('clearFormFields', clearFormFields);
        return () => {
            window.removeEventListener('clearFormFields', clearFormFields);
        };
    }, []);

    useEffect(() => {
        sessionStorage.setItem('rawBytes', rawFormData);
        setRawFormPostData(rawFormData, isHeader);
        window.dispatchEvent(new Event('storageUpdate'));
    }, [rawFormData]);

    const handleRawInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        try {
            setRawFormData(newValue)
        } catch (error) {
            console.error('Error:', error);
        }
    };


    return (
        <Fragment>
            <span style={{ fontSize: '12px', color: '#AEAEAE', fontWeight: '400' }}>Byte Stream</span>
            <Form.Control
                className="input__preview__container_big bg-transparent"
                type="text"
                as="textarea"
                placeholder="Enter Base64 Encoded format"
                value={rawFormData}
                rows={17}
                style={{ resize: 'none' }}
                onChange={handleRawInputChange}
                onMouseDown={(e) => {
                    e.stopPropagation();  // Prevents window dragging while selecting text
                }}
                onSelectStart={(e) => {
                    e.stopPropagation();  // Ensures text can be selected without triggering drag
                }}
            />

            <div className="d-flex align-items-center gap-1">
                <Form.Check
                    type="checkbox"
                    label=""
                    className="h-5 cursor-pointer mr-1 custom-checkbox"
                    checked={isHeader}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            e.preventDefault();
                        }
                    }}
                    onChange={() => {
                        sessionStorage.setItem('type', isHeader ? 'RawTctm' : 'completeRawFrame');
                        setIsHeader(prevIsHeader => {
                            const newIsHeader = !prevIsHeader;
                            setRawFormPostData(rawFormData, newIsHeader);
                            return newIsHeader;
                        });
                    }}
                />
                <h4 className="fs-12 mb-0" style={{ color: '#F0F0F0' }}>Header included in byte stream.</h4>
            </div>
        </Fragment>
    )
}

export default RawForm