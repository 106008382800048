import { useState } from "react";

export function useOrdersVariables() {
    const [Orders, setorders] = useState([
        {
            status: "Complete",
            name: "Delhi",
            subName: "North_Command | Recurring",
            progress: 100,
            subStatus: "Executed",
            idNum: 0,
            priority: 2,
            isRecurring: true,
            recurringCount: 12,
            recurringLimit: 24,
            startDate: "31 July, 2024 14:20 UTC",
            endDate: "35 August, 2024 7:35 UTC",
        },
        {
            status: "Complete",
            name: "Western Coast",
            subName: "South_Command | One-time",
            progress: 75,
            subStatus: "Partially Completed",
            idNum: 1,
            priority: 1,
            isRecurring: false,
            recurringCount: 14,
            recurringLimit: 24,
            startDate: "31 July, 2024 14:20 UTC",
            endDate: "35 August, 2024 7:35 UTC",
        },
        {
            status: "Complete",
            name: "Chennai",
            subName: "South_Command | One-time",
            progress: 30,
            subStatus: "Cancelled",
            idNum: 1,
            priority: 0,
            isRecurring: true,
            recurringCount: 16,
            recurringLimit: 24,
            startDate: "31 July, 2024 14:20 UTC",
            endDate: "35 August, 2024 7:35 UTC",
        },
        {
            status: "Complete",
            name: "Bangalore",
            subName: "South_Command | One-time",
            progress: 90,
            subStatus: "Failed",
            idNum: 1,
            priority: 1,
            isRecurring: true,
            recurringCount: 14,
            recurringLimit: 24,
            startDate: "31 July, 2024 14:20 UTC",
            endDate: "35 August, 2024 7:35 UTC",
        },
        {
            status: "Current",
            name: "Eastern Borders",
            subName: "South_Command | Recurring",
            progress: 26,
            subStatus: "Ongoing",
            idNum: 2,
            priority: 2,
            isRecurring: false,
            recurringCount: 18,
            recurringLimit: 24,
            startDate: "31 July, 2024 14:20 UTC",
            endDate: "35 August, 2024 7:35 UTC",
        },
        {
            status: "Current",
            name: "Northern Borders",
            subName: "North_Command | One-time",
            progress: 55,
            subStatus: "Ongoing",
            idNum: 3,
            priority: 1,
            isRecurring: true,
            recurringCount: 20,
            recurringLimit: 24,
            startDate: "31 July, 2024 14:20 UTC",
            endDate: "35 August, 2024 7:35 UTC",
        },
        {
            status: "Current",
            name: "Southern Borders",
            subName: "North_Command | One-time",
            progress: 55,
            subStatus: "Ongoing",
            idNum: 3,
            priority: 0,
            isRecurring: false,
            recurringCount: 22,
            recurringLimit: 24,
            startDate: "31 July, 2024 14:20 UTC",
            endDate: "35 August, 2024 7:35 UTC",
        },
        {
            status: "Current",
            name: "Western Borders",
            subName: "Western_Command | One-time",
            progress: 25,
            subStatus: "Delay",
            idNum: 3,
            priority: 0,
            isRecurring: false,
            recurringCount: 22,
            recurringLimit: 24,
            startDate: "31 July, 2024 14:20 UTC",
            endDate: "35 August, 2024 7:35 UTC",
        },
        {
            status: "Pending",
            name: "Mumbai",
            subName: "South_Command | One-time",
            progress: 15,
            subStatus: "Ongoing",
            idNum: 4,
            priority: 2,
            isRecurring: true,
            recurringCount: 24,
            recurringLimit: 24,
            startDate: "31 July, 2024 14:20 UTC",
            endDate: "35 August, 2024 7:35 UTC",
        },
        {
            status: "Pending",
            name: "Dubai",
            subName: "South_Command | One-time",
            progress: 15,
            subStatus: "Ongoing",
            idNum: 4,
            priority: 1,
            isRecurring: false,
            recurringCount: 26,
            recurringLimit: 34,
            startDate: "31 July, 2024 14:20 UTC",
            endDate: "35 August, 2024 7:35 UTC",
        },
        {
            status: "Pending",
            name: "UAE",
            subName: "South_Command | One-time",
            progress: 15,
            subStatus: "Ongoing",
            idNum: 4,
            priority: 0,
            isRecurring: true,
            recurringCount: 28,
            recurringLimit: 34,
            startDate: "31 July, 2024 14:20 UTC",
            endDate: "35 August, 2024 7:35 UTC",
        },
    ]);
    return [Orders, setorders];
}

export function useSpikeVariables() {
    const [OrdersSpike, setOrdersSpike] = useState([
        {
            name: "SPIKE01",
            usage: "Strip_mapping",
            component: "Imager",
            componentImg: require("../ImgAssets/ImagerSmallIcon.svg").default,
            type: "Capture_Strip",
            status: "Complete",
            triggerStatus: "02:00 UTC",
            geoTriggerLocation: "RKB | 05:00 UTC",
        },
        {
            name: "SPIKE01",
            usage: "TeleData Downlink",
            component: "X-Band",
            componentImg: require("../ImgAssets/XBandSmallIcon.svg").default,
            type: "Data_Img_type",
            status: "Complete",
            triggerStatus: "02:00 UTC",
            geoTriggerLocation: "RKB | 05:00 UTC",
        },
        {
            name: "SPIKE02",
            usage: "Strip_mapping",
            component: "Imager",
            componentImg: require("../ImgAssets/ImagerSmallIcon.svg").default,
            type: "Capture_Strip",
            status: "Waiting",
            triggerStatus: "02:00 UTC",
            geoTriggerLocation: "RKB | 05:00 UTC",
        },
        {
            name: "SPIKE02",
            usage: "TeleData Downlink",
            component: "X-Band",
            componentImg: require("../ImgAssets/XBandSmallIcon.svg").default,
            type: "Data_Img_type",
            status: "Warning",
            // status: "Pending",
            triggerStatus: "02:00 UTC",
            geoTriggerLocation: "RKB | 05:00 UTC",
            errorHeader: "ADCS malfunctioned in SPIKE 02",
            errorItems: [
                { header: "SPIKE 02 Strip Mapping", message: "Failed to Capture_Strip", time: "02:07 UTC", status: "Warning" },
                { header: "SPIKE 02 connecting to SPIKE 04", message: "Informing about Task Failure", time: "02:30 UTC", status: "Warning" },
                { header: "SPIKE 04 connecting to SPIKE 09", message: "Assigning Task Strip_Mapping", time: "02:30 UTC", status: "OK" },
            ],
        },
        {
            name: "SPIKE03",
            usage: "Strip_mapping",
            component: "Imager",
            componentImg: require("../ImgAssets/ImagerSmallIcon.svg").default,
            type: "Capture_Strip",
            status: "Pending",
            triggerStatus: "02:00 UTC",
            geoTriggerLocation: "RKB | 05:00 UTC",
        },
    ]);
}

export const OrdersSpikeMock =
    [
        {
            name: "SPIKE01",
            usage: "Strip_mapping",
            component: "Imager",
            componentImg: require("../ImgAssets/ImagerSmallIcon.svg").default,
            type: "Capture_Strip",
            status: "Complete",
            triggerStatus: "02:00 UTC",
            geoTriggerLocation: "RKB | 05:00 UTC",
        },
        {
            name: "SPIKE01",
            usage: "TeleData Downlink",
            component: "X-Band",
            componentImg: require("../ImgAssets/XBandSmallIcon.svg").default,
            type: "Data_Img_type",
            status: "Complete",
            triggerStatus: "02:00 UTC",
            geoTriggerLocation: "RKB | 05:00 UTC",
        },
        {
            name: "SPIKE02",
            usage: "Strip_mapping",
            component: "Imager",
            componentImg: require("../ImgAssets/ImagerSmallIcon.svg").default,
            type: "Capture_Strip",
            status: "Waiting",
            triggerStatus: "02:00 UTC",
            geoTriggerLocation: "RKB | 05:00 UTC",
        },
        {
            name: "SPIKE02",
            usage: "TeleData Downlink",
            component: "X-Band",
            componentImg: require("../ImgAssets/XBandSmallIcon.svg").default,
            type: "Data_Img_type",
            status: "Warning",
            // status: "Pending",
            triggerStatus: "02:00 UTC",
            geoTriggerLocation: "RKB | 05:00 UTC",
            errorHeader: "ADCS malfunctioned in SPIKE 02",
            errorItems: [
                { header: "SPIKE 02 Strip Mapping", message: "Failed to Capture_Strip", time: "02:07 UTC", status: "Warning" },
                { header: "SPIKE 02 connecting to SPIKE 04", message: "Informing about Task Failure", time: "02:30 UTC", status: "Warning" },
                { header: "SPIKE 04 connecting to SPIKE 09", message: "Assigning Task Strip_Mapping", time: "02:30 UTC", status: "OK" },
            ],
        },
        {
            name: "SPIKE03",
            usage: "Strip_mapping",
            component: "Imager",
            componentImg: require("../ImgAssets/ImagerSmallIcon.svg").default,
            type: "Capture_Strip",
            status: "Pending",
            triggerStatus: "02:00 UTC",
            geoTriggerLocation: "RKB | 05:00 UTC",
        },
    ];
