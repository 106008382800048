import { Fragment, useEffect, useState } from 'react'
import './GroundstationsAndProviders.css'
import GroundStationProvider from './GsProviders/GroundStationProvider'
import Loader from '../../../Layouts/Loader/Loader'
import GroundStations from './GroundStations/GroundStations'
import { useParams } from 'react-router-dom'
import GroundStationService from '../../../service/GroundStationService'
import GsMapVisualization from './GSProviderDetails/Modals/GsVisualization/GsMapVisualization'
import CustomToolTip from '../../CommonComponents/CustomToolTip'

const GroundstationsAndProviders = () => {
    const params = useParams();
    const { mission_id, tab_name } = params
    const [loading, setLoading] = useState<any>(false)
    const [gsProviders, setGsProviders] = useState<any>([])
    const [standAloneGs, setStandAloneGs] = useState<any>([])
    const [allGroundStations, setAllGroundStations] = useState<any>([])

    const [show_btn_group, set_show_btn_group] = useState<any>('')
    const [open_map_visualisation_modal, set_open_map_visualisation_modal] = useState<any>(false)
    const [open_provider_config_modal, set_open_provider_config_modal] = useState<any>(false);

    const get_gs_providers = () => {
        setLoading(true)
        GroundStationService.getAllGsProviders(mission_id)?.then((res) => {
            if (res.data) {
                setGsProviders(res?.data)
            } else {
                setGsProviders([])
            }
            get_all_ground_stations()
        }).catch((err) => {
            console.error(err);
            setLoading(false);
        })
    }

    const get_all_ground_stations = () => {
        GroundStationService.getGroundStationsByMissionId()?.then((res) => {
            if (res?.data) {
                let stand_alone_gs_list = res?.['data'].filter(gs => (gs?.['owner-org'] !== "System" && ((gs?.status === 'Configure' && (!gs?.['properties']?.['provider'] || gs?.['properties']?.['provider'] === '')) || gs?.status === 'Associate With Provider')))
                setAllGroundStations(res?.data)
                setStandAloneGs(stand_alone_gs_list)
                setLoading(false);
            }
            setLoading(false);
        }).catch((err) => {
            console.error(err);
            setLoading(false);
        });
    }


    useEffect(() => {
        if (mission_id && mission_id !== undefined && tab_name === 'Ground_Stations') {
            get_gs_providers()
        }
    }, [mission_id, tab_name])


    return (
        <Fragment>
            {loading && <Loader />}
            <div className='position-relative'>
                <div className='d-flex align-items-center position-absolute gap-2' style={{ top: -70, right: 20 }}>
                    <CustomToolTip title='List View' placement='top'>
                        <button className='p-0 m-0 bg-transparent cursor-auto'>
                            <img src={require('../../../assets/images/svgs/gs/list_selected.svg').default} height={35} alt="list" />
                        </button>
                    </CustomToolTip>
                    <CustomToolTip title='Map View' placement='top'>
                        <button className='p-0 m-0 bg-transparent' onClick={() => { set_open_map_visualisation_modal(true) }}>
                            <img src={require('../../../assets/images/svgs/gs/map.svg').default} height={35} alt="map" />
                        </button>
                    </CustomToolTip>
                </div>
            </div>
            {/* {(gsProviders?.length === 0 && standAloneGs?.length === 0) &&
                <div className="gsp_badge_info gap-2 mb-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M10 2.5C5.85938 2.5 2.5 5.85938 2.5 10C2.5 14.1406 5.85938 17.5 10 17.5C14.1406 17.5 17.5 14.1406 17.5 10C17.5 5.85938 14.1406 2.5 10 2.5ZM9.76562 14.375C9.61111 14.375 9.46006 14.3292 9.33159 14.2433C9.20311 14.1575 9.10298 14.0355 9.04384 13.8927C8.98471 13.75 8.96924 13.5929 8.99939 13.4413C9.02953 13.2898 9.10394 13.1506 9.2132 13.0413C9.32246 12.9321 9.46166 12.8577 9.61321 12.8275C9.76476 12.7974 9.92184 12.8128 10.0646 12.872C10.2074 12.9311 10.3294 13.0312 10.4152 13.1597C10.5011 13.2882 10.5469 13.4392 10.5469 13.5938C10.5469 13.801 10.4646 13.9997 10.3181 14.1462C10.1715 14.2927 9.97283 14.375 9.76562 14.375ZM11.0719 10.3906C10.4387 10.8156 10.3516 11.2051 10.3516 11.5625C10.3516 11.7075 10.2939 11.8466 10.1914 11.9492C10.0888 12.0518 9.94973 12.1094 9.80469 12.1094C9.65965 12.1094 9.52055 12.0518 9.41799 11.9492C9.31543 11.8466 9.25781 11.7075 9.25781 11.5625C9.25781 10.7066 9.65156 10.0262 10.4617 9.48203C11.2148 8.97656 11.6406 8.65625 11.6406 7.95195C11.6406 7.47305 11.3672 7.10938 10.8012 6.84023C10.668 6.77695 10.3715 6.71523 10.0066 6.71953C9.54883 6.72539 9.19336 6.83477 8.91953 7.05508C8.40312 7.4707 8.35938 7.92305 8.35938 7.92969C8.35591 8.0015 8.33834 8.07194 8.30766 8.13696C8.27697 8.20199 8.23379 8.26033 8.18055 8.30866C8.12732 8.357 8.06509 8.39437 7.99742 8.41866C7.92974 8.44294 7.85795 8.45366 7.78613 8.4502C7.71432 8.44673 7.64388 8.42916 7.57886 8.39848C7.51383 8.36779 7.45549 8.3246 7.40716 8.27137C7.35882 8.21814 7.32145 8.15592 7.29716 8.08824C7.27288 8.02057 7.26216 7.94877 7.26562 7.87695C7.26992 7.78203 7.33594 6.92695 8.2332 6.20508C8.69844 5.83086 9.29023 5.63633 9.99102 5.62773C10.4871 5.62188 10.9531 5.70586 11.2691 5.85508C12.2148 6.30234 12.7344 7.04805 12.7344 7.95195C12.7344 9.27344 11.8512 9.8668 11.0719 10.3906Z" fill="#EEEEEE" />
                    </svg>
                    <span>Add ground station provider or standalone ground station from here or choose from map.</span>
                </div>
            } */}
            <div className="mission-card-view gap-3 ">
                <GroundStationProvider
                    gs_providers={gsProviders}
                    all_ground_stations={allGroundStations}
                    get_gs_providers={() => { get_gs_providers() }}
                    set_loading={(data) => { setLoading(data) }}
                    get_all_ground_stations={() => { get_all_ground_stations() }}
                    open_provider_config_modal={open_provider_config_modal}
                    set_open_provider_config_modal={(data) => { set_open_provider_config_modal(data) }}
                    show_btn_group={show_btn_group}
                    set_show_btn_group={(data) => { set_show_btn_group(data) }}
                />
                <GroundStations
                    set_loading={(data) => { setLoading(data) }}
                    stand_alone_gs={standAloneGs}
                    set_open_provider_config_modal={(data) => { set_open_provider_config_modal(data) }}
                    get_all_ground_stations={() => { get_all_ground_stations() }}
                    gs_providers={gsProviders}
                    show_btn_group={show_btn_group}
                    set_show_btn_group={(data) => { set_show_btn_group(data) }}
                    set_open_map_visualisation_modal={(data) => { set_open_map_visualisation_modal(data) }}
                    all_ground_stations={allGroundStations}
                />
            </div>


            {open_map_visualisation_modal &&
                <GsMapVisualization
                    close={() => { set_open_map_visualisation_modal(false) }}
                    ground_stations={allGroundStations}
                    get_all_ground_stations={() => { get_all_ground_stations() }}
                    all_providers={gsProviders}
                    get_provider_details={() => (get_gs_providers())}
                    mode={'Map'}
                />
            }

        </Fragment>
    )
}

export default GroundstationsAndProviders
