

const RedundancySvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g clip-path="url(#clip0_782_157129)">
                <path d="M6.66665 12H3.33331C2.80288 12 2.29417 11.7893 1.9191 11.4143C1.54403 11.0392 1.33331 10.5305 1.33331 10V9.33337" stroke="#F0F0F0" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4.66669 14L6.66669 12L4.66669 10" stroke="#F0F0F0" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M13.3333 9.33337H10.6666C9.93027 9.33337 9.33331 9.93033 9.33331 10.6667V13.3334C9.33331 14.0698 9.93027 14.6667 10.6666 14.6667H13.3333C14.0697 14.6667 14.6666 14.0698 14.6666 13.3334V10.6667C14.6666 9.93033 14.0697 9.33337 13.3333 9.33337Z" stroke="#F0F0F0" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.33331 1.33337H2.66665C1.93027 1.33337 1.33331 1.93033 1.33331 2.66671V5.33337C1.33331 6.06975 1.93027 6.66671 2.66665 6.66671H5.33331C6.06969 6.66671 6.66665 6.06975 6.66665 5.33337V2.66671C6.66665 1.93033 6.06969 1.33337 5.33331 1.33337Z" stroke="#F0F0F0" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_782_157129">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default RedundancySvg