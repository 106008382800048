import { useState } from "react";

export function useChartOptions() {
    const chartOptionsSettings = {
        animation: false,
        plugins: {
          legend: {
            display: true,
            font: {
              color: "#f0f0f0",
            },
            position: "bottom",
            maxWidth: "100%",
            labels: { boxWidth: 15, boxHeight: 2, padding: 15, color: "#f0f0f0", font: { family: "Montserrat", size: "12px", weight: "300" } },
          },
          tooltip: {
            enabled: true,
          },
        },
    
        scales: {
          x: {
            grid: {
              display: true,
              color: "#464646",
              lineWidth: 0.5,
            },
          },
          y: {
            grid: {
              display: true,
              color: "#464646",
              lineWidth: 0.5,
            },
          },
        },
      }
      return chartOptionsSettings;
};