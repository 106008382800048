import { useState } from "react";
import Draggable from "react-draggable";

const BottomBarOrderAnalytics = ({ props }) => {
  return (
    <div className="bottomBarBlackBarComponent">
      <div className="bottomBarOrderAnalytics">
        <button
          className={props.bottomBarComponent.Order ? "bottomBarOrderAnalyticsComponent active" : "bottomBarOrderAnalyticsComponent"}
          onClick={() => props.setBottomBarComponent({ Order: true, Analytics: false, Policy: false })}
        >
          <img src={require("../ImgAssets/OrderIcon.svg").default}></img>
          Orders
        </button>
        <button
          className={props.bottomBarComponent.Analytics ? "bottomBarOrderAnalyticsComponent active" : "bottomBarOrderAnalyticsComponent"}
          onClick={() => props.setBottomBarComponent({ Order: false, Analytics: true, Policy: false })}
        >
          <img src={require("../ImgAssets/OrderIcon.svg").default}></img>Analytics
        </button>
        {/* <button
          className={props.bottomBarComponent.Policy ? "bottomBarOrderAnalyticsComponent active" : "bottomBarOrderAnalyticsComponent"}
          onClick={() => props.setBottomBarComponent({ Order: false, Analytics: false, Policy: true })}
        >
          <img src={require("../ImgAssets/OrderIcon.svg").default}></img>Policy
        </button> */}
      </div>
    </div>
  );
};

export default BottomBarOrderAnalytics;
