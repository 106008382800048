// @ts-nocheck
import { useEffect, useState } from "react";
import "./ModalComponents.css";
import Select from "react-select";

const ModalDropdown = ({ options, isMulti, handleSelectedOption, previousOption }) => {
    const [selectedOption, setSelectedOption] = useState<any>(null); // Set initial state to null

    const handleSelection = (e) => {
        setSelectedOption(e);
        handleSelectedOption(e);
    };

    useEffect(() => {
        if (previousOption) {
            setSelectedOption({
                label: previousOption,
                value: previousOption,
            });
        }
    }, [previousOption]);

    return (
        <Select
            className="modalSelectBtn"
            placeholder="Select Dropdown"
            value={selectedOption}
            options={options}
            onChange={handleSelection}
            isMulti={isMulti === "true" ? true : false}
            styles={{
                control: (provided, state) => ({
                    ...provided,
                    borderRadius: "4px",
                    border: " 0.5px solid rgba(240, 240, 240, 0.30)",
                    backgroundColor: state.selectProps?.value
                        ? "#020612"
                        : "rgba(10, 10, 10, 0.65)",
                }),
                menu: (provided) => ({
                    ...provided,
                    backgroundColor: "#020612",
                }),
                option: (provided, state) => ({
                    ...provided,
                    fontSize: "12px !important",
                    backgroundColor: state.isFocused
                        ? "#020612"
                        : "rgba(255, 255, 255, 0.10)",
                    "&:hover": {
                        backgroundColor: "#020612",
                    },
                }),
                placeholder: (provided) => ({
                    ...provided,
                    color: "#57585A !important",
                    fontSize: "12px !important",
                }),
                singleValue: (provided) => ({
                    ...provided,
                    fontSize: "12px",
                    color: "#fff" // Set font size of selected value
                })
            }}
        />
    );
};

export default ModalDropdown;