import { useState } from "react";

const AnalyticsDataBoxes = ({ props }) => {
  const rowOneDataValues = [
    { label: "Global Coverage", value: "20%" },
    { label: "Blind Spots", value: "2%" },
    { label: "Average Latency", value: "15 mins" },
  ];

  const rowTwoDataValues = [
    { label: "Completed", value: "125" },
    { label: "Delayed", value: "12" },
    { label: "Cancelled", value: "5" },
    { label: "Failed", value: "15" },
  ];

  const RenderDataBoxes = (item) => (
    <div className="analyticsDataBox">
      <div className="analyticsDataBoxHeader">{item.value}</div>
      <div className="analyticsDataBoxSubHeader">{item.label}</div>
    </div>
  );

  return (
    <>
      <div className="analyticsDataBoxes">
        <div className="analyticsDataBoxesRow">
          {rowOneDataValues.map(RenderDataBoxes)}
        </div>
        <div className="analyticsDataBoxesRow">
          {rowTwoDataValues.map(RenderDataBoxes)}
        </div>
      </div>
    </>
  );
};

export default AnalyticsDataBoxes;
