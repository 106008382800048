import "./UcpComponents.css";

const ThreeDotMenuComponent = ({ item }) => {
  return (
    <div className="threeDotMenuParentDiv">
      <div className="threeDotOptionsMenu">
        {item.status === "Complete" && (
          <>
            <button className="modalInputBox fullWidth" onClick={() => {}}>
              <div className="modalInputBoxText">Info</div>
            </button>
            <button className="modalInputBox fullWidth" onClick={() => {}}>
              <div className="modalInputBoxText">Archive</div>
            </button>
            <button className="modalInputBox fullWidth" onClick={() => {}}>
              <div className="modalInputBoxText">Resubmit Order</div>
            </button>
          </>
        )}
        {item.status === "Current" && (
          <>
            <button className="modalInputBox fullWidth" onClick={() => {}}>
              <div className="modalInputBoxText">Info</div>
            </button>
            <button className="modalInputBox fullWidth" onClick={() => {}}>
              <div className="modalInputBoxText">Change Priority</div>
            </button>
            <button className="modalInputBox fullWidth" onClick={() => {item.status.subStatus = "Cancelled"}}>
              <div className="modalInputBoxText">Cancel</div>
            </button>
            <button className="modalInputBox fullWidth" onClick={() => {item.status.subStatus = "Delay"}}>
              <div className="modalInputBoxText">Hold</div>
            </button>
          </>
        )}
        {item.status === "Pending" && (
          <>
            <button className="modalInputBox fullWidth" onClick={() => {}}>
              <div className="modalInputBoxText">Info</div>
            </button>
            <button className="modalInputBox fullWidth" onClick={() => {}}>
              <div className="modalInputBoxText">Change Priority</div>
            </button>
            <button className="modalInputBox fullWidth" onClick={() => {item.status.subStatus = "Ongoing"}}>
              <div className="modalInputBoxText">Accept</div>
            </button>
            <button className="modalInputBox fullWidth" onClick={() => {item.status.subStatus = "Delay"}}>
              <div className="modalInputBoxText">Decline</div>
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ThreeDotMenuComponent;
