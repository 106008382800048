import { getEnvVar } from '../../envUtils.ts';
import ApiService from '../../service/ApiService.js';
import * as ucpActions from '../../store/UnifiedControlPlaneSlice.js';

const ORDERS_URL = 'orders';

const getOrders = () => async (dispatch) => {
    dispatch(ucpActions.getOrdersRequest());
    try {
        const response = await ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}${ORDERS_URL}`);
        dispatch(ucpActions.getOrdersSuccess(response.data));
    } catch (error) {
        dispatch(ucpActions.getOrdersFailure(error.message));
        console.error('Error while getting orders:', error);
    }
};

const createOrders = (body, callback) => async (dispatch) => {
    dispatch(ucpActions.createOrderRequest);
    try {
        const response = await ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}${ORDERS_URL}`, body);
        dispatch(ucpActions.createOrderSuccess(response.data));
        if (callback) {
            callback();
        }
        // dispatch(fetchAois(missionId))
    } catch (error) {
        dispatch(ucpActions.createOrderFailure());
        console.error('Error while creating order:', error);
    }
};

const getConstellationsFromMM = (missionId, scenarioid) => async (dispatch) => {
    dispatch(ucpActions.getConstellationRequest())
    try {
        const response = await ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/constellation/all/orbit?missionid=${missionId}&scenarioid=${scenarioid}`);
        dispatch(ucpActions.getConstellationSuccess(response.data))
    } catch (error) {
        dispatch(ucpActions.getConstellationFailure())
        console.log(error)
    }
}

const getGroundStationsFromMission = () => async (dispatch) => {
    dispatch(ucpActions.getGroundStationRequest())
    try {
        const response = await ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation`);
        dispatch(ucpActions.getGroundStationSuccess(response.data))
    } catch (error) {
        dispatch(ucpActions.getGroundStationFailure())
        console.log(error)
    }
}

const getMissionMoellingModels = (mission_id) => async (dispatch) => {
    dispatch(ucpActions.getScenarioIdRequest())
    try {
        const response = await ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/model/all?missionid=${mission_id}`)
        dispatch(ucpActions.getScenarioIdSuccess(response.data?.[0]?.['scenario_id']))
    } catch (error) {
        console.log(error)
        dispatch(ucpActions.getConstellationFailure())
    }
}

const UnifiedControlPlaneService = {
    getMissionMoellingModels,
    getOrders,
    createOrders,
    getConstellationsFromMM,
    getGroundStationsFromMission
};

export default UnifiedControlPlaneService;
