import { useState } from "react";

const OrdersBarFeaturesList = ({ props }) => {
  const [filterItems, setFilterItems] = useState("");
  const [filterItemsLevelOne, setFilterItemsLevelOne] = useState("");
  const [filterItemsLevelTwo, setFilterItemsLevelTwo] = useState("");
  const [filterItemsLevelThree, setFilterItemsLevelThree] = useState("");
  const filterItemsNames = [
    {
      label: "Constellation",
      id: "Constellation",
      subArray: true,
      filterItems: [
        { label: "All", id: "All", subArray: false, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] },
        {
          label: "SPIKE_NE",
          id: "SPIKE_NE",
          subArray: false,
          filterItems: [
            { label: "All", id: "All", subArray: true, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] },
            {
              label: "Plane_HP",
              id: "Plane_HP",
              subArray: true,
              filterItems: [
                { label: "All", id: "All", subArray: false, filterItems: [] },
                { label: "SPIKE_01", id: "SPIKE_01", subArray: false, filterItems: [] },
                { label: "SPIKE_02", id: "SPIKE_02", subArray: false, filterItems: [] },
                { label: "SPIKE_03", id: "SPIKE_03", subArray: false, filterItems: [] },
                { label: "SPIKE_04", id: "SPIKE_04", subArray: false, filterItems: [] },
                { label: "SPIKE_05", id: "SPIKE_05", subArray: false, filterItems: [] },
                { label: "SPIKE_06", id: "SPIKE_06", subArray: false, filterItems: [] },
              ],
            },
            {
              label: "Plane_CP",
              id: "Plane_CP",
              subArray: true,
              filterItems: [
                { label: "All", id: "All", subArray: false },
                { label: "SPIKE_01", id: "SPIKE_01", subArray: false, filterItems: [] },
                { label: "SPIKE_02", id: "SPIKE_02", subArray: false, filterItems: [] },
                { label: "SPIKE_03", id: "SPIKE_03", subArray: false, filterItems: [] },
                { label: "SPIKE_04", id: "SPIKE_04", subArray: false, filterItems: [] },
                { label: "SPIKE_05", id: "SPIKE_05", subArray: false, filterItems: [] },
                { label: "SPIKE_06", id: "SPIKE_06", subArray: false, filterItems: [] },
              ],
            },
          ],
        },
        {
          label: "SPIKE_EW",
          id: "SPIKE_EW",
          subArray: false,
          filterItems: [{ label: "SPIKE_EW", id: "SPIKE_EW", subArray: true, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] }],
        },
        {
          label: "SPIKE_SW",
          id: "SPIKE_SW",
          subArray: false,
          filterItems: [{ label: "SPIKE_SW", id: "SPIKE_SW", subArray: true, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] }],
        },
        {
          label: "CARTOSAT",
          id: "CARTOSAT",
          subArray: false,
          filterItems: [{ label: "CARTOSAT", id: "CARTOSAT", subArray: true, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] }],
        },
      ],
    },
    {
      label: "Priority",
      id: "Priority",
      subArray: true,
      filterItems: [
        { label: "All", id: "All", subArray: false, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] },
        { label: "Normal", id: "Normal", subArray: false, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] },
        { label: "High", id: "High", subArray: false, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] },
        { label: "Higher", id: "Higher", subArray: false, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] },
      ],
    },
    {
      label: "Order Owner",
      id: "Order_Owner",
      subArray: true,
      filterItems: [
        { label: "All", id: "All", subArray: false, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] },
        { label: "North_Command", id: "North_Command", subArray: false, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] },
        { label: "South_Command", id: "South_Command", subArray: false, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] },
        { label: "West_Command", id: "West_Command", subArray: false, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] },
        { label: "East_Command", id: "East_Command", subArray: false, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] },
        { label: "Self", id: "Self", subArray: false, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] },
      ],
    },
    {
      label: "Orbital Plane",
      id: "Orbital_Plane",
      subArray: true,
      filterItems: [
        { label: "All", id: "All", subArray: false, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] },
        { label: "Polar Orbits", id: "Polar Orbits", subArray: false, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] },
        { label: "Equatorial Orbit", id: "Equatorial Orbit", subArray: false, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] },
        { label: "Inclined Orbit", id: "Inclined Orbit", subArray: false, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] },
        { label: "Molniya Orbit", id: "Molniya Orbit", subArray: false, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] },
        { label: "Tundra Orbit", id: "Tundra Orbit", subArray: false, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] },
      ],
    },
    {
      label: "Vendors",
      id: "Vendors",
      subArray: true,
      filterItems: [
        { label: "All", id: "All", subArray: false, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] },
        { label: "Antaris", id: "Antaris", subArray: false, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] },
        { label: "Vendor01", id: "Vendor01", subArray: false, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] },
        { label: "Vendor02", id: "Vendor02", subArray: false, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] },
      ],
    },
    {
      label: "Sensor",
      id: "Sensor",
      subArray: true,
      filterItems: [
        { label: "All", id: "All", subArray: false, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] },
        { label: "Sensor01", id: "Sensor01", subArray: false, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] },
        { label: "Sensor02", id: "Sensor02", subArray: false, filterItems: [{ label: "", id: "", subArray: false, filterItems: [] }] },
      ],
    },
  ];

  return (
    <div>
      <div className="featuresList ordersBarFeaturesListOne">
        {filterItemsNames.map((item) => (
          <div className="modalInputBox fullWidth">
            <button
              className="transparentButton"
              onClick={() => {
                setFilterItems(item.id);
                setFilterItemsLevelOne("");
                setFilterItemsLevelTwo("");
                setFilterItemsLevelThree("");
              }}
            >
              <div className={item.id === filterItems ? "modalInputBoxText floatLeft activeText" : "modalInputBoxText floatLeft"}>{item.label}</div>
              <button className="ordersDropdownArrow featuresListDropDown">
                <img className={item.id === filterItems ? "active-img" : ""} src={require("../ImgAssets/DropdownArrowWhite.svg").default} />
              </button>
            </button>
          </div>
        ))}
      </div>

      {filterItems != "" && (
        <div className="featuresList ordersBarFeaturesListTwo">
          <div className="modalInputBoxText floatLeft activeText" style={{ fontSize: "12px", marginBottom: "8px" }}>
            {filterItems}
          </div>
          {filterItemsNames
            .filter((item) => item.id === filterItems)
            .map((item, i) =>
              item.filterItems.map((items) => (
                <div className="modalInputBox fullWidth">
                  <div className="transparentButton">
                    <div style={{ float: "left" }}>
                      <button
                        className="modalInputRoundedBoxButton"
                        onClick={() => {
                          setFilterItemsLevelOne(items.id);
                          setFilterItemsLevelTwo("");
                          setFilterItemsLevelThree("");
                        }}
                      ></button>
                      <img
                        style={{ top: "-1px" }}
                        className={filterItemsLevelOne === items.id ? "singleTickIcon" : "singleTickIcon hideIcon"}
                        src={require("../ImgAssets/SingletickIcon.svg").default}
                      />
                    </div>
                    <div className={items.id === filterItemsLevelOne ? "modalInputBoxText floatLeft activeText" : "modalInputBoxText floatLeft"}>{items.label}</div>
                    {items.filterItems[0].subArray && (
                      <button
                        className="ordersDropdownArrow featuresListDropDown"
                        onClick={() => {
                          setFilterItemsLevelOne(items.id);
                          setFilterItemsLevelTwo("");
                          setFilterItemsLevelThree("");
                        }}
                      >
                        <img className={items.id === filterItemsLevelOne ? "active-img" : ""} src={require("../ImgAssets/DropdownArrowWhite.svg").default} />
                      </button>
                    )}
                  </div>
                </div>
              ))
            )}
        </div>
      )}

      {filterItemsLevelOne != "" && (
        <div className="featuresList ordersBarFeaturesListThree">
          <div className="modalInputBoxText floatLeft activeText" style={{ fontSize: "12px", marginBottom: "8px" }}>
            {filterItemsLevelOne}
          </div>
          {filterItemsNames
            .filter((item) => item.id === filterItems)
            .map((item) =>
              item.filterItems
                .filter((items) => items.id === filterItemsLevelOne)
                .map((items, i) =>
                  items.filterItems.map((itemsOne) => (
                    <div className="modalInputBox fullWidth">
                      <div className="transparentButton">
                        <div style={{ float: "left" }}>
                          <button
                            className="modalInputRoundedBoxButton"
                            onClick={() => {
                              setFilterItemsLevelTwo(itemsOne.id);
                              setFilterItemsLevelThree("");
                            }}
                          ></button>
                          <img
                            style={{ top: "-1px" }}
                            className={filterItemsLevelTwo === itemsOne.id ? "singleTickIcon" : "singleTickIcon hideIcon"}
                            src={require("../ImgAssets/SingletickIcon.svg").default}
                          />
                        </div>
                        <div className={itemsOne.id === filterItemsLevelTwo ? "modalInputBoxText floatLeft activeText" : "modalInputBoxText floatLeft"}>{itemsOne.label}</div>
                        {itemsOne.subArray && (
                          <button className="ordersDropdownArrow featuresListDropDown">
                            <img className={itemsOne.id === filterItemsLevelTwo ? "active-img" : ""} src={require("../ImgAssets/DropdownArrowWhite.svg").default} />
                          </button>
                        )}
                      </div>
                    </div>
                  ))
                )
            )}
        </div>
      )}

      {filterItemsLevelTwo != "" && (
        <div className="featuresList ordersBarFeaturesListFour">
          <div className="modalInputBoxText floatLeft activeText" style={{ fontSize: "12px", marginBottom: "8px" }}>
            {filterItemsLevelTwo}
          </div>
          {filterItemsNames
            .filter((item) => item.id === filterItems)
            .map((item) =>
              item.filterItems
                .filter((items) => items.id === filterItemsLevelOne)
                .map((items, i) =>
                  items.filterItems
                    .filter((items) => items.id === filterItemsLevelTwo)
                    .map((itemsOne) =>
                      itemsOne.filterItems.map((itemsTwo) => (
                        <div className="modalInputBox fullWidth">
                          <div className="transparentButton">
                            <div style={{ float: "left" }}>
                              <button className="modalInputRoundedBoxButton" onClick={() => setFilterItemsLevelThree(itemsTwo.id)}></button>
                              <img
                                style={{ top: "-1px" }}
                                className={filterItemsLevelThree === itemsTwo.id ? "singleTickIcon" : "singleTickIcon hideIcon"}
                                src={require("../ImgAssets/SingletickIcon.svg").default}
                              />
                            </div>
                            <div className={itemsTwo.id === filterItemsLevelTwo ? "modalInputBoxText floatLeft activeText" : "modalInputBoxText floatLeft"}>{itemsTwo.label}</div>
                          </div>
                        </div>
                      ))
                    )
                )
            )}
        </div>
      )}
    </div>
  );
};

export default OrdersBarFeaturesList;
