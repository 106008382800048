import { FC, useEffect, useRef, useState } from "react";
import './ContactTimeline.scss'
import ContactTimeFrame from "./ContactTimeFrame";
import OnboardContact from "./OnboardContact";
import GroundContact from "./GroundContact";
import { InfoSvg } from "./Svgs";
import CustomLoader from "../../../../custom_components/CustomLoader";
import useTimeline from "../../../../hooks/useTimeline";

interface ContactTimelineProps {
    simulation_start_time: number,
    simulation_end_time: number,
}

const GroundAndOnboardContact: FC<ContactTimelineProps> = ({
    simulation_end_time,
    simulation_start_time,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const { loading, set_window_width } = useTimeline()


    useEffect(() => {
        const container = containerRef.current;
        if (container instanceof HTMLElement) {
            const width = container.offsetWidth;
            set_window_width(width);
        }
    }, [containerRef, window.innerWidth])


    return (
        <div className='contact__container gap-2 justify-content-start'
            style={{ height: '150px' }}
        >
            <div className="onboard__contact__details__container__wrapper">
                {loading &&
                    <div className='container__loading'>
                        <CustomLoader loading={loading} />
                    </div>
                }
                <div className="onboard__contact__details__container">
                    <div className="left_details">
                        <span>Onboard Schedule</span>
                        <div className="info__svg__container">
                            <InfoSvg />
                            <div className="custom__tooltip">Timeline of Tasks scheduled for execution onboard a Satellite</div>
                        </div>
                    </div>
                    <div className="left_details">
                        <span>Ground Contacts</span>
                        <div className="info__svg__container">
                            <InfoSvg />
                            <div className="custom__tooltip">Timeline of Ground Contacts scheduled with a Satellite.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="timeframe__contact__details__container" ref={containerRef}>
                <ContactTimeFrame
                    simulation_start_time={simulation_start_time}
                    simulation_end_time={simulation_end_time}
                />

                <div className="details__container__wrapper">
                    <OnboardContact />
                    <GroundContact />
                </div>
            </div>

        </div>
    )
}

export default GroundAndOnboardContact