import { FC } from "react";
import BusForm from "./BusForm";
import PayloadForm from "./PayloadForm";
import './Console.scss'
import CustomRadioButton from "../../../../custom_components/CustomRadioButton";
import RawForm from "./RawForm";

interface TelecommandSectionProps {
    communication_type: string;
    command_type: string;
    set_command_type: (type: string) => void;
    busComponentOptions: any[],
    fetchBusCommands: (components: string) => void;
    busCommandOptions: any[];
    allPayloadComponents: any[];
}

const TTCFormSection: FC<TelecommandSectionProps> = ({ communication_type, command_type, set_command_type, busComponentOptions, fetchBusCommands, busCommandOptions, allPayloadComponents }) => {


    return (
        <div className="left__panel__body__form__section__container">
            <div className="left__panel__body__input__option__container">
                <span className="input__label">Command Type</span>
                <CustomRadioButton
                    value={command_type}
                    onChange={set_command_type}
                    button_list={['Bus', 'Payload', 'Raw']}
                />
            </div>
            {command_type === 'Bus' && <BusForm busComponentOptions={busComponentOptions} fetchBusCommands={fetchBusCommands} busCommandOptions={busCommandOptions} />}
            {command_type === 'Payload' && <PayloadForm allPayloadComponents={allPayloadComponents} />}
            {command_type === 'Raw' && <RawForm />}
        </div>
    )
}

export default TTCFormSection