// @ts-nocheck
import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';

// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import 'mapbox-gl/dist/mapbox-gl.css';

import * as MAP_CONSTANTS from "../../UnifiedControlPlane.constants";
import * as mapActions from "../../../../store/MapSlice";
import { getEnvVar } from "../../../../envUtils";

const MapboxView = () => {
    mapboxgl.accessToken = getEnvVar('REACT_APP_MAPBOX_TOKEN') // Mapbox access token for Equirectangular projection    const mapRef = useRef(null);
    const drawRef = useRef(null);
    const mapRef = useRef(null);
    const mapContainerRef = useRef(null);

    const aoiCollection = useSelector(state => state.unifiedControlPlane.aoiCollection);
    const uploadedCollection = useSelector(state => state.unifiedControlPlane.uploadedCollection);

    const dispatch = useDispatch();

    const removeSourceAndLayers = (sourceId, layerIds) => {
        const newMap = mapRef.current;
        if (newMap.getSource(sourceId)) {
            layerIds.forEach(layerId => {
                if (newMap.getLayer(layerId)) {
                    newMap.removeLayer(layerId);
                }
            });
            newMap.removeSource(sourceId);
        }
    };

    useEffect(() => {
        // to init the map
        if (mapRef.current) return;

        if (!mapRef.current) {
            const initializeMap = () => {

                const newMap = new mapboxgl.Map({
                    container: 'map',
                    style: getEnvVar('REACT_APP_MAPBOX_STYLE_URL'), // Mapbox style url for Equirectangular projection
                    center: MAP_CONSTANTS.MAP_CENTER,
                    zoom: MAP_CONSTANTS.MAP_DEFAULT_ZOOM,
                    minZoom: MAP_CONSTANTS.MAP_MIN_ZOOM,
                });

                newMap.on('load', () => {

                    // to allow drawing aois
                    const drawControl = new MapboxDraw({
                        displayControlsDefault: false,
                        userProperties: true,
                        modes: {
                            ...MapboxDraw.modes,
                        },
                    });

                    newMap.addControl(drawControl);

                    // dispatch(mapActions.setMapRef(newMap));
                    dispatch(mapActions.setDrawRef(drawControl));
                    drawRef.current = drawControl;

                    const updateCoordinates = () => {
                        if (drawControl) {
                            const drawnFeatures = drawControl.getAll();
                            if (drawnFeatures && drawnFeatures.features.length > 0) {
                                const featureInfo = {
                                    coordinates: drawnFeatures.features[0].geometry.coordinates,
                                    geometry: drawnFeatures.features[0].geometry,
                                }
                                dispatch(mapActions.setDrawnFeatureInfo(featureInfo));
                            }
                        }
                    };

                    newMap.on('draw.create', updateCoordinates);
                    newMap.on('draw.update', updateCoordinates);

                    // cleanup function to remove event listeners and controls
                    return () => {
                        newMap.off('draw.create', updateCoordinates);
                        newMap.off('draw.update', updateCoordinates);
                        if (drawControl) {
                            drawControl.deleteAll();
                            newMap.removeControl(drawControl);
                        }
                        newMap.remove();
                    };
                });

                dispatch(mapActions.setMapRef(newMap));
                mapRef.current = newMap;
            };

            initializeMap();

            return () => {
                if (mapRef.current) {
                    mapRef.current.remove();
                    mapRef.current = null;
                }
            };
        }
    }, [dispatch]);

    const showAoiOnMap = () => {
        const newMap = mapRef.current;

        removeSourceAndLayers('aoi-source', ['aoi-polygon', 'aoi-point']);

        if (Object.keys(aoiCollection).length > 0) {

            newMap.addSource('aoi-source', {
                type: "geojson",
                data: aoiCollection,
            });

            newMap.addLayer({
                id: 'aoi-polygon',
                type: 'fill',
                source: 'aoi-source',
                paint: {
                    'fill-color': '#090909',
                    'fill-opacity': 0.3,
                },
                filter: [
                    'all',
                    ['==', '$type', 'Polygon'],
                ]
            });

            newMap.addLayer({
                id: 'aoi-polygon-outline',
                type: 'line',
                source: 'aoi-source',
                layout: {},
                paint: {
                    'line-color': '#090909',
                    'line-width': 1
                },
                filter: [
                    'all',
                    ['==', '$type', 'Polygon'],
                ]
            });

            newMap.addLayer({
                id: 'aoi-point',
                type: 'circle',
                source: 'aoi-source',
                paint: {
                    'circle-color': '#090909',
                    'circle-radius': 5,
                },
                filter: ['==', '$type', 'Point']
            });


        };
    };

    const addUploadedFeatures = () => {
        const newMap = mapRef.current;
        if (!newMap.getSource('upload-source')) {

            newMap.addSource('upload-source', {
                type: "geojson",
                data: uploadedCollection,
            });

            newMap.addLayer({
                id: 'uploaded-polygon',
                type: 'fill',
                source: 'upload-source',
                paint: {
                    'fill-color': '#2C373A',
                    'fill-opacity': 0.5
                },
                filter: [
                    'all',
                    ['==', '$type', 'Polygon'],
                ]
            });

            newMap.addLayer({
                id: 'uploaded-polygon-outline',
                type: 'line',
                source: 'upload-source',
                layout: {},
                paint: {
                    'line-color': '#3AB2D0',
                    'line-width': 2
                },
                filter: [
                    'all',
                    ['==', '$type', 'Polygon'],
                ]
            });

            newMap.addLayer({
                id: 'uploaded-point',
                type: 'circle',
                source: 'upload-source',
                paint: {
                    'circle-radius': 5,
                    'circle-color': '#3AB2D0',
                },
                filter: ['==', '$type', 'Point']
            });
        }
    };

    useEffect(() => {
        mapRef.current.on('load', () => {
            showAoiOnMap();
        });
    }, [aoiCollection]);

    useEffect(() => {
        // to display the uploaded aois
        Object.keys(uploadedCollection).length > 0 ?
            addUploadedFeatures() :
            removeSourceAndLayers('upload-source',
                ['uploaded-polygon', 'uploaded-polygon-outline', 'uploaded-point'])
    }, [uploadedCollection]); // eslint-disable-line react-hooks/exhaustive-deps


    return (<div id='map' ref={mapContainerRef} className="position-relative h-100 w-100"></div>)

};

export default MapboxView;