import { useEffect } from 'react';
import { panelPublicUrl } from '../../../../panelurl';
import './Panel.css'
interface Props {
    panels: any[];
    monitor_view_type: string;
}
const EPS: React.FC<Props> = ({ panels, monitor_view_type }) => {

    useEffect(() => {
        window.addEventListener("blur", function (e) {
            setTimeout(function () {
                window.focus();
            }, 0);
        });
    }, [])

    return (
        <div className={`${monitor_view_type === 'Grid' ? 'monitor__grid__view' : 'monitor__list__view'}`}>
            {panels && panels.length > 0 && (
                panels.map((panel: { panel_name: string; url: string }) => (
                    <div className='monitor__item__container' key={panel.panel_name}>
                        {panel.url && (
                            <iframe src={`${panelPublicUrl}${panel.url}`} className='panel__frame' />
                        )}
                    </div>
                ))
            )}
        </div>

    )
}

export default EPS