//@ts-nocheck
import { useDispatch, useSelector } from 'react-redux';
import ModalHeader from '../../commonComponents/ModalHeader';
import { DeorbitIcon, EclipseIcon } from '../../commonMMSvgs/MissionModelingSvgs';
import './deorbitCoverageReport.css'
import * as mmActions from '../../../../store/MissionModellingSlice.js'
import { useEffect, useState } from 'react';

const DeorbitCoverage = () => {
    const dispatch = useDispatch()

    const deorbitEclipseReport = useSelector(state => state.missionModelling.deorbitEclipseReport)
    const deorbitEclipseReportName = useSelector(state => state.missionModelling.deorbitEclipseReportName)
    const [eclipseReport, setEclipseReport] = useState()
    const [deOrbitReport, setDeOrbitReport] = useState()

    const isModalMinimized = useSelector(state => state.missionModelling.isModalMinimized);

    useEffect(() => {
        setEclipseReport(deorbitEclipseReport?.eclipse_report)
        setDeOrbitReport(deorbitEclipseReport?.deorbit_report)
    }, [deorbitEclipseReport])

    const closeModal = () => {
        dispatch(mmActions.getDeorbitEclipseReportRequest())
    }

    const convertMonthsToYearsAndMonths = (totalMonths) => {
        totalMonths = Math.round(totalMonths);

        const years = Math.floor(totalMonths / 12);
        const months = totalMonths % 12;

        return `${years} year ${months} months`
    }

    return (
        <div className='main_report_container'>
            <ModalHeader
                title={deorbitEclipseReportName}
                handleClose={() => { closeModal() }}
            />

            {!isModalMinimized && (
                <div className='report_container_body'>
                    <div className='eclipse_report'>
                        <div className='report_title'>
                            <EclipseIcon />
                            <span>
                                Eclipse Details
                            </span>
                        </div>
                        <div className='report_body'>
                            <div className='report_body_item'>
                                <span>
                                    Avg Eclipse Duration
                                </span>
                                <span>
                                    {eclipseReport?.overview?.avgEclipseDuration?.toFixed(2)} min
                                </span>
                            </div>
                            <div className='report_body_item'>
                                <span>
                                    Max Eclipse Duration
                                </span>
                                <span>
                                    {eclipseReport?.overview?.maxEclipseDuration?.toFixed(2)} min
                                </span>
                            </div>
                            {/* <div className='report_body_item'>
                            <span>
                                eclipse fraction
                            </span>
                            <span>
                                .39
                            </span>
                        </div> */}
                            {/* <div className='report_body_item'>
                                <span>
                                    No. of Eclipses
                                </span>
                                <span>
                                    {eclipseReport?.overview?.noOfEclipse?.toFixed(2)}
                                </span>
                            </div> */}
                        </div>
                    </div>
                    <div className='deorbit_report'>
                        <div className='deorbit_report_title'>
                            <div className='report_title'>
                                <DeorbitIcon />
                                <span>
                                    Deorbit Details
                                </span>
                            </div>
                            <div className='population_section'>
                                <span>
                                    Without Propulsion
                                </span>
                            </div>
                        </div>
                        <div className='report_body'>
                            <div className='report_body_item'>
                                <span>
                                    De-orbiting Period
                                </span>
                                <span>
                                    {convertMonthsToYearsAndMonths(deOrbitReport?.overview?.deorbitPeriod)}
                                </span>
                            </div>
                            {/* <div className='report_body_item'>
                            <span>
                                Altitude
                            </span>
                            <span>
                                33.51 mins
                            </span>
                        </div>
                        <div className='report_body_item'>
                            <span>
                                Deorbiting Altitude
                            </span>
                            <span>
                                .39
                            </span>
                        </div> */}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DeorbitCoverage;
