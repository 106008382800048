import { panelPublicUrl } from '../../../../panelurl';
import './Panel.css'
import { Fragment, useState, useEffect } from "react";
interface Props {
    panels: any;
    monitor_view_type: string;
}
const Payload: React.FC<Props> = ({ panels, monitor_view_type }) => {

    const [selectedPayload, setSelectedpayload] = useState('');
    const [payloads, setPayloads] = useState<string[]>([]);

    useEffect(() => {
        if (panels && Object.keys(panels)?.length > 0) {
            let payloads: string[] = []
            let selected_payload: string = ''
            Object.keys(panels).map((type) => {
                payloads.push(type)
                selected_payload = selected_payload === '' ? type : selected_payload
            })
            setSelectedpayload(payloads?.length > 0 ? payloads[0] : '')
            setPayloads(payloads)
        }
    }, [panels])

    useEffect(() => {
        window.addEventListener("blur", function (e) {
            setTimeout(function () {
                window.focus();
            }, 0);
        });
    }, [])

    return (

        <Fragment>
            {payloads?.length > 1 && <div className="monitor__container__body__header">
                {payloads.map((btn: string) => (
                    <button
                        className={`button ${selectedPayload === btn && 'active'}`}
                        onClick={() => setSelectedpayload(btn)}
                    >
                        {btn}
                    </button>
                ))}
            </div>}

            <div className={`${monitor_view_type === 'Grid' ? 'monitor__grid__view' : 'monitor__list__view'}`}>
                {panels[selectedPayload] && panels[selectedPayload].length > 0 && (
                    panels[selectedPayload].map((panel: { panel_name: string; url: string }) => (
                        <div className='monitor__item__container' key={panel.panel_name}>
                            {panel.url && (
                                <iframe src={`${panelPublicUrl}${panel.url}`} className='panel__frame' />
                            )}
                        </div>
                    ))
                )}
            </div>
        </Fragment>
    )
}

export default Payload