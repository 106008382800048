import "./Orders.css";

import { formatDate } from "../Utils/HelperFunctions";
import CircularProgressBarComponent from "../CircularProgressBarComponent";
import { useEffect } from "react";
import { ucp_visualization_presentation } from "../../Utils";

const OrderDescriptionBox = ({ headerElement, orderDescriptionBoxVisibility, ucpViewer }) => {
  useEffect(() => {
    let dataSourceName;
    switch (headerElement?.status?.state) {
      case 'Current':
        dataSourceName = 'Current_Order'
        break
      case 'Pending':
        dataSourceName = 'Pending_Order'
        break
      case 'Executed':
        dataSourceName = 'Executed_Order'
        break
    }

    ucp_visualization_presentation().refresh_aoi_view(ucpViewer, dataSourceName)
    if (orderDescriptionBoxVisibility.display !== 'none') {
      ucp_visualization_presentation().zoom_into_selected_entity(ucpViewer, dataSourceName, headerElement?.spec?.geofeature?.features[0]?.id)
    }
  }, [orderDescriptionBoxVisibility])

  return (
    <div className="orderDescriptionBox" style={orderDescriptionBoxVisibility}>
      {headerElement?.status?.state != "Delay" && (
        <>
          <div className="orderDescriptionBoxHeaderDiv">
            <div className="orderDescriptionBoxHeader">
              <div className="priorityIconDiv">
                {headerElement?.spec?.attributes.priorityLane === 'High' && <img className="priorityIcon" src={require("../../ImgAssets/HighPriorityIcon.svg").default} />}
                {headerElement?.spec?.attributes.priorityLane === 'Normal' && <img className="priorityIcon" src={require("../../ImgAssets/MediumPriorityIcon.svg").default} />}
                {headerElement?.spec?.attributes.priorityLane === 'Low' && <img className="priorityIcon lowPriorityIcon lowerPlaced" src={require("../../ImgAssets/LowPriorityIcon.svg").default} />}
              </div>
              <div className="orderTitleName">{headerElement?.spec?.name}</div>
            </div>
            {headerElement?.spec?.attributes.recurring && (
              <div style={{ position: "absolute", right: "80px" }}>
                <img className="pauseIcon recurringIcon" src={require("../../ImgAssets/RecurringIcon.svg").default} />
              </div>
            )}
            <div className="orderProgressDiv descBoxOrderProgressDiv">
              <CircularProgressBarComponent
                item={headerElement}
              >
              </CircularProgressBarComponent>
            </div>
          </div>
          <div className="orderDescriptionBoxDatesDiv">
            <div className="orderDescriptionBoxDates">
              <div className="orderDescriptionBoxDatesTitle">{formatDate(headerElement.createdAt)}</div>
              <div className="orderDescriptionBoxDatesSubTitle">Start Date</div>
            </div>
            <div className="orderDescriptionBoxDates">
              <div className="orderDescriptionBoxDatesTitle">{formatDate(headerElement.updatedAt)}</div>
              <div className="orderDescriptionBoxDatesSubTitle">End Date</div>
            </div>
          </div>
          <div className="orderDescriptionBoxBodyDiv">
            <div className="orderDescriptionBoxHeaderDiv">
              <div className="orderDescriptionBoxBodyHeader">Order Details</div>
              <div className="orderDescriptionBoxBodyStatus">{headerElement?.status?.state}</div>
            </div>
            <div className="">
              <div style={{ width: '100%', height: '20px' }}>
                <div className="orderDescriptionBoxLabel">AOI label</div>
                <div className="orderDescriptionBoxValue">{headerElement?.spec?.name}</div>
              </div>
              <div style={{ width: '100%', height: '20px' }}>
                <div className="orderDescriptionBoxLabel">Image Spectral</div>
                <div className="orderDescriptionBoxValue">{headerElement?.spec?.attributes.imagingSpectrum}</div>
              </div>
              <div style={{ width: '100%', height: '20px' }}>
                <div className="orderDescriptionBoxLabel">Cloud Coverage</div>
                <div className="orderDescriptionBoxValue">{headerElement?.spec?.attributes.cloudCoverage}</div>
              </div>
              <div style={{ width: '100%', height: '20px' }}>
                <div className="orderDescriptionBoxLabel">Resolution</div>
                <div className="orderDescriptionBoxValue">{headerElement?.spec?.attributes.resolution}</div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OrderDescriptionBox;

// ~~~~~~~ This can be reused in case of delay state: ~~~~~~~~

// {headerElement?.status?.state === "Delay" && (
// <>
//   <div className="pauseDiv">
//     <img className="pauseIcon" src={require("../../ImgAssets/Pause_Icon.svg").default} />
//     <div className="delayedText">Order on Hold</div>
//   </div>
//   <div className="orderTitleDiv orderDescriptionBoxTitleDiv delayedBox">
//     <div className="orderTitleName whiteTitle delayedBox">{headerElement?.spec?.name}</div>
//     </div>
//     <div className="delayedTextBar">Order with more priority is to be scheduled</div>
//   </>
// )}
