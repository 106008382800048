import { FC } from "react";
import './CustomRadioButton.scss'
interface CustomRadioButtonProps {
    value: string;
    onChange: (value: string) => void;
    button_list: string[];
    disabledItems?: string[];
}

const CustomRadioButton: FC<CustomRadioButtonProps> = ({ value, onChange, button_list, disabledItems }) => {
    return (
        <div className="radio__btn__group__container">
            {button_list.map(btn => (
                <button
                    disabled={disabledItems && disabledItems.includes(btn)}
                    className={`radio__btn__container ${value === btn && 'active'}`}
                    key={btn}
                    onClick={() => { onChange(btn) }}
                >
                    <div className="radio__btn" />
                    <span className="radio__label">{btn === "Raw" ? `${btn} Input` : `${btn} Commands`}</span>
                </button>
            ))}
        </div>
    )
}

export default CustomRadioButton