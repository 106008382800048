import { Fragment, useState } from "react";
import { useOrdersVariables, useSpikeVariables } from "../StateVariablesDefinitions";

const AnalyticsOrderSelection = ({ props }) => {
  const [Orders, setOrders] = useOrdersVariables();
  const [currentOrderName, setCurrentOrderName] = useState("All Orders");
  const [orderNumber, setOrderNumber] = useState(-1);

  const setOrder = (num) => {
    setOrderNumber((prevOrderNumber) => {
      const newOrderNumber = prevOrderNumber + num;
      if (newOrderNumber >= -1 && newOrderNumber < Orders.length) {
        if (newOrderNumber === -1) {
          setCurrentOrderName("All Orders");
        } else {
          setCurrentOrderName(Orders[newOrderNumber].name);
        }
        return newOrderNumber;
      }
      return prevOrderNumber;
    });
  };

  return (
    <>
      <div className="analyticsOrderSelectionDiv">
        <button onClick={() => setOrder(-1)} disabled={orderNumber === -1}>
          <img src={require("../../ImgAssets/WhiteDropdownArrow.svg").default} />
        </button>
        {currentOrderName}
        <button onClick={() => setOrder(1)} disabled={orderNumber === Orders.length - 1}>
          <img className="imgFlipped" src={require("../../ImgAssets/WhiteDropdownArrow.svg").default} />
        </button>
      </div>
    </>
  );
};

export default AnalyticsOrderSelection;
