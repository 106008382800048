//@ts-nocheck
import { Form } from "react-bootstrap";
import "./notificationMessage.css"
import { CloseSvg, FailureMessageIcon, SaveSvg, SuccessMessageIcon } from "../commonMMSvgs/MissionModelingSvgs";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import * as mmActions from '../../../store/MissionModellingSlice.js'

const NotificationMessage = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const {
        mission_name,
        mission_id
    } = params;

    const notification = useSelector(state => state.missionModelling.notification)

    useEffect(() => {
        if (notification.isError || notification.isSuccess) {
            const timer = setTimeout(() => {
                dispatch(mmActions.clearNotification());
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [notification, dispatch]);

    return (
        <div className={`messageContainer ${notification.isError && `error_message_container`}`}>
            {notification.isSuccess && <SuccessMessageIcon />}
            {notification.isError && <FailureMessageIcon />}

            {notification.isSuccess && <span className={`${`success_message_text`}`}> {notification.message} </span>}
            {notification.isError && <span className={`${`error_message_text`}`}> {notification.message} </span>}
        </div>
    );
};

export default NotificationMessage;