//@ts-nocheck
import * as Cesium from "cesium";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react';


export function ucp_visualization_presentation() {
    const getArrayCoordinates = (nestedArray) => {
        let resultArray = [];
        resultArray = resultArray.concat(...nestedArray);
        let finalArray = [];
        for (let i = 0; i < resultArray.length; i++) {
            finalArray.push(resultArray[i]);  // Push the current element
            if ((i % 2 === 1)) { // i >= 2 ensures we start from the 3rd element
                finalArray.push(0);  // Push 0 after every element at the specified odd index
            }
        }
        return finalArray;
    }
    const createDocument = () => {
        let document = [];
        document.push({
            "clock": {
                "multiplier": 1,
                "range": "CLAMPED",
                "step": "SYSTEM_CLOCK_MULTIPLIER"
            },
            "id": "document",
            "name": "Satellite Object",
            "version": "1.0"
        })
        return document;
    }
    const getSatFromGroup = (constellation) => {
        let satelliteArray = [];
        satelliteArray = constellation?.groups[0]?.satellites
    }

    const getAvailibility = (startTime, duration) => {
        let start = startTime * 1000
        const startTimeISO = new Date(start)
        let end = start + duration * 60000
        const endTimeISO = new Date(end)
        return {
            startTimeISO, endTimeISO
        }
    }

    function removeDataSourceByName(viewer, dataSourceName) {
        const dataSources = viewer.dataSources;  // Get the data sources collection
        if (!dataSources) return
        // Iterate over the data sources using a for loop
        for (let i = 0; i < dataSources.length; i++) {
            const dataSource = dataSources.get(i);  // Get the data source at index i
            // Check if the data source has a name that matches the one we're looking for
            if (dataSource.name === dataSourceName) {
                dataSources.remove(dataSource);
                return dataSource;  // Exit the function after removing
            }
        }
    }

    function getDataSourceByName(viewer, dataSourceName) {
        const dataSources = viewer.dataSources;  // Get the data sources collection
        // Iterate over the data sources using a for loop
        if (!dataSources) return
        for (let i = 0; i < dataSources.length; i++) {
            const dataSource = dataSources.get(i);  // Get the data source at index i

            if (dataSource.name === dataSourceName) {
                return dataSource;  // Return the removed data source
            }
        }
        return null;
    }

    const hide_show_datasource = (ucp_viewer, dataSourceName) => {
        const dataSource = getDataSourceByName(ucp_viewer, dataSourceName);
        if (!dataSource) return
        dataSource.show = !dataSource.show;
    }

    const reset_viewer_position = (mm_viewer) => {
        mm_viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(0, 0, 34000000)
        });
    }

    const getOrbitObjectAvailability = (mm_viewer, dataSourceName) => {
        const dataSource = getDataSourceByName(mm_viewer, dataSourceName);
        if (!dataSource) return
        if (Cesium.defined(dataSource) && Cesium.defined(dataSource.clock)) {
            const clock = dataSource.clock;
            const startTime = clock.startTime;
            const stopTime = clock.stopTime;
            const start = Cesium.JulianDate.toDate(startTime).toISOString();
            const stop = Cesium.JulianDate.toDate(stopTime).toISOString();
            return `${start}/${stop}`
        }
    }


    return {
        init_default_gs: (mm_viewer) => {
            const groundStationInput = {
                name: 'GS_01',
                lat: 35.5,    // Latitude should be a number
                long: 35.5,   // Longitude should be a number
                groundStationId: 'new_default_gs'
            };
            const default_entity = {
                "billboard": {
                    "horizontalOrigin": Cesium.HorizontalOrigin.CENTER,  // Use Cesium constants
                    "image": '/assets/un_select_gs.svg',
                    "pixelOffset": new Cesium.Cartesian2(0, 0),          // Correctly use Cartesian2
                    "show": true,
                    "verticalOrigin": Cesium.VerticalOrigin.CENTER       // Use Cesium constants
                },
                "label": {
                    "fillColor": Cesium.Color.fromBytes(255, 255, 255, 255), // Correct RGBA to Cesium.Color
                    "font": "montserrat",
                    "horizontalOrigin": Cesium.HorizontalOrigin.LEFT,        // Use Cesium constants
                    "outlineColor": Cesium.Color.fromBytes(0, 0, 0, 255),    // Correct outline RGBA
                    "outlineWidth": 2,
                    "pixelOffset": new Cesium.Cartesian2(15, 0),             // Correctly use Cartesian2
                    "show": true,
                    "style": Cesium.LabelStyle.FILL_AND_OUTLINE,             // Use Cesium constants
                    "verticalOrigin": Cesium.VerticalOrigin.CENTER,          // Use Cesium constants
                    "text": groundStationInput?.name,
                },
                "name": groundStationInput?.name,
                "position": Cesium.Cartesian3.fromDegrees(groundStationInput.lat, groundStationInput.long, 0), // Correct position to use lat/long
                "id": groundStationInput?.groundStationId
            };

            const dataSource = getDataSourceByName(mm_viewer, 'GS_Object')
            if (dataSource) {
                const entityId = 'new_default_gs';
                const entity = dataSource.entities.getById(entityId);
                if (!entity) {
                    dataSource.entities.add(default_entity);
                }
            } else {
                gs_visualization_presentation().init_all_gs([groundStationInput], mm_viewer)
            }
        },
        update_default_gs: (groundStationInput, mm_viewer) => {
            if (mm_viewer.dataSources) {
                const dataSource = getDataSourceByName(mm_viewer, 'GS_Object')
                if (!dataSource) return
                const entityId = "new_default_gs"; // Example entity id
                const entity = dataSource.entities.getById(entityId);
                if (!entity) return
                const newLat = Number(groundStationInput.latitude)
                const newLong = Number(groundStationInput.longitude)
                const newAltitude = 0;
                entity.label.text = groundStationInput.groundStationName
                if (entity && entity.position instanceof Cesium.ConstantPositionProperty) {
                    const newPosition = Cesium.Cartesian3.fromDegrees(newLong, newLat, newAltitude);
                    entity.position.setValue(newPosition);
                }
            }
        },
        selected_gs: (ground_stations, mm_viewer) => {
            const dataSource = getDataSourceByName(mm_viewer, 'GS_Object')
            if (!dataSource) return
            const all_entities = dataSource.entities
            all_entities.values.forEach(entity => {
                if (entity && entity?._id && ground_stations.includes(entity?._id)) {
                    entity.billboard.image = '/assets/select_gs.svg';
                    entity.label.show = true
                } else {
                    entity.billboard.image = '/assets/un_select_gs.svg'; // Set the new image
                    entity.label.show = false
                }
            });
        },
        remove_default_gs: (mm_viewer) => {
            const dataSource = getDataSourceByName(mm_viewer, 'GS_Object')
            if (!dataSource) return
            const entityId = "new_default_gs"; // Example entity id
            const entity = dataSource.entities.getById(entityId);
            dataSource.entities.remove(entity)
        },
        init_all_gs: (all_ground_stations, mm_viewer) => {
            let gsPacketList: any[] = [];
            all_ground_stations.forEach((gs_station) => {
                const packet = {
                    "billboard": {
                        "horizontalOrigin": Cesium.HorizontalOrigin.CENTER,  // Use Cesium constants
                        "image": '/assets/un_select_gs.svg',
                        "pixelOffset": new Cesium.Cartesian2(0, 0),          // Correctly use Cartesian2
                        "show": true,
                        "verticalOrigin": Cesium.VerticalOrigin.CENTER       // Use Cesium constants
                    },
                    "label": {
                        "fillColor": Cesium.Color.fromBytes(255, 255, 255, 255), // Correct RGBA to Cesium.Color
                        "font": "montserrat",
                        "horizontalOrigin": "LEFT",        // Use Cesium constants
                        "outlineColor": Cesium.Color.fromBytes(0, 0, 0, 255),    // Correct outline RGBA
                        "outlineWidth": 2,
                        "pixelOffset": {
                            "cartesian2": [
                                15,
                                0
                            ]
                        },
                        "show": false,
                        "style": "FILL_AND_OUTLINE",             // Use Cesium constants
                        "verticalOrigin": "CENTER",          // Use Cesium constants
                        "text": gs_station?.groundStationName ? gs_station?.groundStationName : gs_station?.name,
                    },
                    "name": gs_station?.groundStationName ? gs_station?.groundStationName : gs_station?.name,
                    "position": {
                        "cartographicDegrees": [
                            gs_station?.properties?.longitude ? Number(gs_station?.properties?.longitude) : gs_station?.long,
                            gs_station?.properties?.latitude ? Number(gs_station?.properties?.latitude) : gs_station?.lat,
                            gs_station?.properties?.altitude ? Number(gs_station?.properties?.altitude) : 0
                        ]
                    },
                    "id": gs_station?.groundStationId
                };
                gsPacketList.push(packet)
            })

            const dataSource = new Cesium.CzmlDataSource();
            mm_viewer && mm_viewer.dataSources.add(dataSource.load([
                {
                    "clock": {
                        "multiplier": 1,
                        "range": "CLAMPED",
                        "step": "SYSTEM_CLOCK_MULTIPLIER"
                    },
                    "id": "document",
                    "name": "GS_Object",
                    "version": "1.0"
                },
                ...gsPacketList
            ]))
        },
        init_all_aois: (aois, ucp_viewer, dataSourceName) => {
            if (aois && ucp_viewer) {
                let aoisArray: any[] = []
                aois.forEach((aoi, index) => {
                    let coordinates = getArrayCoordinates(aoi.polygon);
                    let packet = {
                        "description": "aoi",
                        "id": aoi?.areaOfInterestId,
                        "name": aoi?.name,
                        "polygon": {
                            "extrudedHeight": 0,
                            "height": 0,
                            "material": {
                                "solidColor": {
                                    "color": {
                                        "rgba": [
                                            204, 245, 78, 50
                                        ]
                                    }
                                }
                            },
                            "arcType": "RHUMB",
                            "positions": {
                                "cartographicDegrees": coordinates
                            }
                        }
                    }
                    aoisArray.push(packet)
                })

                const dataSource = new Cesium.CzmlDataSource();
                removeDataSourceByName(ucp_viewer, dataSourceName)
                ucp_viewer && ucp_viewer.dataSources.add(dataSource.load([
                    {
                        "clock": {
                            "multiplier": 1,
                            "range": "CLAMPED",
                            "step": "SYSTEM_CLOCK_MULTIPLIER"
                        },
                        "id": "document",
                        "name": dataSourceName,
                        "version": "1.0"
                    },
                    ...aoisArray
                ]))
            }
        },

        preview_strips: (ucp_viewer, aois, dataSourceName) => {
            if (aois?.coordinates && ucp_viewer) {
                let aoisArray: any[] = []
                aois?.coordinates?.forEach((aoi, index) => {
                    let coordinates = getArrayCoordinates(aoi);
                    let packet = {
                        "description": "aoi",
                        "id": index,
                        "name": index,
                        "show": false,
                        "polygon": {
                            "extrudedHeight": 0,
                            "height": 0,
                            "material": {
                                "solidColor": {
                                    "color": {
                                        "rgba": [
                                            36, 101, 178, 100
                                        ]
                                    }
                                }
                            },
                            "arcType": "RHUMB",
                            "positions": {
                                "cartographicDegrees": coordinates
                            }
                        }
                    }
                    aoisArray.push(packet)
                })

                const dataSource = new Cesium.CzmlDataSource();
                removeDataSourceByName(ucp_viewer, dataSourceName);
                ucp_viewer && ucp_viewer.dataSources &&
                    Object.keys(ucp_viewer.dataSources).length > 0
                    && ucp_viewer.dataSources.add(dataSource.load([
                        {
                            "clock": {
                                "multiplier": 1,
                                "range": "CLAMPED",
                                "step": "SYSTEM_CLOCK_MULTIPLIER"
                            },
                            "id": "document",
                            "name": dataSourceName,
                            "version": "1.0"
                        },
                        ...aoisArray
                    ]))
            }
        },

        remove_preview_strips: (ucp_viewer, dataSourceNames) => {
            dataSourceNames.map((item, index) => {
                removeDataSourceByName(ucp_viewer, item);
            });
        },

        selected_aois: (aois, mm_viewer) => {
            const dataSource = getDataSourceByName(mm_viewer, 'AOI_Object')
            if (!dataSource) return
            const all_entities = dataSource.entities
            all_entities.values.forEach(entity => {
                if (entity && entity?._id && aois.includes(entity?._id)) {
                    if (Cesium.defined(entity) && Cesium.defined(entity.polygon) && Cesium.defined(entity.polygon.material)) {
                        const currentMaterial = entity.polygon.material.color;
                        if (!Cesium.Color.equals(currentMaterial.getValue(Cesium.JulianDate.now()), Cesium.Color.fromBytes(255, 255, 255, 50))) {
                            entity.polygon.material = new Cesium.ColorMaterialProperty(Cesium.Color.fromBytes(255, 255, 255, 50));
                            // entity.polygon.outline = true; // Enable the outline
                            // entity.polygon.outlineColor = new Cesium.Color.fromBytes(255, 255, 255, 255);
                        }
                    }

                    // "outline": true,  // Enabling outline
                    // "outlineColor": {
                    //     "rgba": [255, 0, 0, 255]  // Red outline with full opacity
                    // }
                } else {
                    if (Cesium.defined(entity) && Cesium.defined(entity.polygon) && Cesium.defined(entity.polygon.material)) {
                        const currentMaterial = entity.polygon.material.color;
                        if (!Cesium.Color.equals(currentMaterial.getValue(Cesium.JulianDate.now()), Cesium.Color.fromBytes(204, 245, 78, 50))) {
                            entity.polygon.material = new Cesium.ColorMaterialProperty(Cesium.Color.fromBytes(204, 245, 78, 50));
                        }
                    }

                }
            });
        },
        init_all_satellites: (allOrbitData, mm_viewer) => {
            let satelliteData = [];

            if (allOrbitData?.length > 0) {
                const allReports = allOrbitData.flatMap(item => item.orbit_report);
                if (allReports.length > 0) {
                    allReports.forEach(satellite => {
                        let satAvailability = getAvailibility(satellite.startTime, satellite.duration);
                        let startTime = satAvailability.startTimeISO.toISOString();
                        let endTime = satAvailability.endTimeISO.toISOString();
                        let satAvailabilityy = `${startTime}/${endTime}`;
                        const orbitalPointArray = satellite?.orbit_points;

                        // Check if orbital points are defined and valid
                        if (Array.isArray(orbitalPointArray) && orbitalPointArray.length > 0) {
                            let packet = {
                                availability: satAvailabilityy,
                                billboard: {
                                    eyeOffset: { cartesian: [0, 0, 0] },
                                    horizontalOrigin: "CENTER",
                                    image: '/assets/un_select_gs.svg',
                                    pixelOffset: { cartesian2: [0, 0] },
                                    scale: 0.75,
                                    show: true,
                                    verticalOrigin: "CENTER"
                                },
                                description: "orbit",
                                id: satellite?.satelliteId,
                                path: {
                                    leadTime: [{ number: 5000 }],
                                    width: 1,
                                    material: {
                                        solidColor: {
                                            color: { rgba: [142, 125, 238, 100] } // Semi-transparent color
                                        }
                                    },
                                    resolution: 10,
                                    show: [{ boolean: true, interval: `${startTime}/${endTime}` }],
                                    trailTime: [{ number: 5000 }]
                                },
                                position: {
                                    cartographicDegrees: orbitalPointArray, // Ensure this is in [lon, lat, height] format
                                    epoch: satAvailabilityy.split('/')[0]
                                }
                            };

                            satelliteData.push(packet);
                        } else {
                            console.warn(`Invalid orbital points for satellite ID: ${satellite?.satelliteId}`);
                        }
                    });

                    const dataSource = new Cesium.CzmlDataSource();
                    removeDataSourceByName(mm_viewer, 'Orbit_Object');
                    let satAvailability = getAvailibility(allReports[allReports?.length - 1].startTime, allReports[allReports.length - 1].duration);
                    let startTime = satAvailability.startTimeISO.toISOString();
                    let endTime = satAvailability.endTimeISO.toISOString();
                    let availability = `${startTime}/${endTime}`;
                    mm_viewer.dataSources.add(dataSource.load([
                        {
                            clock: {
                                interval: availability,
                                multiplier: 1,
                                range: "CLAMPED",
                                step: "SYSTEM_CLOCK_MULTIPLIER"
                            },
                            id: "document",
                            name: "Orbit_Object",
                            version: "1.0"
                        },
                        ...satelliteData
                    ]));
                }
            } else {
                removeDataSourceByName(mm_viewer, 'Orbit_Object');
            }

        },
        selected_satellite: (satellites, mm_viewer) => {
            const dataSource = getDataSourceByName(mm_viewer, 'Orbit_Object')
            if (!dataSource) return
            const all_entities = dataSource && dataSource.entities
            all_entities && all_entities.values.forEach(entity => {
                if (entity && entity?._id && satellites.includes(entity?._id)) {
                    if (Cesium.defined(entity) && Cesium.defined(entity.path) && Cesium.defined(entity.path.material)) {
                        const currentMaterial = entity.path.material.color;
                        if (!Cesium.Color.equals(currentMaterial.getValue(Cesium.JulianDate.now()), Cesium.Color.fromBytes(255, 255, 255, 50))) {
                            entity.path.material = new Cesium.ColorMaterialProperty(Cesium.Color.fromBytes(255, 255, 255, 50));
                        }
                    }

                } else {
                    if (Cesium.defined(entity) && Cesium.defined(entity.path) && Cesium.defined(entity.path.material)) {
                        const currentMaterial = entity.path.material.color;
                        if (!Cesium.Color.equals(currentMaterial.getValue(Cesium.JulianDate.now()), Cesium.Color.fromBytes(142, 125, 238, 100))) {
                            entity.path.material = new Cesium.ColorMaterialProperty(Cesium.Color.fromBytes(142, 125, 238, 100));
                            // entity.path.outline = true; // Enable the outline
                            // entity.polygon.outlineColor = new Cesium.Color.fromBytes(255, 255, 255, 255);
                        }
                    }

                }
            });
        },
        strip_mapping: (coverageAnalysisReport, mm_viewer) => {
            hide_show_datasource(mm_viewer, 'GS_Object')
            let satAvailabilityy = getOrbitObjectAvailability(mm_viewer, 'Orbit_Object');
            let zoomInPoint;
            let coverageAreaBody = [{
                "clock": {
                    "interval": satAvailabilityy,
                    "multiplier": 1,
                    "range": "LOOP_STOP",
                    "step": "SYSTEM_CLOCK_MULTIPLIER"
                },
                "id": "document",
                "name": "Strip_Orbit",
                "version": "1.0"
            }]
            coverageAnalysisReport?.areaOfInterests.map(aoi => {
                aoi?.visualisationData?.map((item, index) => {
                    zoomInPoint = { long: item[0][0], lat: item[0][1], alt: 0 }
                    let coverage = {
                        "description": "area of interest - " + index,
                        "id": "Facility/aoi/" + index,
                        "name": "ee",
                        "polygon": {
                            "extrudedHeight": 0,
                            "height": 0,
                            "material": {
                                "solidColor": {
                                    "color": {
                                        "rgba": [
                                            217,
                                            217,
                                            217,
                                            50
                                        ]
                                    }
                                }
                            },
                            "arcType": "RHUMB",
                            "outline": true,  // Enabling outline
                            "outlineColor": {
                                "rgba": [255, 0, 0, 255]  // Red outline with full opacity
                            },
                            "positions": {
                                "cartographicDegrees": [
                                    item[0][0], item[0][1], 0,
                                    item[1][0], item[1][1], 0,
                                    item[2][0], item[2][1], 0,
                                    item[3][0], item[3][1], 0,
                                ]
                            }
                        }
                    }
                    coverageAreaBody.push(coverage)
                })
            })
            removeDataSourceByName(mm_viewer, 'Strip_Orbit')
            mm_viewer?.dataSources?.add(Cesium.CzmlDataSource.load(coverageAreaBody)).then(() => {
                if (zoomInPoint) {
                    mm_viewer.camera.flyTo({
                        destination: Cesium.Cartesian3.fromDegrees(zoomInPoint.long, zoomInPoint.lat, 10000000)
                    });
                }
            });
        },
        remove_strip_mapping: (mm_viewer) => {
            hide_show_datasource(mm_viewer, 'GS_Object')
            removeDataSourceByName(mm_viewer, 'Strip_Orbit')
            reset_viewer_position(mm_viewer)
        },
        remove_entity_by_id: (entityId, dataSourceName, mm_viewer) => {
            const dataSource = getDataSourceByName(mm_viewer, dataSourceName)
            if (!dataSource) return
            const entity = dataSource.entities.getById(entityId);
            dataSource.entities.remove(entity)
        },

        hide_datasource: (ucp_viewer, dataSourceName) => {
            const dataSource = getDataSourceByName(ucp_viewer, dataSourceName);
            if (!dataSource) return
            dataSource.show = false;
        },
        show_datasource: (ucp_viewer, dataSourceName) => {
            const dataSource = getDataSourceByName(ucp_viewer, dataSourceName);
            if (!dataSource) return
            dataSource.show = true;
        },
        zoom_into_selected_entity: (ucp_viewer, dataSourceName, entityId) => {
            const dataSource = getDataSourceByName(ucp_viewer, dataSourceName)
            if (!dataSource) return

            const entity = dataSource.entities.getById(entityId);
            entity.polygon.material = new Cesium.ColorMaterialProperty(Cesium.Color.fromBytes(142, 125, 238, 100));
            // entity.polygon.outline = true; // Enable the outline
            // entity.polygon.outlineColor = new Cesium.Color.fromBytes(142, 125, 238, 100);

            ucp_viewer.flyTo(entity, {
                offset: new Cesium.HeadingPitchRange(
                    Cesium.Math.toRadians(0),  // Heading
                    Cesium.Math.toRadians(-90), // Pitch (top-down view)
                    5000000                      // Range (distance from the entity)
                )
            });
        },
        refresh_aoi_view: (ucp_viewer, dataSourceName) => {
            const dataSource = getDataSourceByName(ucp_viewer, dataSourceName)
            if (!dataSource) return

            dataSource.entities.values.forEach(entity => {
                if (Cesium.defined(entity) && Cesium.defined(entity.polygon)) {
                    entity.polygon.material = new Cesium.ColorMaterialProperty(Cesium.Color.fromBytes(204, 245, 78, 50));
                }
            });

            ucp_viewer.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(0, 0, 37000000), // Set the initial view position to be full view
                orientation: {
                    heading: Cesium.Math.toRadians(10),
                    pitch: Cesium.Math.toRadians(-90),
                    roll: 0,
                },
            });
        }
    }

}