//@ts-nocheck
import React, { useRef, useEffect, useState } from "react";
import * as Cesium from "cesium";

const MissionOrbit = ({ setUcpViewer }) => {

    useEffect(() => {
        let imaginary = new Cesium.MapboxStyleImageryProvider({
            styleId: 'dark-v10',
            accessToken: "pk.eyJ1IjoiYW50YXJpcy1tYXBib3giLCJhIjoiY2x2cGNubzF4MDBveTJtb2RtNG5zMGQ2NCJ9.MkPyl-z2FXuFSyYNwP_oaw"
        });

        const viewer = new Cesium.Viewer("cesiumContainer", {
            requestRenderMode: true,
            shouldAnimate: true,
            animation: false, // Disable the default animation widget
            baseLayerPicker: false, // Disable the default base layer picker
            timeline: false, // Disable the timeline
            geocoder: false, // Disable the geocoder
            homeButton: false, // Disable the home button
            navigationHelpButton: false, // Disable the navigation help button
            sceneModePicker: false, // Disable the scene mode picker
            selectionIndicator: false, // Disable the selection indicator
            fullscreenButton: false,
            imageryProvider: imaginary,
        });

        viewer.scene.globe.baseColor = Cesium.Color.WHITE; // Set a base color for the globe
        viewer.scene.globe.enableLighting = false; // Enable lighting to ensure consistent shading
        viewer.scene.globe.showGroundAtmosphere = false; // Show the atmospheric effects
        viewer.scene.gamma = 2.0; // Adjust the value based on your observation

        const resetViewer = () => {
            viewer.dataSources.removeAll();
        };


        viewer.clock.currentTime = viewer.clock.startTime;
        viewer.clock.shouldAnimate = true;
        viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP;
        viewer.scene.screenSpaceCameraController.enableRotate = true;
        // viewer.scene.screenSpaceCameraController.enableTranslate = true;
        viewer.scene.screenSpaceCameraController.enableZoom = true;
        viewer.scene.screenSpaceCameraController.enableTilt = true;
        viewer.scene.screenSpaceCameraController.enableLook = true;

        viewer.scene.morphTo2D(0);

        viewer.camera.flyTo({
            // Use flyTo instead of setView
            destination: Cesium.Cartesian3.fromDegrees(0, 0, 37000000), // Set the initial view position to be full view
            orientation: {
                heading: Cesium.Math.toRadians(10),
                pitch: Cesium.Math.toRadians(-90),
                roll: 0,
            },
        });
        // const dataSource = new Cesium.CzmlDataSource();
        // viewer.dataSources.add(dataSource.load([{
        //     "clock": {
        //         "multiplier": 1,
        //         "range": "CLAMPED",
        //         "step": "SYSTEM_CLOCK_MULTIPLIER"
        //     },
        //     "id": "document",
        //     "name": "GS_Object",
        //     "version": "1.0"
        // }]));

        setUcpViewer(viewer)

        return () => {
            viewer.destroy();
        };
    }, []);

    return (
        <div id="cesiumContainer" className="position-relative" style={{ height: "100vh", position: "relative", bottom: "10px" }} >
        </div>
    );
};

export default MissionOrbit;
