
interface ModalHeaderProps {
  close: () => void;
  toggle_body: () => void;
  minimize_modal: boolean;
}

const AdditionalOrbitModalHeader: React.FC<ModalHeaderProps> = ({ close, toggle_body, minimize_modal }) => {
  const header = {
    title: "Additional Orbital Details",
    img: require("../../Images/ScenarioCreationIcon.svg").default,
  };

  return (
    <div className="modalHeader d-flex justify-content-between">
      <div className="d-flex gap-0">
        <div className="modalHeaderImg">
          <img
            src={header.img}
            className="active-img"
          />
        </div>
        <span>{header.title}</span>
      </div>
      <div className="d-flex gap-0">
        <button
          className="modalCloseBtn"
          onClick={toggle_body}
        >
          {minimize_modal &&
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" viewBox="0 0 10 14" fill="none">
              <path d="M9.19981 13L4.9998 8.8L0.799805 13M0.799805 1L4.9998 5.2L9.19981 1" stroke="#F0F0F0" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          }
          {!minimize_modal &&
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
              <path d="M1.00625 11.2214L5.20625 15.4214L9.40625 11.2214M9.41113 5.2002L5.20048 1.01088L1.01116 5.22154" stroke="#F0F0F0" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          }
        </button>
        <button
          className="modalCloseBtn modalMinimizeBtn"
          onClick={close}
        >
          <img src={require("../../Images/CloseButtonWhite.svg").default} />
        </button>
      </div>
    </div>
  )
}

export default AdditionalOrbitModalHeader

