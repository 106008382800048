//@ts-nocheck
import { useEffect, useState } from "react";
import { ucp_visualization_presentation } from "../Utils";
import { useSelector } from "react-redux";
import { ORDER_STATUS } from "../UnifiedControlPlane.constants";

const FeaturesFilter = ({ props, ucpViewer }) => {

  const activeOrdersSection = useSelector(state => state.unifiedControlPlane.activeOrdersSection);

  const [isFeaturesFilter, setIsFeaturesFilter] = useState(false);
  const [layers, setLayers] = useState({ All_Layers: true, AOI: false, Ground_Station: false, Satellite: false });
  const layersName = [
    { label: "All Layers", id: "All_Layers" },
    { label: "AOI", id: "AOI" },
    { label: "Satellite", id: "Satellite" },
    { label: "Ground Station", id: "Ground_Station" },
  ];

  const hideAllAois = () => {
    ucp_visualization_presentation().hide_datasource(ucpViewer, 'Current_Order')
    ucp_visualization_presentation().hide_datasource(ucpViewer, 'Executed_Order')
    ucp_visualization_presentation().hide_datasource(ucpViewer, 'Pending_Order')
  }

  const showSelectedAOIs = () => {
    switch (activeOrdersSection) {
      case ORDER_STATUS.CURRENT:
        ucp_visualization_presentation().show_datasource(ucpViewer, 'Current_Order')
        ucp_visualization_presentation().hide_datasource(ucpViewer, 'Executed_Order')
        ucp_visualization_presentation().hide_datasource(ucpViewer, 'Pending_Order')
        break;
      case ORDER_STATUS.EXECUTED:
        ucp_visualization_presentation().show_datasource(ucpViewer, 'Executed_Order')
        ucp_visualization_presentation().hide_datasource(ucpViewer, 'Current_Order')
        ucp_visualization_presentation().hide_datasource(ucpViewer, 'Pending_Order')
        break;
      case ORDER_STATUS.PENDING:
        ucp_visualization_presentation().show_datasource(ucpViewer, 'Pending_Order')
        ucp_visualization_presentation().hide_datasource(ucpViewer, 'Executed_Order')
        ucp_visualization_presentation().hide_datasource(ucpViewer, 'Current_Order')
        break;
      default:
        ucp_visualization_presentation().hide_datasource(ucpViewer, 'Pending_Order')
        ucp_visualization_presentation().hide_datasource(ucpViewer, 'Executed_Order')
        ucp_visualization_presentation().hide_datasource(ucpViewer, 'Current_Order')
        break
    }
  }

  useEffect(() => {
    if (ucpViewer) {
      if (layers.All_Layers) {
        ucp_visualization_presentation().show_datasource(ucpViewer, 'Orbit_Object')
        ucp_visualization_presentation().show_datasource(ucpViewer, 'GS_Object')
        showSelectedAOIs()
      } else {
        layers.AOI ? showSelectedAOIs() : hideAllAois()
        layers.Satellite ? ucp_visualization_presentation().show_datasource(ucpViewer, 'Orbit_Object') : ucp_visualization_presentation().hide_datasource(ucpViewer, 'Orbit_Object')
        layers.Ground_Station ? ucp_visualization_presentation().show_datasource(ucpViewer, 'GS_Object') : ucp_visualization_presentation().hide_datasource(ucpViewer, 'GS_Object')
      }
    }
  }, [layers, ucpViewer])

  return (
    <>
      <div className="ucpFeaturesBar" style={{ width: "auto" }}>
        <button className="ucpFeaturesBarBtn" onClick={() => setIsFeaturesFilter(!isFeaturesFilter)}>
          {!isFeaturesFilter && <img src={require("../ImgAssets/FilterIconWhite.svg").default} />}
          {isFeaturesFilter && <img src={require("../ImgAssets/CloseIconWhite.svg").default} />}
        </button>
      </div>
      {isFeaturesFilter && (
        <div className="featuresList">
          {layersName.map((item) => (
            <div className="modalInputBox">
              <button className="modalInputRoundedBoxButton" onClick={() => setLayers({ ...layers, [item.id]: !layers[item.id] })}></button>
              <img className={layers[item.id] ? "singleTickIcon" : "singleTickIcon hideIcon"} src={require("../ImgAssets/SingletickIcon.svg").default} />
              <div className="modalInputBoxText">{item.label}</div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default FeaturesFilter;
