import { useEffect, useState } from "react";
import "./Orders.css";
// import "./../UcpComponents.css";

import OrderDescriptionBox from "./OrderDescriptionBox";
import FeaturesFilter from "../FeaturesFilter";
import OrdersBarFeaturesList from "../OrdersBarFeaturesList";
// import SubOrdersBarFeaturesList from "../SubOrdersBarFeaturesList";
import ExpandedSubOrderTable from "../ExpandedSubOrderTable";
import RenderSubOrder from "../RenderSubOrder";
import HoverBoxNotification from "../HoverBoxNotification";
import { OrdersSpikeMock } from "../StateVariablesDefinitions";
import CircularProgressBarComponent from "../CircularProgressBarComponent";
import ThreeDotMenuComponent from "../ThreeDotMenuComponent";
import { useDispatch, useSelector } from "react-redux";
import { setOrdersSection, setShowMapBoxMap } from "../../../../store/UnifiedControlPlaneSlice";
import { ORDER_STATUS } from "../../UnifiedControlPlane.constants";
import { ucp_visualization_presentation } from "../../Utils";

const OrdersView = ({ props, ucpViewer }) => {

  const dispatch = useDispatch();

  const activeOrdersSection = useSelector(state => state.unifiedControlPlane.activeOrdersSection);

  const [threeDotOptionsMenu, setThreeDotOptionsMenu] = useState({ display: "none" });
  const [isThreeDotOptionsVisible, setIsThreeDotOptionsVisible] = useState(true);
  const [isOrdersAllOrders, setIsOrdersAllOrders] = useState(true);
  const [previewDataSources, setPreviewDataSources] = useState([]);

  const orders = useSelector(state => state.unifiedControlPlane.orders);

  const [headerElement, setHeaderElement] = useState({
    status: {
      state: '',
    },
    spec: {
      name: '',
    },
  });

  const [orderDescriptionBoxVisibility, setOrderDescriptionBoxVisibility] = useState({ display: "none" });

  const createNewOrder = () => {
    props.setIsOrdersBarVisible(false);
    props.setIsOrderCreationModal(true);
    dispatch(setShowMapBoxMap(true));
  };

  const showSingleOrderBar = (item) => {
    setIsOrdersAllOrders(false);
    setHeaderElement(item);
  };

  const showPreview = (item) => {
    const orderAssignments = item?.status?.schedule?.orderAssignments;

    if (!orderAssignments) return;

    let currentIndex = 0; // Start with the first order
    let previewList = [];
    const executeInitPreview = () => {
      if (currentIndex < orderAssignments.length) {
        const order = orderAssignments[currentIndex];
        ucp_visualization_presentation().preview_strips(ucpViewer, order.strip, "preview_"+currentIndex);
        previewList.push("preview_"+currentIndex)
        currentIndex++;
      } else {
        // Optionally clear the interval after processing all orders
        setPreviewDataSources(previewList);
        clearInterval(intervalId);
      }
    };

    executeInitPreview();
    const intervalId = setInterval(executeInitPreview, 3000);
    return intervalId;
  }

  const showOrderDescriptionBoxOnHover = (e, item, type) => {
    setHeaderElement(item);
    setOrderDescriptionBoxVisibility({ display: "block" });
  };

  const showOrderDescriptionBoxOffHover = (e, item, type) => {
    setOrderDescriptionBoxVisibility({ display: "none" });
  };

  const [hoverBoxNotificationVisibility, setHoverBoxNotificationVisibility] = useState({ display: "none" });
  const [hoverBoxElement, setHoverBoxElement] = useState({
    isType: "",
    name: "",
  });

  const showHoverBoxNotificationOnHover = (e, item, type) => {
    setHeaderElement(item);
    setHoverBoxElement({ isType: type, name: item.spec.name });
    setHoverBoxNotificationVisibility({ display: "block" });
  };

  const showHoverBoxNotificationOffHover = (e, item) => {
    setHoverBoxNotificationVisibility({ display: "none" });
  };

  const showAllOrders = () => {
    setIsOrdersAllOrders(true);
    ucp_visualization_presentation().remove_preview_strips(ucpViewer, previewDataSources);
  };

  const RenderOrder = (item, i) => (
    <div
      className={`ordersTypeHeaderBar activeHeaderBar`}
      key={`${item?.status?.state}-${i}`}
      onClick={(e) => {
        showPreview(item)
        showSingleOrderBar(item);
      }}
      onMouseEnter={(e) => showOrderDescriptionBoxOnHover(e, item)}
      onMouseLeave={(e) => showOrderDescriptionBoxOffHover(e, item)}
      style={orderDescriptionBoxVisibility.display === "block" && headerElement.spec.name === item.spec.name ? { background: "rgba(204, 245, 78, 0.10)" } : {}}
    >
      <div className="orderTitleDiv">
        <div className="priorityIconDiv">
          {item?.spec.attributes.priorityLane === 'High' && <img className="priorityIcon" src={require("../../ImgAssets/HighPriorityIcon.svg").default} />}
          {item?.spec.attributes.priorityLane === 'Normal' && <img className="priorityIcon" src={require("../../ImgAssets/MediumPriorityIcon.svg").default} />}
          {item?.spec.attributes.priorityLane === 'Low' && <img className="priorityIcon lowPriorityIcon" src={require("../../ImgAssets/LowPriorityIcon.svg").default} />}
        </div>
        <div className="orderTitleName">{item.spec.name}</div>
      </div>
      <button
        className="orderThreeDotMenu"
        onClick={() => {
          setThreeDotOptionsMenu(item.spec.name);
          setIsThreeDotOptionsVisible(!isThreeDotOptionsVisible);
        }}
      >
        <img src={require("../../ImgAssets/ThreeDotIcon.svg").default} />
        {threeDotOptionsMenu === item.spec.name && isThreeDotOptionsVisible && <ThreeDotMenuComponent item={item} />}
      </button>

      {item.status.state != "Pending" && (
        <div className="orderProgressDiv">
          {item.status.state != "Executed" && (
            <div onMouseEnter={(e) => showHoverBoxNotificationOnHover(e, item, item.status.state)} onMouseLeave={(e) => showHoverBoxNotificationOffHover(e, item)}>
              <CircularProgressBarComponent item={item} />
            </div>
          )}
          {item.status.state === "Executed" && (
            <div onMouseEnter={(e) => showHoverBoxNotificationOnHover(e, item, item.status.state)} onMouseLeave={(e) => showHoverBoxNotificationOffHover(e, item)}>
              <img className="pauseIcon" style={{ scale: "1.4", position: "relative", top: "4px" }} src={require("../../ImgAssets/GreenTickSmallIcon.svg").default} />
            </div>
          )}
        </div>
      )}
      {item.spec.attributes.recurring && (
        <>
          <div
            style={{ display: "contents" }}
            onMouseEnter={(e) => showHoverBoxNotificationOnHover(e, item, "Recurring")}
            onMouseLeave={(e) => showHoverBoxNotificationOffHover(e, item)}
          >
            <img className="pauseIcon recurringIcon" src={require("../../ImgAssets/RecurringIcon.svg").default} />
          </div>
        </>
      )}
      {item.status.state === "Pending" && (
        <>
          <button
            className="orderProgressDivPendingBtn"
            onMouseEnter={(e) => showHoverBoxNotificationOnHover(e, item, "Pending")}
            onMouseLeave={(e) => showHoverBoxNotificationOffHover(e, item)}
          >
            <img className="pauseIcon orderPendingIcon" src={require("../../ImgAssets/OrderPendingIcon.svg").default} />
          </button>
        </>
      )}
      <div className="hoverBoxParentContainer">
        {hoverBoxElement.name === item.spec.name && (
          <HoverBoxNotification headerElement={headerElement} hoverBoxNotificationVisibility={hoverBoxNotificationVisibility} type={hoverBoxElement.isType} />
        )}
      </div>
    </div>
  );

  return (
    <>
      {props.isOrdersBarVisible && headerElement.status.state != "" && isOrdersAllOrders && !props.isOrdersBarFeaturesList && (
        <OrderDescriptionBox headerElement={headerElement} orderDescriptionBoxVisibility={orderDescriptionBoxVisibility} ucpViewer={ucpViewer} />
      )}
      {props.isOrdersBarVisible && <FeaturesFilter props={props} ucpViewer={ucpViewer} />}
      {/* {props.isOrdersBarVisible && !isOrdersAllOrders && <SubOrdersBarFeaturesList props={props} />} */}
      {props.isOrdersBarFeaturesList && <OrdersBarFeaturesList props={props} />}
      {props.isOrdersBarVisible && isOrdersAllOrders && (
        <div className="ordersBar">
          <div className="ordersGreenHeaderBar">
            <div className="orders">
              <button className="ordersGreenHeader" onClick={() => setIsOrdersAllOrders(true)}>
                Orders
              </button>
            </div>
            <div className="">
              <button onClick={() => createNewOrder()} className="addNewOrderBtn">
                + Order
              </button>
            </div>
          </div>

          <div className="scrollableOrdersBar">
            <div className="ordersTypeHeaderBar">
              <button onClick={() => activeOrdersSection !== ORDER_STATUS.EXECUTED ?
                dispatch(setOrdersSection(ORDER_STATUS.EXECUTED)) :
                dispatch(setOrdersSection(''))}
                className={`ordersDropdownArrow ${activeOrdersSection === ORDER_STATUS.EXECUTED ? "activeArrow" : ""}`}
              >
                <img src={require("../../ImgAssets/DropdownArrow.svg").default} />
              </button>
              <div className={`ordersTypeHeader ${activeOrdersSection === ORDER_STATUS.EXECUTED ? "activeHeader" : ""}`}>Executed Orders</div>
              <div className="ordersCount">({orders.executed.length})</div>
            </div>
            {(activeOrdersSection === ORDER_STATUS.EXECUTED) && <>{orders.executed.length > 0 && orders.executed.map(RenderOrder)}</>}

            <div className="ordersTypeHeaderBar">
              <button
                onClick={() => activeOrdersSection !== ORDER_STATUS.CURRENT ?
                  dispatch(setOrdersSection(ORDER_STATUS.CURRENT)) :
                  dispatch(setOrdersSection(''))}
                className={`ordersDropdownArrow ${activeOrdersSection === ORDER_STATUS.CURRENT ? "activeArrow" : ""}`}
              >
                <img src={require("../../ImgAssets/DropdownArrow.svg").default} />
              </button>
              <div className={`ordersTypeHeader ${activeOrdersSection === ORDER_STATUS.CURRENT ? "activeHeader" : ""}`}>Current Orders</div>
              <div className="ordersCount">({orders.current.length})</div>
            </div>
            {(activeOrdersSection === ORDER_STATUS.CURRENT) && <>{orders.current.length > 0 && orders.current.map(RenderOrder)}</>}
            <div className="ordersTypeHeaderBar">
              <button onClick={() => activeOrdersSection !== ORDER_STATUS.PENDING ?
                dispatch(setOrdersSection(ORDER_STATUS.PENDING)) :
                dispatch(setOrdersSection(''))}
                className={`ordersDropdownArrow ${activeOrdersSection === ORDER_STATUS.PENDING ? "activeArrow" : ""}`}>
                <img src={require("../../ImgAssets/DropdownArrow.svg").default} />
              </button>
              <div className={`ordersTypeHeader ${activeOrdersSection === ORDER_STATUS.PENDING ? "activeHeader" : ""}`}>Pending Orders</div>
              <div className="ordersCount">({orders.pending.length})</div>
            </div>
            {(activeOrdersSection === ORDER_STATUS.PENDING) && <>{orders.pending.length > 0 && orders.pending.map(RenderOrder)}</>}
          </div>
        </div>
      )}

      {props.isOrdersBarVisible && !isOrdersAllOrders && (
        <div className={props.isExpandedOrdersBar ? "ordersBar expandedOrdersBar" : "ordersBar"}>
          <div className="ordersGreenHeaderBar singleOrderView">
            <button className="ordersGreenHeader" onClick={() => showAllOrders()}>
              Orders
            </button>
            <img className="dropdownArrowWhiteSpacer" src={require("../../ImgAssets/DropdownArrowWhite.svg").default} />
            <button className="ordersGreenHeader subOrderHeaderClass activeHeader">
              {headerElement.status.state} Orders
            </button>
          </div>

          <div className="ordersTypeHeaderBar" style={{ height: 'auto', padding: '12px 12px 0px' }}>
            <div className="ordersTypeHeaderTwoLineDiv">
              <div className="ordersTypeHeader">{headerElement.spec.name}</div>
              <div className="ordersTypeHeader activeHeader">
                SCHEDULE
              </div>
            </div>
            <button className="orderThreeDotMenu">
              <img onClick={() => props.setIsExpandedOrdersBar(!props.isExpandedOrdersBar)} src={require("../../ImgAssets/ThreeDotIcon.svg").default} />
            </button>
            <div className="orderThreeDotMenu">
              <CircularProgressBarComponent item={headerElement} />
            </div>
          </div>
          <div className="scrollableOrdersBar">
            {!props.isExpandedOrdersBar && (
              <>
                {headerElement.status.schedule.displayAssignments.map((item) => (
                  <RenderSubOrder item={item} />
                ))}
              </>
            )}
            {props.isExpandedOrdersBar && <ExpandedSubOrderTable displayAssingments={headerElement.status.schedule.displayAssignments} />}
          </div>
        </div>
      )}
    </>
  );
};

export default OrdersView;
