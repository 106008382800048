import React from "react";
import _ from "lodash";

const Separator = (separatorProps) => {
  return (
    <div
      style={{
        position: "absolute",
        height: "100%",
        transform: `rotate(${separatorProps.turns}turn)`
      }}
    >
      <div style={separatorProps.style} />
    </div>
  );
}

const RadialSeparators = (separatorProps) => {
  const turns = 1 / separatorProps.count;
  return _.range(separatorProps.count).map(index => (
    <Separator turns={index * turns} style={separatorProps.style} />
  ));
}

export default RadialSeparators;
