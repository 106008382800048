import React from 'react'
import ScenarioTimeline from './ScenarioTimeline';
import ScenarioOrbitNew from './ScenarioOrbitNew.js';

interface VisualizationWrapperProps {
  active_schedule_modal: boolean;
  scenario_start_time: any;
  scenario_duration: any;
  simulation_start_time: any;
  simulation_end_time: any;
  scenario_step?: string;
  open_schedule_modal: () => void;
  schedule_data: any[];
  duration: any;
  set_simulation_time: (data: any) => void;
  all_ground_stations: any[];
  scenarioData: any;
  loading: boolean | string;
}

const VisualizationWrapper: React.FC<VisualizationWrapperProps> = ({
  scenario_step, open_schedule_modal, scenario_duration, scenario_start_time, schedule_data,
  simulation_start_time, simulation_end_time, duration, set_simulation_time,
  all_ground_stations, scenarioData, active_schedule_modal, loading }) => {

  const scenario_scheduled = schedule_data.length

  const OrbitContainer: React.CSSProperties = {
    height: ((loading === 'Generating' || scenario_scheduled) && scenario_step === 'Schedule') ? 'calc(100% - 200px)' : '100%',
    width: '100%',
    display: 'flex',
    position: 'relative',
    transition: 'all 0.3s ease-in-out',
  }

  const TimelineContainer: React.CSSProperties = {
    height: ((loading === 'Generating' || scenario_scheduled) && scenario_step === 'Schedule') ? '200px' : '0px',
    width: '100%',
    display: 'flex',
    transition: 'all 0.3s ease-in-out',
  }

  return (
    <div className='d-flex w-100 h-100 flex-column overflow-hidden'>
      <div style={OrbitContainer}>
        <ScenarioOrbitNew
          all_ground_stations={all_ground_stations} // All gs from mission
          scenarioData={scenarioData}
        />
      </div>
      <div style={TimelineContainer}>
        {!scenario_scheduled &&
          <div className='h-100 w-100 d-flex flex-column gap-3 scenario_timeline_container p-4'>
            <div className='timeline_skeleton h-40' />
            <div className='timeline_skeleton h-150' />
          </div>
        }
        {scenario_scheduled > 0 &&
          <ScenarioTimeline
            active_schedule_modal={active_schedule_modal}
            scenario_duration={scenario_duration}
            scenario_start_time={scenario_start_time}
            simulation_start_time={simulation_start_time}
            simulation_end_time={simulation_end_time}
            open_schedule_modal={open_schedule_modal}
            schedule_data={schedule_data}
            duration={duration}
            set_simulation_time={set_simulation_time}
            disabled={loading}
          />
        }
      </div>
    </div>
  )
}


export default VisualizationWrapper
