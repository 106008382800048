export const MAP_CENTER = [78.6677428, 22.3511148];

export const MAP_DEFAULT_ZOOM = 2.7;
export const MAP_MIN_ZOOM = 2.5;

export const MAP_VIEWS = [
  {
    name: 'Falt Earth View',
    dimension: 'mercator',
  },
  {
    name: '3D Globe View',
    dimension: 'globe',
  },
];

export const MAP_STYLES = [
  {
    name: 'Mapbox Dark',
    style: 'dark-v11',
  },
  {
    name: 'Mapbox Light',
    style: 'light-v11',
  },
  {
    name: 'Mapbox Satellite',
    style: 'satellite-v9'
  },
];

export const GEODATA_OPTIONS = [
  {
    name: 'Orders',
    type: 'orders',
  },
  {
    name: 'Leagacy Data',
    type: 'legacyData',
  },
];

export const ORDER_STATUS = {
  CURRENT: 'current',
  PENDING: 'pending',
  EXECUTED: 'executed',
};

export const ORDER_STATUS_SERVER = {
  CURRENT: 'Current',
  PENDING: 'Pending',
  EXECUTED: 'Executed',
  COMPLETE: 'Complete',
  WAITING: 'Waiting',
  WARNING: 'Warning',
  INPROGRESS: 'InProgress',
  FAILURE: 'Failure',
};

export const orderType = [
  "Geodata Archive",
  "Standard Order"
];

export const priorityLane = [
  "Normal",
  "High",
  "ASAP"
];

export const imagingSpectrum = [
  {
    label: 'MSI',
    value: 'Spectral',
  },
  {
    label: 'HSI',
    value: 'Hyper-S',
  },
  {
    label: 'SAR',
    value: 'SAR',
  },
];

export const resolution = [
  "0.1m - 1m (Very high)",
  "1m - 5m (High)",
  "5m - 25m (Medium)",
  "25m - 100m (Low)",
  "100m - 1km (Coarse)",
];

export const coverageWindowValueType = [
  'days',
  'weeks',
  'months',
];

export const faultToleranceOptions = [
  "ISL",
  "Ground Link"
];

export const recurringType = [
  {
    label: 'Every Day',
    value: 'everyDay'
  },
  {
    label: 'Every Week',
    value: 'everyWeek'
  },
  {
    label: 'Every Month',
    value: 'everyMonth'
  },
];

// export const recurringOptions = [
//     "One time",
//     "More than one-time"
// ];

export const ORDER_DETAILS_KEYS = [
  'platform',
  'eo:cloud_cover',
  'created'
];

export const ORDER_DETAILS_KEYS_LABEL = [
  {
    label: 'Platform',
    value: 'platform',
  },
  {
    label: 'Cloud Cover',
    value: 'eo:cloud_cover',
  },
  {
    label: 'Creation Date',
    value: 'created',
  },
];

export const ASSET_OPTIONS = [
  {
    id: 1,
    label: 'Blue'
  },
  {
    id: 2,
    label: 'Geodata Preview'
  },
  {
    id: 3,
    label: 'Reduced Resolution'
  },
  {
    id: 4,
    label: 'Thumbnail'
  },
];

export const PROVIDER_OPTIONS = [
  'MAXAR',
  'PLANET',
  'ANTARIS',
  'SENTINEL',
  'LANDSAT',
]