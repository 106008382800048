//@ts-nocheck
import { useEffect, useState } from 'react';
import CustomConstellation from './CustomConstellation';
import PolarConstellation from './PolarConstellation';
import SunSynchronusConstellation from './SunSynchronusConstellation';
import WalkerDeltaConstellation from './WalkerDeltaConstellation';
import { useSelector } from 'react-redux';
import ModalDropdown from '../../../commonComponents/ModalDropdown';

const ConstellationConfiguration = ({ orbitalDetails, satConstellationData, setSatConstellationData }) => {

    const [selectedConstellationType, setSelectedConstellationType] = useState<any>({ label: "Single Plane Constellation", value: "Single Plane Constellation" })
    const [isPreviousSelected, setIsPreviousSelected] = useState("Single Plane Constellation")

    // const options = [{ label: "Custom", value: "Custom" }, { label: "Single Plane Constellation", value: "Single Plane Constellation" }, { label: "Walker Delta", value: "Walker Delta" }, { label: "Sun Synchronous", value: "Sun Synchronous" }];
    const options = [{ label: "Single Plane Constellation", value: "Single Plane Constellation" }, { label: "Walker Delta", value: "Walker Delta" }];

    const editConstellationData = useSelector(state => state.missionModelling.editConstellationObject);

    const handleSelectedConstellationType = (e) => {
        setSelectedConstellationType(e)
    }

    const [walkerDeltaConstellation, setWalkerDeltaConstellation] = useState<object>({
        noOfPlanes: '',
        noOfSatellitesPerPlane: '',
        separationAngleBetweenPlane: '',
        interAnglePhasingForWalkerDelta: ''
    })

    const [sunSynchronusConstellation, setSunSynchronusConstellation] = useState<object>({
        noOfSatellites: '',
        orbitPeriod: '',
        altitudeForEachSatellite: ''
    })

    const [polarConstellation, setPolarConstellation] = useState<object>({
        noOfSatellites: '',
        interAnglePhasingForSinglePlane: ''
    })

    const [customConstellation, setCustomConstellation] = useState<object>({
        noOfPlanes: '',
        noOfSatellitesPerPlane: '',
        separationAngleBetweenPlane: '',
        inclinationOfPlane: '',
        RAAN: ''
    })

    useEffect(() => {
        let constellationdata: object = {}
        switch (selectedConstellationType.value) {
            case 'Custom':
                constellationdata = customConstellation
                break;
            case 'Single Plane Constellation':
                constellationdata = polarConstellation
                break;
            case 'Walker Delta':
                constellationdata = walkerDeltaConstellation
                break;
            case 'Sun Synchronous':
                constellationdata = sunSynchronusConstellation
                break;
        }
        setSatConstellationData({
            constellationType: selectedConstellationType.value,
            constellationData: constellationdata
        })

    }, [walkerDeltaConstellation, sunSynchronusConstellation, polarConstellation, customConstellation])

    useEffect(() => {
        if (Object.keys(editConstellationData).length > 0) {
            let groupProperties = editConstellationData?.groups[0]?.groupProperties

            if (editConstellationData.type !== "Single") {
                setIsPreviousSelected(groupProperties?.type)
                setSelectedConstellationType({ label: groupProperties?.type, value: groupProperties?.type })
            }

            switch (groupProperties?.type) {
                case 'Custom':
                    setCustomConstellation(
                        {
                            noOfPlanes: groupProperties?.noOfPlanes,
                            noOfSatellitesPerPlane: groupProperties?.noOfSatellitesPerPlane,
                            separationAngleBetweenPlane: groupProperties?.separationAngleBetweenPlane,
                            inclinationOfPlane: groupProperties?.inclinationOfPlane,
                            RAAN: groupProperties?.RAAN
                        }
                    )
                    break;
                case 'Single Plane Constellation':
                    setPolarConstellation({
                        noOfSatellites: groupProperties?.noOfSatellites,
                        interAnglePhasingForSinglePlane: groupProperties?.interAnglePhasingForSinglePlane,
                    })
                    break;
                case 'Walker Delta':
                    setWalkerDeltaConstellation({
                        noOfPlanes: groupProperties?.noOfPlanes,
                        noOfSatellitesPerPlane: groupProperties?.noOfSatellitesPerPlane,
                        separationAngleBetweenPlane: groupProperties?.separationAngleBetweenPlane,
                        interAnglePhasingForWalkerDelta: groupProperties?.interAnglePhasingForWalkerDelta
                    })
                    break;
                case 'Sun Synchronous':
                    setSunSynchronusConstellation({
                        noOfSatellites: groupProperties?.noOfSatellites,
                        orbitPeriod: groupProperties?.orbitPeriod,
                        altitudeForEachSatellite: groupProperties?.altitudeForEachSatellite
                    })
                    break;
            }
        }
    }, [editConstellationData])

    return (
        <div className='orbital_body'>
            <span className='orbital_body_title'> Constellation Type </span>
            <div style={{ width: "100%" }}>
                <ModalDropdown
                    options={options}
                    isMulti={false}
                    handleSelectedOption={handleSelectedConstellationType}
                    previousOption={isPreviousSelected}
                />
            </div>

            {selectedConstellationType.label === "Custom" && <CustomConstellation customConstellation={customConstellation} setCustomConstellation={setCustomConstellation} />}
            {selectedConstellationType.label === "Single Plane Constellation" && <PolarConstellation polarConstellation={polarConstellation} setPolarConstellation={setPolarConstellation} />}
            {selectedConstellationType.label === "Sun Synchronous" && <SunSynchronusConstellation sunSynchronusConstellation={sunSynchronusConstellation} setSunSynchronusConstellation={setSunSynchronusConstellation} />}
            {selectedConstellationType.label === "Walker Delta" && <WalkerDeltaConstellation walkerDeltaConstellation={walkerDeltaConstellation} setWalkerDeltaConstellation={setWalkerDeltaConstellation} />}
        </div>
    );
};

export default ConstellationConfiguration;
