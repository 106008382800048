//@ts-nocheck
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import './sidebar.css'
import { useEffect, useState } from "react";
import BackToHomePanel from "./BackToHomePanel/BackToHomePanel";
import { Divider, SideBarToggleButton, SideBarToggleButtonActive } from "../../commonMMSvgs/MissionModelingSvgs";
import SatelliteCreation from "./SatelliteCreation/SatelliteCreation";
import GroundStationCreation from "./GroundStationCreation/GroundStationCreation";
import GeoFeatureCreation from "./GeoFeatureCreation/GeoFeatureCreation";
import SatelliteCreationModal from "./SatelliteCreation/SatelliteCreationModal";
import GroundStationCreationModal from "./GroundStationCreation/GroundStationCreationModal";
import DeorbitCoverage from "../report/DeorbitCoverage";
import { useDispatch, useSelector } from "react-redux";
import GroundStationAnalysis from "../bottombar/GroundStationAnalysis/GroundStationAnalysis";
import CoverageAnalysis from "../bottombar/GroundStationAnalysis/CoverageAnalysis";
import * as mmActions from '../../../../store/MissionModellingSlice.js'
import SatelliteConfig from "./SatelliteCreation/SatelliteConfig";

const SideBar = ({ openedCreationModal, setOpenedCreationModal, mm_viewer }) => {

    const dispatch = useDispatch()
    const [isSidebatActive, setIsSidebarActive] = useState<boolean>(true)
    const constellations = useSelector(state => state.missionModelling.constellations)

    const deorbitEclipseReport = useSelector(state => state.missionModelling.deorbitEclipseReport);
    const editConstellationData = useSelector(state => state.missionModelling.editConstellationObject);
    const editGroundStationData = useSelector(state => state.missionModelling.editGroundStationObject);
    const editConstellationConfig = useSelector(state => state.missionModelling.editConstellationConfig);

    const [all_satellites, set_all_satellites] = useState<{
        satellites: [],
        counts: 0
    }>({
        satellites: [],
        counts: 0
    })

    const get_all_satellites = (data: any[]) => {
        const all_satellites: any[] = []
        data.length && data.map(constellation => {
            if (constellation?.['groups']) {
                constellation?.['groups'].map(group => {
                    if (group?.['satellites']) {
                        all_satellites.push(...group?.['satellites'])
                    }
                })
            }
        })
        set_all_satellites({
            satellites: all_satellites,
            counts: all_satellites.length
        })
    }

    const clearEditData = () => {
        dispatch(mmActions.setEditConstellationObject({}))
        dispatch(mmActions.setEditGroundStationObject({}))
        dispatch(mmActions.setEditConstellationConfig({}))
    }

    const closeModalCreation = () => {

        console.log("Inside the Modal Creation")

        setOpenedCreationModal({
            groundStationCreationModal: false,
            satelliteCreationModal: false,
            ConstellationConfig: false
        })
        clearEditData()
    }

    const openSatelliteCreationModal = () => {
        setOpenedCreationModal({
            satelliteCreationModal: true
        })
    }

    const openGroundStationCreationModal = () => {
        setOpenedCreationModal({
            groundStationCreationModal: true
        })
    }

    const openConstellationConfigModal = () => {
        setOpenedCreationModal({
            ConstellationConfig: true
        })
    }

    useEffect(() => {
        if (Object.keys(editConstellationData).length > 0) {
            openSatelliteCreationModal()
        }
        if (Object.keys(editGroundStationData).length > 0) {
            openGroundStationCreationModal()
        }
        if (Object.keys(editConstellationConfig).length > 0) {
            openConstellationConfigModal()
        }
    }, [editConstellationData, editGroundStationData, editConstellationConfig])

    useEffect(() => {
        if (constellations) {
            get_all_satellites(constellations)
        }
    }, [constellations])


    return (
        <div className={`new_mission_sidebar ${isSidebatActive ? 'active' : ''}`}>
            <BackToHomePanel />
            {/* <PanelGroup direction="vertical">
                <Panel maxSize={75}>
                    <SatelliteCreation openSatelliteCreationModal={openSatelliteCreationModal} />
                </Panel>

                <PanelResizeHandle>
                    <div style={{ marginBottom: "-7pt" }}>
                        <Divider />
                    </div>
                </PanelResizeHandle>

                <Panel maxSize={75}>
                    <GeoFeatureCreation />
                </Panel>

                <PanelResizeHandle>
                    <div style={{ marginBottom: "-7pt" }}>
                        <Divider />
                    </div>
                </PanelResizeHandle>

                <Panel maxSize={75}>
                    <GroundStationCreation openGroundStationCreationModal={openGroundStationCreationModal} />
                </Panel>
            </PanelGroup> */}

            <div style={{ width: '100%', height: '95%' }}>
                <div className="sideBarSubSection">
                    <SatelliteCreation all_satellites={all_satellites} openSatelliteCreationModal={openSatelliteCreationModal} openedCreationModal={openedCreationModal} mm_viewer={mm_viewer} />
                </div>
                <div className="sideBarSubSection">
                    <GeoFeatureCreation mm_viewer={mm_viewer} openedCreationModal={openedCreationModal} />
                </div>
                <div className="sideBarSubSection">
                    <GroundStationCreation openGroundStationCreationModal={openGroundStationCreationModal} openedCreationModal={openedCreationModal} mm_viewer={mm_viewer} />
                </div>
            </div>


            <div className="new_mission_sidebar_button" onClick={() => { setIsSidebarActive(!isSidebatActive) }}>
                {isSidebatActive ? <SideBarToggleButtonActive /> : <SideBarToggleButton />}
            </div>

            {/* Creation Modals */}
            {openedCreationModal.satelliteCreationModal && <div className="creation_modal">
                <SatelliteCreationModal all_satellites={all_satellites} closeModalCreation={closeModalCreation} />
            </div>}

            {openedCreationModal.groundStationCreationModal && <div className="creation_modal">
                <GroundStationCreationModal closeModalCreation={closeModalCreation} mm_viewer={mm_viewer} />
            </div>}

            {openedCreationModal.groundStationanlysisModal && <div className="creation_modal">
                <GroundStationAnalysis closeModalCreation={closeModalCreation} mm_viewer={mm_viewer} />
            </div>}

            {openedCreationModal.coverageAnalysisModal && <div className="creation_modal">
                <CoverageAnalysis closeModalCreation={closeModalCreation} mm_viewer={mm_viewer} />
            </div>}

            {openedCreationModal.ConstellationConfig && <div className="creation_modal">
                <SatelliteConfig closeModalCreation={closeModalCreation} />
            </div>}

            {Object.keys(deorbitEclipseReport).length > 0 && <DeorbitCoverage />}
        </div>
    );
};

export default SideBar;
