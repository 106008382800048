// @ts-nocheck
import { useState } from 'react';
import { Modal } from 'reactstrap';

const Dialog = (props: { onClick: any, openModel: any, comment: string }) => {

    const [hover, setHover] = useState('Yes');

    return <Modal
        isOpen={props.openModel}>
        <div className="text-end p-2">
            <i className='fe fe-x  fs-18'
                style={{ cursor: "pointer" }}
                onClick={() => {
                    props.onClick(false);
                }}></i>
        </div>
        <div className="modal-header d-flex align-items-center text-center justify-content-center">
            <h5 className='pb-0 mb-0'>{props.comment}</h5>
        </div>
        <div className="modal-footer d-flex align-items-center justify-content-center gap-3">
            <button
                type="button"
                onClick={() => {
                    props.onClick(false);
                }}
                onMouseEnter={() => {
                    setHover('No');
                }}
                onMouseLeave={() => {
                    setHover('Yes');
                }}
                className={hover === 'No' ? "btn btn-outline-danger px-6" : "btn btn-outline-danger px-6"}
                data-dismiss="modal"
                style={{ borderRadius: '8px' }}
            >
                No
            </button>
            <button type="button" className={hover !== 'Yes' ? "btn btn-primary btn btn-outline-primary px-6" : " btn btn-primary btn btn-outline-primary px-6"}
                style={{ borderRadius: '8px', color: '#fff' }}
                onClick={() => {
                    props.onClick(true);
                }}>
                Yes
            </button>


        </div>
    </Modal>
};
export default Dialog;
