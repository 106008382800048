// @ts-nocheck
import { LinearProgress } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Lottie from 'react-lottie-player';
import * as animationData from './transformation.json'
import * as animationDataSuccess from './Success.json'
import * as animationDataError from './Failure.json'
import Dialog from '../../../CommonComponents/Dialog';
import CommandCenterService from '../../../../service/CommandCenterService';
import MissionService from '../../../../service/MissionService';
import { navigateTo } from '../../../CommonComponents/CommonFunctions';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const style = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '40px',
  width: '100%',
  padding: '10px 16px',
  borderRadius: '4px',
  border: '1px solid #2465B2',
  background: 'rgba(36, 101, 178, 0.10)',
  gap: '4px'
}

interface LoaderAnimationProps {
  objectName?: string;
  state: string;
  category?: string;
  close?: () => void;
  goto?: () => void;
  truetwin_id?: string;
  mission_name?: string;
  mission_id?: string;
  sat_name?: string;
  sat_id?: string;
  dis_connect_ground_station?: () => void;
  show_worning?: boolean
}

const LoaderAnimation: React.FC<LoaderAnimationProps> = ({
  objectName,
  close,
  state,
  category,
  goto,
  truetwin_id,
  mission_name,
  mission_id,
  sat_name,
  sat_id,
  dis_connect_ground_station,
  show_worning = false
}) => {

  const navigate = useNavigate()
  const [TrueTwin, setTrueTwin] = useState<any>({});
  const [loadingState, setLoadingState] = useState<any>(state);
  const [showDialouge, setShowDialouge] = useState(false);
  const [remoteAppStatus, setRemoteAppStatus] = useState(0)
  const [navigatig, setNavigating] = useState(false)

  const navigate_to_tt_dashboard = () => {
    navigateTo(
      `${mission_name}/${mission_id}/${sat_name}/${sat_id}/${TrueTwin?.["Name"]}/${TrueTwin?.["trueTwinId"]}/${TrueTwin?.['deployInfo']?.['type'] === 'Quick Deploy' ? 'quick-deploy' : 'simulation'}/${TrueTwin?.['deployInfo']?.['scenarioName']}/${TrueTwin?.['deployInfo']?.['scenarioId']}/hud`,
      navigate
    );
  };
  const navigate_to_tt_details = () => {
    let scenario_flow = window.location.pathname.includes('create_scenario') || window.location.pathname.includes('update_scenario') ? true : false
    navigateTo(
      `${mission_name}/${mission_id}/${sat_name}/${sat_id}/truetwin_details/${TrueTwin?.["Name"]}/${TrueTwin?.["trueTwinId"]}/${scenario_flow ? 'Scenarios' : 'Configuration'}`,
      navigate
    );
  };

  const get_truetwin_by_id = () => {
    CommandCenterService.getTrueTwinStatus(truetwin_id)
      ?.then((res) => {
        if (res?.data) {
          setTrueTwin(res.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (truetwin_id && truetwin_id !== undefined && (state?.['deployed'] || state?.['created'])) {
      get_truetwin_by_id();
    }
  }, [truetwin_id, state])

  useEffect(() => {
    if (state) {
      setLoadingState(state);
    }
  }, [state])

  const upload_generated_schedule = () => {
    CommandCenterService.uploadScenarioSchedule(TrueTwin?.['deployInfo']?.["scenarioId"], TrueTwin?.["trueTwinId"])?.then(res => {
      if (res.data) {
        setLoadingState({
          'deployed': `FlatSat ${TrueTwin?.['Name']?.length > 15 ? TrueTwin?.['Name']?.substring(0, 15) + "..." : TrueTwin?.['Name']} deployed successfully.`,
          'connected': 'Successfully connected to FlatSat'
        })
      }
    }).catch(err => {
      setLoadingState({
        'deployed': `FlatSat ${TrueTwin?.['Name']?.length > 15 ? TrueTwin?.['Name']?.substring(0, 15) + "..." : TrueTwin?.['Name']} deployed successfully.`,
        'connected': 'Successfully connected to FlatSat'
      })
      toast.error('Something went wrong while uploading schedule data.');
    })
  }

  const handleConnect = () => {
    setLoadingState({ 'connecting': `Connecting to FlatSat proxy...` })
    CommandCenterService.connectGroundStation(
      TrueTwin?.["groundStationId"],
      TrueTwin?.["trueTwinId"],
      TrueTwin?.['deployInfo']?.["scenarioId"]
    )
      ?.then((res) => {
        if (res.data) {
          if (TrueTwin?.['deployInfo']?.['type'] !== "Quick Deploy") {
            upload_generated_schedule();
          } else {
            setLoadingState({
              'deployed': `FlatSat ${TrueTwin?.['Name']?.length > 15 ? TrueTwin?.['Name']?.substring(0, 15) + " ..." : TrueTwin?.['Name']} deployed successfully.`,
              'connected': 'Successfully connected to FlatSat'
            })
          }
        }
      }).catch((err) => {
        setLoadingState({ 'error': `An error occurred while connecting to FlatSat.` })
      });
  }


  const getTrueTwinRemoteAppStatus = () => {
    let payload = TrueTwin?.["remoteApp"]?.["AppData"].find(
      (pay) => pay?.["isConfigured"] === "Yes"
    );

    let Data = {
      commandName: 'Get Status',
      commandType: "Payload",
      sequenceData: {
        commandId: 604,
        commandData: {
          Num: payload?.["appId"],
        },
        commandHeader: {
          SaId: 1,
          DaId: 134,
          Qos: 3,
        },
      }
    };

    CommandCenterService.runSequence(TrueTwin?.["groundStationId"], "JSON", TrueTwin?.["trueTwinId"], TrueTwin?.['deployInfo']?.["scenarioId"], "", "simulate", Data)?.then((response) => {
      if (response && response?.['data'] && response?.['data']?.['responseData']?.['Application_state'] === 3) {
        setRemoteAppStatus(response.data.responseData.Application_state)
      }
    })
      .catch((err) => {
        console.error(err);
      });
  };


  const run_simulation = ({ navigate }) => {
    setNavigating(true)
    let body = {
      "simulationName": "Simultion " + TrueTwin?.['deployInfo']?.['scenarioName'],
      "scenarioId": TrueTwin?.['deployInfo']?.['scenarioId'],
      "trueTwinId": truetwin_id,
      "missionId": mission_id,
      "satId": sat_id,
    }
    MissionService.runSimulation(body)?.then(res => {
      if (res.data) {
        if (navigate) {
          navigate_to_tt_dashboard()
          close && close()
        } else {
          setLoadingState({
            'deployed': `TrueTwin ${TrueTwin?.['Name']?.length > 15 ? TrueTwin?.['Name']?.substring(0, 15) + " ..." : TrueTwin?.['Name']} deployed successfully.`,
            'connected': 'TrueTwin connected successfully to remote application.'
          })
        }
      }
    }).catch(err => {
      dis_connect_ground_station && dis_connect_ground_station()
    })
  }

  useEffect(() => {
    if (remoteAppStatus === 3) {
      run_simulation({ navigate: false })
    }
  }, [remoteAppStatus])

  useEffect(() => {
    if (TrueTwin?.["isDeployed"] === "Yes" && TrueTwin?.['Category'] === "TrueTwin" && TrueTwin?.['isRemoteAppConfigured'] === 'Yes') {
      const stopTrueTwinStatus = setInterval(getTrueTwinRemoteAppStatus, 3000)
      if (remoteAppStatus === 3) {
        clearInterval(stopTrueTwinStatus);
      }
      return () => clearInterval(stopTrueTwinStatus);
    }
  }, [TrueTwin, remoteAppStatus])

  const downloadPayloadConfig = (trueTwinId, fileId, fileName) => {
    MissionService.downloadTrueTwinPayloadConfig(trueTwinId, fileId)
      ?.then((res) => {
        if (res && res.data) {
          let link = document.createElement("a");
          const mimeType = "application/zip";
          link.href = `data:${mimeType};base64,${res.data.Data}`;
          link.download = fileName;
          link.click();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const download_file = () => {
    if (TrueTwin?.['isRemoteAppConfigured'] === 'Yes') {
      const file_data = TrueTwin?.['remoteApp']?.['AppData']?.find((pay) => pay?.['isConfigured'] === 'Yes');
      downloadPayloadConfig(TrueTwin?.['trueTwinId'], file_data?.['fileId'], file_data?.['fileName']);
    }
  }

  return (
    <Fragment>
      {!showDialouge &&
        <div className='loaderAnimation gap-5'>
          <div className='centerLoader'>
            <div className='innerLoader'>
              {(loadingState?.['creating'] || loadingState?.['deploying'] || loadingState?.['retiring'] || loadingState?.['connecting']) &&
                <Lottie
                  loop
                  animationData={animationData}
                  play
                  style={{ width: 250, height: 250 }}
                />
              }
              {((loadingState?.['created'] || loadingState?.['connected'] || loadingState?.['deployed'] && !loadingState?.['connecting'])) &&
                <Lottie
                  loop
                  animationData={animationDataSuccess}
                  play
                  style={{ width: 250, height: 250 }}
                />
              }
              {loadingState?.['error'] &&
                <Lottie
                  loop
                  animationData={animationDataError}
                  play
                  style={{ width: 250, height: 250 }}
                />
              }
            </div>
            <div className='d-flex flex-column gap-3 w-100 text-center align-items-center justify-content-center'>
              <div className='d-flex flex-column gap-2'>
                {Object.keys(loadingState).length > 0 && (
                  Object.keys(loadingState).map(item => (
                    <span key={item} className={`fs-20 ${loadingState?.['error'] ? 'color-danger' : (item === 'created' || item === 'deployed') ? 'color-atmos' : 'color-white'}`}>
                      {loadingState?.[item]}
                    </span>
                  ))
                )}
              </div>

              <div className='d-flex w-100 align-items-center justify-content-center'>
                {((loadingState?.['created'] || loadingState?.['connected'] || loadingState?.['deployed']) && !loadingState?.['connecting']) && <div className='w-500px bg-loader h-4px rounded-2' />}
                {loadingState?.['error'] && <div className='w-500px h-4px bg-red rounded-2' />}
                {(loadingState?.['creating'] || loadingState?.['deploying'] || loadingState?.['retiring'] || loadingState?.['connecting']) && <LinearProgress className='h-4px w-500px rounded-2' />}
              </div>

              {category === 'TrueTwin' && TrueTwin?.['isRemoteAppConfigured'] === 'Yes' && loadingState?.['connecting'] &&
                <div className='w-100 d-flex justify-content-center'>
                  <button onClick={download_file} className='d-flex gap-1 align-items-center btn btn-outline-dark'>
                    <i className='fe fe-download'></i>
                    Download Remote Config
                  </button>
                </div>
              }
            </div>

            {show_worning &&
              <div className='w-100 px-5 d-flex align-items-center justify-content-center mt-6'>
                <div style={style}>
                  <i className='fe fe-info fs-16' style={{ color: '#2465B2' }} />
                  {`Note: Do not disconnect remote application while truetwin is retiring`}
                </div>
              </div>
            }
          </div>


          <div className="navigator-btn gap-3">
            {(category === 'TrueTwin' && TrueTwin?.['isRemoteAppConfigured'] === 'Yes' && loadingState?.['connecting']) &&
              <Button
                variant='btn-outline'
                disabled={navigatig}
                className="d-flex align-items-center btn btn-outline-danger pt-1 pb-1 pe-5 ps-4 fs-14 bg-red-700"
                onClick={() => {
                  run_simulation({ navigate: true })
                }}
              >
                <i className={`fe fe-x me-1 fs-16 ${navigatig ? 'opacity-50 color-danger' : 'opacity-100'}`}></i>
                Cancel
              </Button>
            }
            {((loadingState?.['created'] || loadingState?.['connected'] || loadingState?.['deployed'] || loadingState?.['error']) && (!loadingState?.['connecting'] || !loadingState?.['error']) && !loadingState?.['connecting']) &&
              <Button
                variant='btn-outline'
                className="d-flex align-items-center btn btn-outline-danger pt-1 pb-1 pe-5 ps-4 fs-14 bg-red-700"
                onClick={() => {
                  close && close()
                }}
              >
                <i className={`fe fe-x me-1 fs-16`}></i>
                Close
              </Button>
            }
            {((loadingState?.['created'] || (loadingState?.['connected'] && TrueTwin?.['isRemoteAppConfigured'] === 'Yes') || (loadingState?.['deployed'] && TrueTwin?.['isRemoteAppConfigured'] === 'No')) && category === 'TrueTwin') &&
              <Button
                variant='btn-primary'
                className="d-flex align-items-center btn btn-primary pt-1 pb-1 pe-5 ps-4 fs-14 bg-red-700"
                onClick={() => {
                  if (TrueTwin?.['isDeployed'] === 'No') {
                    navigate_to_tt_details()
                    close && close()
                  } else {
                    navigate_to_tt_dashboard()
                    close && close()
                  }
                }}
              >
                Done
                <i className="fe fe-chevron-right me-1 fs-16"></i>
              </Button>
            }
            {((loadingState?.['created'] || loadingState?.['connected'] || loadingState?.['deployed']) && category === 'FlatSat') &&
              <Button
                disabled={navigatig}
                variant='btn-primary'
                className="d-flex align-items-center btn btn-primary pt-1 pb-1 pe-5 ps-4 fs-14 bg-red-700"
                onClick={() => {
                  if (loadingState?.['created'] && TrueTwin?.['isDeployed'] === 'No') {
                    navigate_to_tt_details()
                    close && close()
                  }
                  else if (TrueTwin?.['isDeployed'] === 'Yes' && loadingState?.['connected']) {
                    navigate_to_tt_dashboard()
                    close && close()
                  } else {
                    setShowDialouge(true)
                  }
                }}
              >
                {(loadingState?.['created'] || loadingState?.['connected']) ? 'Done' : 'Connect To FlatSat'}
                {(loadingState?.['created'] || loadingState?.['connected']) && <i className="fe fe-chevron-right me-1 fs-16"></i>}
              </Button>
            }
          </div>

        </div>
      }
      <Dialog
        onClick={(event) => {
          if (event) {
            handleConnect()
          }
          setShowDialouge(false)
        }}
        openModel={showDialouge} comment={`Please ensure proxy is running on your setup and connected to ACP, Port: ${TrueTwin?.['satellitePort']}`}
      />
    </Fragment>
  )
}

export default LoaderAnimation