import Lottie from "react-lottie-player"
import * as animationData from './transformation.json'
import './FileUploadLoader.css'
import { LinearProgress } from "@mui/material"
interface FileUploadLoaderProps {
    loader_type: string
}
const FileUploadLoader: React.FC<FileUploadLoaderProps> = ({ loader_type }) => {
    return (
        <div className='loader-page'>
            {loader_type === 'file_upload' &&
                <div className="d-flex flex-column gap-4 align-items-center justify-content-center">
                    <Lottie
                        loop
                        animationData={animationData}
                        play
                        style={{ width: 200, height: 200 }}
                    />
                    <span className="fs-16">Uploading 3D Model</span>
                    <LinearProgress className='h-4px w-500px rounded-2' />
                </div>
            }
            {loader_type === 'file_download' &&
                <div className='fetching_loader' />
            }
        </div>
    )
}
export default FileUploadLoader