import { useContext } from 'react';
import { ViewerContext } from '../NewScenarioFlow';

const useScenarioViewer = () => {
    const context = useContext(ViewerContext);
    if (context === undefined) {
        throw new Error('useScenarioViewer must be used within a ViewerProvider');
    }
    return context
};

export default useScenarioViewer;