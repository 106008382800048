import { formatDate } from "./Utils/HelperFunctions";

// @ts-nocheck
const HoverBoxNotification = ({ headerElement, hoverBoxNotificationVisibility, type }) => {
  return (
    <div className="hoverBoxNotification" style={hoverBoxNotificationVisibility}>
      <div className="hoverBoxNotificationHeaderDiv">
        <div className="hoverBoxNotificationHeaderText">
          {type === "Recurring" && <>Recurring Order</>}
          {type === "Executed" && <div style={{ color: "#CCF54E" }}>Executed Order</div>}
          {type === "Partially Completed" && <div style={{ color: "#F2A93B" }}>Partially Executed</div>}
          {type === "Cancelled" && <div style={{ color: "#F0F0F0" }}>Cancelled Order</div>}
          {type === "Failed" && <div style={{ color: "#FF3333" }}>Failed Order</div>}
          {type === "Current" && <div style={{ color: "#F0F0F0" }}>Current Order</div>}
          {type === "Delay" && <div style={{ color: "#A0BFFF" }}>On Hold Order</div>}
          {type === "Pending" && <div style={{ color: "#F0F0F0" }}>Pending Order</div>}
        </div>
        <div className="hoverBoxNotificationHeaderNum">
          {type === "Recurring" && (
            <>
              {headerElement.recurringCount}&nbsp;/&nbsp;{headerElement.recurringLimit}
            </>
          )}
          {type != "Recurring" && <>{headerElement.status.progress}%</>}
        </div>
      </div>
      {type === "Recurring" && (
        <div className="hoverBoxNotificationBodyDiv">This order was scheduled to reoccur {headerElement.recurringLimit} times as per its recurring policy.</div>
      )}
      {type === "Executed" && (
        <>
          <div className="hoverBoxNotificationBodyDivHeader">Success</div>
          <div className="hoverBoxNotificationBodyDiv">Order was executed in the given timeline. The time of execution was {headerElement.endDate}.</div>
        </>
      )}
      {type === "Partially Completed" && (
        <>
          <div className="hoverBoxNotificationBodyDivHeader">{100 - headerElement.status.progress}% Task Failed</div>
          <div className="hoverBoxNotificationBodyDiv">Couldn't reschedule the unexecuted task in the given timeline for this recurring order.</div>
        </>
      )}
      {type === "Cancelled" && (
        <>
          <div className="hoverBoxNotificationBodyDivHeader">User Action</div>
          <div className="hoverBoxNotificationBodyDiv">This occurrence was {headerElement.status.progress}% executed. The entire order got cancelled manually by user.</div>
        </>
      )}
      {type === "Failed" && (
        <>
          <div className="hoverBoxNotificationBodyDivHeader">Failed to complete on time</div>
          <div className="hoverBoxNotificationBodyDiv">{100 - headerElement.status.progress}% of the tasks failed and could not be scheduled within the given timeline.</div>
        </>
      )}
      {type === "Current" && (
        <>
          <div className="hoverBoxNotificationBodyDivHeader">{headerElement.status.progress}% executed</div>
          <div className="hoverBoxNotificationBodyDiv">This is an current order and will complete in the given timeline.</div>
        </>
      )}
      {type === "Delay" && (
        <>
          <div className="hoverBoxNotificationBodyDivHeader">High Priority Order Prioritized</div>
          <div className="hoverBoxNotificationBodyDiv">The order will restart and complete in the given timeline.</div>
        </>
      )}
      {type === "Pending" && (
        <>
          <div className="hoverBoxNotificationBodyDivHeader">Confirmation pending</div>
          <div className="hoverBoxNotificationBodyDiv">The order has not yet been confirmed for execution.</div>
        </>
      )}
      {type != "Recurring" && (
        <div className="orderDescriptionBoxDatesDiv">
          <div className="orderDescriptionBoxDates">
            <div className="orderDescriptionBoxDatesTitle">{formatDate(headerElement.createdAt)}</div>
            <div className="orderDescriptionBoxDatesSubTitle">Start Date</div>
          </div>
          <div className="orderDescriptionBoxDates">
            <div className="orderDescriptionBoxDatesTitle">{formatDate(headerElement.updatedAt)}</div>
            <div className="orderDescriptionBoxDatesSubTitle">End Date</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HoverBoxNotification;
