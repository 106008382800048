//@ts-nocheck
import { useCallback, useEffect, useState } from "react";
import * as Cesium from "cesium";
import { gs_visualization_presentation } from "../components/Sidebar/GroundStationCreation/Utils";
import { useDispatch, useSelector } from "react-redux";
import * as mmActions from '../../../store/MissionModellingSlice.js'

const VisualisationMM = ({ setMMViewer, mm_viewer, openedCreationModal }) => {
    const [selectedSatellite, setSelectedSatellite] = useState(null)
    const dispatch = useDispatch()
    const aois = useSelector(state => state.missionModelling.aois);
    const allGroundStationList = useSelector(state => state.missionModelling.allGroundStationList)
    const constellations = useSelector(state => state.missionModelling.constellations)

    const selectedGroundStationForCoverage = useSelector(state => state.missionModelling.selectedGroundStationForCoverage)
    const selectedGeoFeaturesForCoverage = useSelector(state => state.missionModelling.selectedGeoFeaturesForCoverage)
    let imaginary = new Cesium.MapboxStyleImageryProvider({
        styleId: 'dark-v10',
        accessToken: process.env.REACT_APP_MAPBOX_TOKEN!
    });
    const get_constellation_satellites = (data: any[]) => {
        const all_satellites: any[] = []
        data.map(constellation => {
            if (constellation?.['groups']) {
                constellation?.['groups'].map(group => {
                    if (group?.['satellites']) {
                        all_satellites.push(...group?.['satellites'])
                    }
                })
            }
        })
        return all_satellites
    }
    const getSatDetails = ((satId) => {
        const all_satellites = get_constellation_satellites(constellations)
        if (all_satellites?.length) {
            const sat = all_satellites.find(sat => sat?.['id'] === satId)
            return sat
        }
    })

    useEffect(() => {
        const viewer = new Cesium.Viewer("cesiumContainer", {
            requestRenderMode: true,
            shouldAnimate: true,
            animation: false, // Disable the default animation widget
            baseLayerPicker: false, // Disable the default base layer picker
            timeline: false, // Disable the timeline
            geocoder: false, // Disable the geocoder
            homeButton: false, // Disable the home button
            navigationHelpButton: false, // Disable the navigation help button
            sceneModePicker: false, // Disable the scene mode picker
            selectionIndicator: false, // Disable the selection indicator
            fullscreenButton: false,
            imageryProvider: imaginary,
        });

        viewer.scene.globe.baseColor = Cesium.Color.WHITE; // Set a base color for the globe
        viewer.scene.globe.enableLighting = false; // Enable lighting to ensure consistent shading
        viewer.scene.globe.showGroundAtmosphere = false; // Show the atmospheric effects
        viewer.scene.gamma = 2.0; // Adjust the value based on your observation
        viewer.resolutionScale = 1;
        viewer.scene.morphTo2D(0)

        viewer.camera.setView({
            destination: Cesium.Cartesian3.fromDegrees(0, 0, 34000000)
        });

        const dataSource = new Cesium.CzmlDataSource();
        viewer.dataSources.add(dataSource.load([{
            "clock": {
                "multiplier": 1,
                "range": "CLAMPED",
                "step": "SYSTEM_CLOCK_MULTIPLIER"
            },
            "id": "document",
            "name": "MM_Object",
            "version": "1.0"
        }]));
        setMMViewer(viewer)
        // return () => {
        //     viewer.destroy();
        // };
    }, []);

    const addGeoFeatureForCoverage = (aoiId) => {
        if (openedCreationModal.coverageAnalysisModal) {
            const aoi = aois.find(aoi => aoi?.['areaOfInterestId'] === aoiId)
            if (aoi) {
                const selected_all_aoi = get_selected_aoi_list([...selectedGeoFeaturesForCoverage, aoi])
                gs_visualization_presentation().selected_aois(selected_all_aoi, mm_viewer);
                dispatch(mmActions.setSelectedGeoFeatureForCoverage(aoi))
            }
        }
    }
    const addGroundStationForCoverage = (gsid) => {
        if (openedCreationModal.groundStationanlysisModal) {
            const groundStation = allGroundStationList.find(gs => gs?.['groundStationId'] === gsid)
            if (groundStation) {
                const selected_all_gs = get_selected_gs_list([...selectedGroundStationForCoverage, groundStation])
                gs_visualization_presentation().selected_gs(selected_all_gs, mm_viewer);
                dispatch(mmActions.setSelectedGroundStationForCoverage(groundStation))
            }
        }
    }

    const addConstellationForCoverage = (constellationId) => {
        console.log("Constellation id -> ", constellationId)
    }

    const get_selected_gs_list = (groundstations) => {
        const list = groundstations.map(gs => gs?.['groundStationId'])
        return list
    }

    const get_selected_aoi_list = (aois) => {
        const list = aois.map(aoi => aoi?.['areaOfInterestId'])
        return list
    }
    const addGroundTargetForCoverage = (aoiId) => {
        if (openedCreationModal.coverageAnalysisModal) {
            const aoi = aois.find(aoi => aoi?.['areaOfInterestId'] === aoiId)
            if (aoi) {
                const selected_all_aoi = get_selected_aoi_list([...selectedGeoFeaturesForCoverage, aoi])
                gs_visualization_presentation().selected_target(selected_all_aoi, mm_viewer);
                dispatch(mmActions.setSelectedGeoFeatureForCoverage(aoi))
            }
        }
    }
    useEffect(() => {
        if (selectedSatellite) {
            const satellite = getSatDetails(selectedSatellite)
            gs_visualization_presentation().selected_satellite(satellite, mm_viewer);
        }
    }, [selectedSatellite, constellations])
    useEffect(() => {
        if (mm_viewer) {
            const handler = new Cesium.ScreenSpaceEventHandler(mm_viewer.scene.canvas);
            // this handler to enable the click event to add the entities on coverage modal by clicking from the viewer
            handler.setInputAction((movement) => {
                // Create a Cartesian2 object using the x and y coordinates
                const windowPosition = new Cesium.Cartesian2(movement.x, movement.y);
                // Use the Cartesian2 object to pick the object in the scene
                const pickedObject = mm_viewer.scene.pick(movement.position);
                if (Cesium.defined(pickedObject) && Cesium.defined(pickedObject.id)) {
                    document.body.style.cursor = 'pointer';
                    const entity = pickedObject.id;
                    const entityId = entity?._id || '';
                    const description = (entity && entity.description) && entity.description.getValue(Cesium.JulianDate.now());
                    if (description === 'aoi') {
                        addGeoFeatureForCoverage(entityId)
                    } else if (description === 'orbit') {
                        addConstellationForCoverage(entityId)
                    } else if (description === 'spot target') {
                        addGroundTargetForCoverage(entityId)
                    }
                    else {
                        addGroundStationForCoverage(entityId);
                    }
                } else {
                    document.body.style.cursor = 'auto';
                    // gs_visualization_presentation().selected_target([''], mm_viewer)
                }

            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
            // this handler to enable the hover event to highlight the entity on the viewer
            handler.setInputAction((movement) => {
                const windowPosition = new Cesium.Cartesian2(movement.endPosition.x, movement.endPosition.y);
                const pickedObject = mm_viewer.scene.pick(windowPosition);
                if (Cesium.defined(pickedObject) && Cesium.defined(pickedObject.id)) {
                    document.body.style.cursor = 'pointer';
                    let entity = pickedObject.id;
                    const entityId = entity?._id || '';
                    const description = (entity && entity.description) && entity.description.getValue(Cesium.JulianDate.now());
                    if (description === 'aoi') {
                        const all_selected_aoi_ids = get_selected_aoi_list(selectedGeoFeaturesForCoverage)
                        const selected_all_aoi = all_selected_aoi_ids?.length > 0 ? [...all_selected_aoi_ids, entityId] : [entityId]
                        gs_visualization_presentation().selected_aois(selected_all_aoi, mm_viewer)
                    } else if (description === 'orbit') {
                        setSelectedSatellite(entityId)
                    }
                    // else if (description === 'spot target') {
                    //     const all_selected_aoi_ids = get_selected_aoi_list(selectedGeoFeaturesForCoverage)
                    //     const selected_all_aoi = all_selected_aoi_ids?.length > 0 ? [...all_selected_aoi_ids, entityId] : [entityId]
                    //     gs_visualization_presentation().selected_target(selected_all_aoi, mm_viewer)
                    // } 
                    else {
                        const all_selected_gs_ids = get_selected_gs_list(selectedGroundStationForCoverage)
                        const selected_all_gs = all_selected_gs_ids?.length > 0 ? [...all_selected_gs_ids, entityId] : [entityId]
                        gs_visualization_presentation().selected_gs(selected_all_gs, mm_viewer);
                    }
                } else {
                    const all_selected_gs_ids = get_selected_gs_list(selectedGroundStationForCoverage)
                    const selected_all_gs = all_selected_gs_ids?.length > 0 ? [...all_selected_gs_ids, ''] : ['']

                    gs_visualization_presentation().selected_gs(selected_all_gs, mm_viewer);
                    setSelectedSatellite(null)
                    gs_visualization_presentation().selected_satellite({ 'satelliteId': '' }, mm_viewer);
                    document.body.style.cursor = 'auto';

                    // const all_selected_aoi_ids = get_selected_aoi_list(selectedGeoFeaturesForCoverage)
                    // const selected_all_aoi = all_selected_aoi_ids?.length > 0 ? [...all_selected_aoi_ids, ''] : ['']
                    // gs_visualization_presentation().selected_aois(selected_all_aoi, mm_viewer)

                    // gs_visualization_presentation().selected_target(selected_all_aoi, mm_viewer)
                }
            }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
        }
    }, [mm_viewer, selectedGroundStationForCoverage, openedCreationModal, selectedGeoFeaturesForCoverage])

    useEffect(() => {
        if (openedCreationModal.groundStationanlysisModal && selectedGroundStationForCoverage?.length > 0) {
            const selected_all_gs = get_selected_gs_list(selectedGroundStationForCoverage)
            gs_visualization_presentation().selected_gs(selected_all_gs, mm_viewer);
        }
    }, [selectedGroundStationForCoverage, openedCreationModal])



    return (
        <>
            <div id="cesiumContainer" className="position-absolute h-100 w-100" />
        </>
    );
};

export default VisualisationMM;
