import React, { FC, Fragment, useEffect, useState } from 'react'
import GsTypeModal from './Modals/GsTypeModal';
import {
    ButtonGroup,
    Dropdown,
} from "react-bootstrap";
import GsMapVisualization from './Modals/GsVisualization/GsMapVisualization';
import GroundStationForm from '../GroundStations/GroundStationForm';
import GroundStationService from '../../../../service/GroundStationService';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import MissionService from '../../../../service/MissionService';
import CustomToolTip from '../../../CommonComponents/CustomToolTip';

interface ProviderStationsProps {
    provider_details: any;
    all_providers: any[];
    provider_stations: any[];
    all_ground_stations: any[];
    set_loading: (data) => void;
    get_all_ground_stations: () => void;
    get_provider_details: () => void;
}

const ProviderStations: FC<ProviderStationsProps> = ({ provider_details, all_providers, get_provider_details, provider_stations,
    all_ground_stations, get_all_ground_stations, set_loading }) => {

    const params = useParams()
    const { mission_id } = params
    const [show_btn_group, set_show_btn_group] = useState<any>('')

    const [gsTypeSelectModal, setGSTypeSelectModal] = useState<any>(false)
    const [preferanceType, setPreferanceType] = useState<any>('')

    const [expanded, setExpanded] = useState<any>('');

    const update_mission_ground_stations = (providers) => {
        let updated_mission_ground_stations: any = []
        providers?.map((provider: any) => {
            if (provider?.groundStations?.length > 0) {
                updated_mission_ground_stations = updated_mission_ground_stations?.length > 0 ? [...updated_mission_ground_stations, ...provider?.groundStations] : [...provider?.groundStations]
            }
        })
        MissionService.updateMissionStations(mission_id, updated_mission_ground_stations)?.then((res) => {
            if (res?.data) {
                toast.success('Provider updated successfully.', { toastId: 'update' })
                set_loading(false)
            }
        }).catch((err) => {
            console.error(err);
            toast.error('Failed to update station.')
        })
    }

    const get_provider_mqttConfiguredBands = (provider_stations: any) => {
        let provider_mqttConfiguredBands: string[] = []
        provider_stations?.map((station: any) => {
            if (station?.['properties']?.['freqbands']?.length > 0) {
                station?.['properties']?.['freqbands'].map((band: any) => {
                    if (!provider_mqttConfiguredBands?.includes(band)) {
                        provider_mqttConfiguredBands.push(band)
                    }
                })
            }

        })
        return provider_mqttConfiguredBands
    }
    const update_provider_details = (provider_stations) => {
        const provider_mqttConfiguredBands: string[] = get_provider_mqttConfiguredBands(provider_stations)
        const need_mqtt_configured = provider_mqttConfiguredBands?.length !== provider_details?.['mqttConfiguredBands']?.length
        let not_configured_provider_stations: any = provider_stations?.filter(station => provider_details?.['groundStations'] && provider_details?.['groundStations']?.includes(station?.['groundStationId']) && station?.['status'] === 'Configure')
        let provider_stations_ids = provider_stations?.map((station: any) => station?.['groundStationId'])
        let updated_provider = {
            ...provider_details,
            mqttConfiguredBands: provider_details?.['mqttConfiguredBands'] ? provider_details?.['mqttConfiguredBands'] : [],
            groundStations: provider_stations_ids,
            nextConfigStep: (!provider_details?.['MQTTEndpoint'] || provider_details?.['MQTTEndpoint'] === '') ? 'Configure Provider' : provider_stations?.length === 0 ? 'Add Ground Station' : not_configured_provider_stations?.length > 0 ? 'Configure Ground Station' : (!provider_details?.['MQTTTopicList'] || provider_details?.['MQTTTopicList']?.length === 0 || need_mqtt_configured) ? 'Configure MQTT Topics' : '',
            status: ((!provider_details?.['MQTTEndpoint'] || provider_details?.['MQTTEndpoint'] === '') || provider_stations?.length === 0 || (!provider_details?.['MQTTTopicList'] || provider_details?.['MQTTTopicList']?.length === 0 || need_mqtt_configured)) ? 'Not Configured' : 'Configured',
        }

        let filter_providers = all_providers?.filter(item => item?.['uniqueId'] !== provider_details?.['uniqueId'])
        let providers_list = [...filter_providers, updated_provider]

        GroundStationService.updateGsProvider(provider_details?.['uniqueId'], updated_provider).then((res: any) => {
            if (res.data) {
                get_provider_details()
                update_mission_ground_stations(providers_list)
            }
        }).catch((err) => {
            console.error(err);
            toast.error('Failed to update provider.')
        })
    }

    const update_ground_station = (station) => {
        let filter_stations = all_ground_stations?.filter(gs => gs?.['groundStationId'] !== station?.['groundStationId'])
        let updated_station = {
            ...station,
            properties: {
                ...station?.['properties'],
                provider: '',
            },
            status: (!station?.['properties']?.['altitude'] || station?.['properties']?.['altitude'] === '') ? 'Configure' : 'Associate With Provider',
        }
        let all_stations = [...filter_stations, updated_station]
        let all_provider_stations = all_stations.filter(gs => provider_details?.['groundStations'] && provider_details?.['groundStations'].includes(gs?.['groundStationId']))
        let provider_stations = all_provider_stations.filter(gs => gs?.['groundStationId'] !== station?.['groundStationId'])
        if (station?.['owner-org'] !== "System") {
            GroundStationService.updateGroundStations([updated_station]).then((res) => {
                if (res.data) {
                    update_provider_details(provider_stations)
                }
            }).catch((err) => {
                console.error(err);
                toast.error('Failed to remove ground station from provider.')
            })
        } else {
            update_provider_details(provider_stations)
        }
    }

    const create_manual_gs = (station) => {
        let filter_stations = all_ground_stations?.filter(gs => gs?.['groundStationId'] !== station?.['groundStationId'])
        let updated_station = {
            ...station,
            properties: {
                ...station?.['properties'],
                provider: provider_details?.['providerName'],
            },
            status: 'Configured'
        }

        GroundStationService.createGroundStation(station?.['groundStationName'], updated_station).then((res) => {
            if (res.data) {
                let newly_created_station = res.data;
                let all_stations = [...filter_stations, newly_created_station]
                let provider_stations = all_stations.filter(gs => provider_details?.['groundStations'] && provider_details?.['groundStations'].includes(gs?.['groundStationId']))
                let all_provider_stations = [...provider_stations, newly_created_station]
                update_provider_details(all_provider_stations)
                setPreferanceType('')
            }
        }).catch((err) => {
            console.error(err);
            toast.error('Failed to create ground station.')
        })
    }


    useEffect(() => {
        const handle_click = () => {
            setExpanded('')
            set_show_btn_group('')
        }
        window.addEventListener('click', handle_click)
        return (() => { window.removeEventListener('click', handle_click) })
    }, [])

    const create_ground_station = (data) => {
        const current_name = data?.['groundStationName'].includes('(') ? data?.['groundStationName'].split('(')[0] : data?.['groundStationName']
        const filter_gs_with_same_name = all_ground_stations?.filter((gs: any) => gs?.['groundStationName'].toLowerCase().trim().includes(current_name.toLowerCase().trim()))
        const same_name_array = filter_gs_with_same_name?.map(station => station?.['groundStationName'])

        function getMaxCopyNumber(names: string[]): number {
            const maxNumber = names.map(name => {
                const match = name.match(/\(Copy (\d+)\)$/); // Match pattern "(Copy <number>)"
                return match ? parseInt(match[1], 10) : 0; // Convert to integer, default to 0
            })
                .reduce((max, num) => Math.max(max, num), 0); // Find max number

            return maxNumber;
        }
        const max_number = getMaxCopyNumber(same_name_array);

        const gs_name = same_name_array.length === 1 ? `${current_name.trim()} (Copy)` : same_name_array.length === 2 ? `${current_name.trim()} (Copy 1)` :
            `${current_name.trim()} (Copy ${max_number + 1})`

        const updated_gs = {
            groundStationName: gs_name,
            properties: data?.['properties'],
            status: data?.['status']
        }
        GroundStationService.createGroundStation(gs_name, updated_gs)?.then((res) => {
            if (res.data) {
                const all_provider_stations = [...provider_stations, res.data]
                update_provider_details(all_provider_stations)
            }
        }).catch((err) => {
            toast.error('Something went wrong while creating ground station.')
        })
    }


    return (
        <Fragment>
            {provider_stations?.length > 0 &&
                <div className='position-relative'>
                    <div className='d-flex align-items-center position-absolute gap-2 me-2' style={{ top: -70, right: 0 }}>
                        <button className='mqtt_config_btn me-3' onClick={() => { setGSTypeSelectModal(true) }}>
                            <i className='fe fe-plus fs-16 color-text' /> Add
                        </button>
                        <CustomToolTip title='List View' placement='top'>
                            <button className='p-0 m-0 bg-transparent cursor-auto'>
                                <img src={require('../../../../assets/images/svgs/gs/list_selected.svg').default} height={35} alt="list" />
                            </button>
                        </CustomToolTip>
                        <CustomToolTip title='Map View' placement='top'>
                            <button className='p-0 m-0 bg-transparent' onClick={() => { setPreferanceType('Map') }}>
                                <img src={require('../../../../assets/images/svgs/gs/map.svg').default} height={35} alt="map" />
                            </button>
                        </CustomToolTip>
                    </div>
                </div>
            }
            <div className='d-flex flex-column justify-content-start w-100 mt-5 gap-2'>
                {provider_stations?.length > 0 &&
                    <div className='d-flex flex-column gap-3 w-100 '>
                        <div className='d-flex w-100 align-items-center border rounded-3 py-3 px-5 justify-content-between '>
                            <div className="w-95 d-flex align-items-center justify-content-between">
                                <span className='fs-16 w-25 align-items-center justify-content-start d-flex color-table-header-text '>GROUND STATIONS</span>
                                <span className='fs-16 w-40 d-flex color-table-header-text align-items-center justify-content-start'>LOCATION</span>
                                <span className='fs-16 w-15 d-flex color-table-header-text align-items-center justify-content-start'>PROVIDER</span>
                                <span className='fs-16 w-20 d-flex color-table-header-text align-items-center justify-content-start'>FREQUENCY BANDS</span>
                            </div>
                            <span className='fs-16 w-5 align-items-center justify-content-end d-flex color-table-header-text '>ACTION</span>
                        </div>
                        <div className='py-2 px-0 m-0 gap-2 d-flex flex-column'
                            style={{ height: 'calc(100vh - 400px)', overflowY: 'auto' }}
                        >
                            {provider_stations.map((station: any, i) => {
                                return (
                                    <div key={i} className={`w-100 d-flex flex-column gs__details__containe ${expanded === station["groundStationId"] && 'active'} `}>
                                        <div className={`d-flex w-100 align-items-center rounded-3 gs__details__containe__header justify-content-between cursor-pointer`}>

                                            <div className="w-95 d-flex align-items-start justify-content-between"
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    set_show_btn_group('')
                                                    setExpanded(expanded !== station["groundStationId"] ? station["groundStationId"] : '')
                                                }}
                                            >
                                                <div className="w-25 mt-2 align-items-center justify-content-start d-flex gap-2">
                                                    <img src={require('../../../../assets/images/svgs/gs/ground_station.svg').default} width='30px' height='30px' alt={''} />
                                                    <div className='w-80 d-flex flex-column'>
                                                        <span className="w-100 text-truncate fs-16 color-text" title={station.groundStationName}>
                                                            {station.groundStationName}
                                                        </span>
                                                        {station?.status !== 'Configured' && <p className={`gsp__status__badge ${station?.status === 'Configured' ? 'configured' : 'not_configure'} `}>{station?.status === 'Configured' ? 'Configured' : 'Not Configured'}</p>}
                                                    </div>
                                                </div>
                                                <div className='d-flex flex-column w-40 align-items-start justify-content-start'>
                                                    <div className='color_history d-flex w-100 gap-1'>
                                                        <span className="color_history" style={{ width: '75px' }}>Location :</span>
                                                        <span className='color-location d-flex fs-16 text-truncate w-80'>
                                                            <span className="text-truncate w-auto" title={station?.properties?.['location']}>
                                                                {(station?.properties?.['location'] && station?.properties?.['location'] !== '') ? station?.properties?.['location'] : '--'}
                                                            </span>
                                                            <span className="text-truncate w-auto" title={station?.properties?.country}>
                                                                {(station?.properties?.country && station?.properties?.country !== '') && `, ${station?.properties?.country?.includes(',') ? station?.properties?.country.split(",")[1] : station?.properties?.country}`}
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div className='d-flex gap-2 '>
                                                        <span className='color_history'>Lat: <span className='color-text'>{station?.properties?.['latitude']}</span></span>
                                                        <span className='color_history'>|</span>
                                                        <span className='color_history'>Lon: <span className='color-text'>{station?.properties?.['longitude']}</span></span>
                                                    </div>
                                                </div>
                                                <span className='fs-14 w-15 mt-2 d-flex color-text align-items-center justify-content-start'>
                                                    <span className="w-auto text-truncate" title={station?.properties?.provider}>
                                                        {station?.properties?.provider?.length > 10 ? station?.properties?.provider.substring(0, 10) + '...' : station?.properties?.provider}
                                                    </span>
                                                </span>
                                                <span className='fs-14 w-20 mt-2 d-flex color-text align-items-center justify-content-start'>{
                                                    (station?.['properties']?.['freqbands'] && station?.['properties']?.['freqbands']?.length > 0) &&
                                                    station?.['properties']?.['freqbands'].map((band, i) => `${band} ${i === station?.properties?.freqbands?.length - 1 ? '' : '| '} `)}
                                                </span>
                                            </div>

                                            <span className='fs-16 w-5 align-items-center justify-content-end d-flex color-text cursor-pointer'>
                                                <ButtonGroup size="sm" className="flex-nowrap" onClick={(e) => { e.stopPropagation() }}>
                                                    <div className="btn-list more-button">
                                                        <Dropdown drop="end" show={show_btn_group === station?.['groundStationId']}>
                                                            <Dropdown.Toggle size="sm" variant="transparent"
                                                                onClick={() => {
                                                                    set_show_btn_group(show_btn_group === station?.['groundStationId'] ? '' : station?.['groundStationId'])
                                                                }}
                                                            >
                                                                <i className="fe fe-more-vertical fs-20"></i>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                {station?.['owner-org'] !== "System" &&
                                                                    <Dropdown.Item eventKey="0"
                                                                        onClick={() => {
                                                                            setPreferanceType({
                                                                                mode: 'Manual_Update',
                                                                                data: station
                                                                            })
                                                                            set_show_btn_group('')
                                                                        }}>
                                                                        Edit
                                                                    </Dropdown.Item>
                                                                }
                                                                <Dropdown.Item eventKey="0"
                                                                    onClick={() => {
                                                                        create_ground_station(station)
                                                                        set_show_btn_group('')
                                                                    }}>
                                                                    Clone
                                                                </Dropdown.Item>
                                                                <Dropdown.Item eventKey="2"
                                                                    onClick={() => {
                                                                        update_ground_station(station)
                                                                        set_show_btn_group('')
                                                                    }}>
                                                                    Remove
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </ButtonGroup>
                                            </span>
                                        </div>
                                        {expanded === station["groundStationId"] && <div className="w-100 d-flex flex-column gap-2 rounded-2 p-4 ps-6 gs__details__containe__body">
                                            <div className='w-100 d-flex align-items-center mb-2'>
                                                <span className='w-10 d-flex align-items-center justify-content-start opacity-50'>Bands</span>
                                                <span className='w-20 d-flex align-items-center justify-content-center opacity-50'>Rx Freq.</span>
                                                <span className='w-20 d-flex align-items-center justify-content-center opacity-50'>Tx Freq.</span>
                                                <span className='w-15 d-flex align-items-center justify-content-center opacity-50'>G/T (dB/K)</span>
                                                <span className='w-15 d-flex align-items-center justify-content-center opacity-50'>EIRP (dBW)</span>
                                                <span className='w-20 d-flex align-items-center justify-content-center opacity-50'>Polarization</span>
                                            </div>
                                            {station?.properties?.['freqbands'].map((band, i) => {
                                                return (
                                                    <div key={i} className='w-100 d-flex align-items-center d-flex align-items-center'>
                                                        <span className="fs-14 w-10 color-atmos-secondary align-items-center justify-content-start">{band} </span>
                                                        <span className='w-20 d-flex align-items-center justify-content-center'>{station?.properties?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min']} - {station?.properties?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max']}</span>
                                                        <span className='w-20 d-flex align-items-center justify-content-center'>{Object.keys(station?.properties?.['txFreq']?.[`${band.split('-')[0]}freq`])?.length > 0 ? `${band !== 'X-band' ? `${station?.properties?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['min']} - ${station?.properties?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['max']}` : '--'}` : '--'}</span>
                                                        <span className='w-15 d-flex align-items-center justify-content-center'>{station?.properties?.[`${band === 'UHF' ? 'U' : band.split('-')[0]}_GT`] ? station?.properties?.[`${band === 'UHF' ? 'U' : band.split('-')[0]}_GT`] : '--'}</span>
                                                        <span className='w-15 d-flex align-items-center justify-content-center'>{(station?.properties?.[`${band === 'UHF' ? 'U' : band.split('-')[0]}_EIRP`]) ? station?.properties?.[`${band === 'UHF' ? 'U' : band.split('-')[0]}_EIRP`] : '--'}</span>
                                                        <span className='w-20 d-flex align-items-center justify-content-center'>{station?.properties?.polarization ? station?.properties?.polarization?.length > 15 ? `${station?.properties?.polarization.substring(0, 15)}...` : station?.properties?.polarization : '--'}</span>
                                                    </div>
                                                )
                                            })}
                                        </div>}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                }

                {provider_stations?.length === 0 &&
                    <div
                        className="add-mission-row"
                        onClick={() => { setGSTypeSelectModal(true) }}
                    >
                        <i className="fe fe-plus fs-20  me-3"></i>
                        Add Ground Stations
                    </div>
                }
            </div>

            {gsTypeSelectModal &&
                <GsTypeModal
                    close={() => { setGSTypeSelectModal(false) }}
                    set_preferance_type={(preferance) => { setPreferanceType(preferance) }}
                />
            }

            {(preferanceType === 'Manual_Create' || preferanceType?.mode === 'Manual_Update') &&
                <GroundStationForm
                    close={() => {
                        setPreferanceType('')
                    }}
                    configure_station={false}
                    provider_stations={provider_stations}
                    update_provider_details={(provider_stations) => {
                        update_provider_details(provider_stations)
                    }}
                    get_all_ground_stations={get_all_ground_stations}
                    station_type='Manual'
                    create_manual_gs={(station) => {
                        create_manual_gs(station)
                    }}
                    data={preferanceType?.data ? preferanceType?.data : null}
                    mode={preferanceType === 'Manual_Create' ? 'Create' : 'Update'}
                />
            }

            {(preferanceType === 'Map') &&
                <GsMapVisualization
                    close={() => { setPreferanceType('') }}
                    ground_stations={all_ground_stations}
                    provider_detail={provider_details}
                    all_providers={all_providers}
                    get_provider_details={get_provider_details}
                    get_all_ground_stations={get_all_ground_stations}
                    mode={preferanceType}
                />
            }
        </Fragment>
    )
}

export default ProviderStations
