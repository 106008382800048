import { FC, createRef, useEffect, useState } from "react";
import './CustomSelect.scss';
import useUrlParams from "../hooks/useUrlParams";

export interface Option {
    label: string;
    value: any;
    disabled?: boolean;
    hideOption?: boolean;
    style?: string;
}

interface Props {
    options: Option[] | [];
    value: Option | null;
    onChange: (data: Option) => void;
    placement?: "top" | "bottom";
    disabled?: boolean;
    hideOption?: boolean;
    style?: React.CSSProperties;
    className?: string;
    placeholder?: string;
    disabled_search?: boolean;
    disabled_option?: string | boolean;
    hide_option?: string | boolean;
}

const CustomSelect: FC<Props> = ({ options, value, onChange, placement = 'bottom', disabled = false, disabled_option = false, hide_option = false, disabled_search = false, style = {}, className = '', placeholder = "Select an option" }) => {

    const { dashboard } = useUrlParams();
    const selectorRef = createRef<HTMLDivElement>();
    const [searchValue, setSearchValue] = useState('');
    const [filterOptions, setFilterOptions] = useState<Option[]>([]);

    const filter_options = (event: any) => {
        event.preventDefault();
        const searchValue = event.target.value;
        // Ensure dropdown opens when typing
        const selector = selectorRef.current;
        if (selector && !selector.classList.contains("active")) {
            selector.classList.add("active");
        }
        setFilterOptions(
            searchValue.length > 0 ? options.filter((option) => option.label.toLowerCase().includes(searchValue.toLowerCase())) : options
        );
        setSearchValue(searchValue);
    };

    useEffect(() => {
        if (options.length > 0) {
            setFilterOptions(options);
        }
    }, [options]);

    useEffect(() => {
        if (value === null) {
            setSearchValue("");
        } else if (value) {
            setSearchValue(value.label);
        }
    }, [value]);

    useEffect(() => {
        const closeSelector = (event: MouseEvent) => {
            const selector = selectorRef.current;
            if (selector && !selector.contains(event.target as Node)) {
                selector.classList.remove("active");
            }
        };
        document.addEventListener("mousedown", closeSelector);

        return () => {
            document.removeEventListener("mousedown", closeSelector);
        };
    }, [options, value, selectorRef]);

    const open_selector_container = (event: any) => {
        if (disabled) return;
        const allSelectors = document.querySelectorAll('.selector__select__container');
        allSelectors.forEach((selector) => {
            if (selector !== selectorRef.current) {
                selector.classList.remove('active');
            }
        });

        const selector = selectorRef.current;
        if (!selector) return;
        if (!selector.classList.contains("active")) {
            selector.classList.add("active");
            setFilterOptions(options);
        } else {
            selector.classList.remove("active");
            setSearchValue(value ? value.label : "");
        }
    };

    const on_option_select = (option: Option) => {
        onChange(option);
        setSearchValue(option.label);
        setFilterOptions(options);
        window.dispatchEvent(new Event('storageUpdate'));
    };

    return (
        <div ref={selectorRef} className={`selector__select__container ${className} ${disabled}`} onClick={open_selector_container} style={style}>
            <div className={`selector__selected ${className}`}>
                <input type="text" className="input__box"
                    disabled={disabled_search}
                    value={searchValue}
                    placeholder={placeholder}
                    onChange={(e) => filter_options(e)}
                />
                <i className='fe fe-chevron-down icon' />
            </div>
            <div className="selector__option__container"
                style={{
                    top: placement === 'bottom' ? '40px' : 'auto',
                    bottom: placement === 'top' ? '40px' : 'auto'
                }}
            >
                {filterOptions.map((option, index) => {
                    const option_disabled = option.disabled || disabled_option === option.value;
                    const option_hide = option.hideOption || hide_option === option.value;
                    return (
                        <div className={`selector__option 
                             ${(value && option.label === value.label) ? 'selected' : ''} 
                            ${option_hide ? 'hide_option' : ''} 
                            ${option.style ? 'unique_option' : ''}
                            ${(dashboard !== 'operate' && option.value === 180) ? 'hide_option' : ''}`}
                            key={index}
                            onClick={() => {
                                if (!option_disabled) {
                                    on_option_select(option);
                                }
                            }}
                            style={{
                                pointerEvents: option_disabled ? 'none' : 'auto',
                                opacity: option_disabled ? 0.5 : 1,
                                display: option_hide ? 'none' : 'block'
                            }}
                        >
                            {option.label}
                        </div>
                    );
                })}
                {(options.length === 0 || filterOptions.length === 0) && (
                    <div className="selector__option__container__empty">
                        Options not available
                    </div>
                )}
            </div>
        </div>
    );
}

export default CustomSelect;
