/* DATE FUNCTIONS */

/* For ex: dateString = 2024-04-01T08:30:00Z (ISO 8601)
    return value = 1 April, 2024 8:30 UTC 
    verified on https://www.timestamp-converter.com/
*/

export const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getUTCDate();
    const month = date.toLocaleString('en-GB', { month: 'long', timeZone: 'UTC' });
    const year = date.getUTCFullYear();
    
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, '0'); // ensures two digits for minutes

    return `${day} ${month}, ${year} ${hours}:${minutes} UTC`;
}

/* CONVERSION FUNCTIONS */

// list: constant having keys label and value
// search: 'term needs to be searched'

// this function returns the server value for the label displayed on UI
export const getValue = (list, search) => {
    const item = list.find(item => item.label === search);
    return item ? item.value : null;
}

// this function returns the label for the label server value
export const getLabel = (list, search) => {
    const item = list.find(item => item.value === search);
    return item ? item.label : null;
}