// fileSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedFile: null,
};

const fileSlice = createSlice({
    name: 'file',
    initialState,
    reducers: {
        setFile: (state, action) => {
            state.selectedFile = action.payload;
        },
        clearFile: (state) => {
            state.selectedFile = null;
        },
    },
});

export const { setFile, clearFile } = fileSlice.actions;

export default fileSlice.reducer;
