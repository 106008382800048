import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
    creatingConstellation: false,

    deletingConstellation: false,
    createdConstellation: {},
    constellations: [],
    deorbitEclipseReportName: '',
    deorbitEclipseReport: {},
    isGeoFeatureView: false,
    selectedFeature: {},
    aois: [],
    // All AOi's for visualisations also
    aoiCollection: {},

    //All Ground station for visualisation also
    allGroundStationList: [],
    allGlobalGsList: [],

    editConstellationObject: {},
    editGroundStationObject: {},
    editAreaOfInterestObject: {},
    editConstellationConfig: {},

    // Things related to coverage analysis
    selectedConstellationsForCoverage: [],
    selectedGeoFeaturesForCoverage: [],
    selectedGroundStationForCoverage: [],

    //coverage visulisation objects which should be highlighted
    coverageAnalysisReport: {},
    coverageAnalysisOrbit: {},
    groundStationAnalysisReport: {},
    gsVisualisationData: {},
    latestReport: '',

    // notificatio section
    notification: {
        isError: false,
        isSuccess: false,
        message: ''
    },

    // statue of Orbit and Eclipse Report
    isAllReportCompleted: false,

    isCoverageAnalysisReportCompleted: true,
    coverageAnalysisId: '',

    orbitEclipseReportStatus: [],
    allOrbit: [],

    isModalMinimized: false,
};

function convertToGeoJSON(aoiData) {
    const geoJSON = {
        type: "FeatureCollection",
        features: []
    };
    aoiData.forEach(aoi => {
        const feature = {
            type: "Feature",
            properties: {
                areaOfInterestId: aoi.areaOfInterestId,
                name: aoi.name,
                description: aoi.description,
                missionId: aoi.missionId,
                tenantId: aoi.tenantId,
                createdAt: aoi.createdAt,
                updatedAt: aoi.updatedAt,
                type: aoi.type
            },
            geometry: {}
        };
        if (aoi.type === "Geofence") {
            feature.geometry = {
                type: "Polygon",
                coordinates: [aoi.polygon]
            };
        } else if (aoi.type === "Target Track" && aoi.coordinates) {
            feature.geometry = {
                type: "Point",
                coordinates: [aoi.coordinates.long, aoi.coordinates.lat]
            };
        }
        geoJSON.features.push(feature);
    });
    return geoJSON;
}

const saveSelectedFeature = (state, selectedFeature) => {
    const aoiCollection = state.aoiCollection;
    let selectedFeatureData = [];
    selectedFeatureData = aoiCollection.features.filter((feature) => {
        return feature.properties['areaOfInterestId'] === selectedFeature.properties['areaOfInterestId'];
    });
    if (selectedFeatureData.length > 0) {
        return selectedFeatureData[0];
    };
    return {};
};

const MissionModellingSlice = createSlice(
    {
        name: 'MissionModellingSlice',
        initialState,
        reducers: {
            createConstellationsRequest: (state) => {
                state.creatingConstellation = true
            },
            createConstellationsSuccess: (state, action) => {
                const type = action.payload.data.type;
                state.notification = {
                    isError: false,
                    isSuccess: true,
                    message: type === 'Single' ? 'Satellite Created' : 'Constellation Created'
                }
                state.isAllReportCompleted = false
                state.createdConstellation = action.payload
            },
            createConstellationsFailure: (state, action) => {
                const type = action.payload.type;
                state.notification = {
                    isError: true,
                    isSuccess: false,
                    message: type === 'Single' ? 'Failed To Create Satellite' : 'Failed To Create Constellation'
                }
                state.creatingConstellation = false
            },

            getConstellationsRequest: (state) => {
                state.constellations = []
                state.creatingConstellation = true
            },
            getConstellationsSuccess: (state, action) => {
                state.constellations = action.payload.data
                state.creatingConstellation = false
            },
            getConstellationsFailure: (state) => {
                state.creatingConstellation = false
            },

            getDeorbitEclipseReportRequest: (state) => {
                //loader true
                state.deorbitEclipseReport = []
            },
            getDeorbitEclipseReportSuccess: (state, action) => {
                state.deorbitEclipseReport = action.payload
            },
            getDeorbitEclipseReportFailure: (state) => {
                // loader false
            },
            setDeorbirEclipseReportName: (state, action) => {
                state.deorbitEclipseReportName = action.payload
            },

            setGeoFeatureView: (state, action) => {
                state.isGeoFeatureView = action.payload;
            },
            resetGeoFeatureView: (state) => {
                state.isGeoFeatureView = false;
            },
            setSelectedFeature: (state, action) => {
                state.selectedFeature = saveSelectedFeature(current(state), action.payload);
            },
            resetSelectedFeature: (state) => {
                state.selectedFeature = {};
            },

            getAoiRequest: (state) => {
                state.aois = [];
            },
            getAllAoiSucess: (state, action) => {
                state.aois = action.payload ? action.payload : [];
                state.aoiCollection = action?.payload ? convertToGeoJSON(action.payload) : {};
            },
            getAoiFailure: (state) => {
                state.aois = [];
            },

            createAoiRequest: () => { },
            createAoiSuccess: (state) => {
                state.notification = {
                    isError: false,
                    isSuccess: true,
                    message: 'GeoFeature Created'
                }
            },
            createAoiFailure: (state) => {
                state.notification = {
                    isError: true,
                    isSuccess: false,
                    message: 'Creation Failed'
                }
            },

            deleteAoiRequest: (state) => { },
            deleteAoiSuccess: (state, actiom) => {
                state.notification = {
                    isError: false,
                    isSuccess: true,
                    message: 'GeoFeature Deleted'
                }
            },
            deleteAoiFailure: (state) => {
                state.notification = {
                    isError: true,
                    isSuccess: false,
                    message: 'Action Failed'
                }
            },

            renameAoiRequest: (state) => { },
            renameAoiSuccess: (state, actiom) => {
                state.notification = {
                    isError: false,
                    isSuccess: true,
                    message: 'GeoFeature Renamed'
                }
            },
            renameAoiFailure: (state) => {
                state.notification = {
                    isError: true,
                    isSuccess: false,
                    message: 'Action Failed'
                }
            },

            editAoiRequest: (state) => { },
            editAoiSuccess: (state, actiom) => {
                state.notification = {
                    isError: false,
                    isSuccess: true,
                    message: 'GeoFeature Updated'
                }
            },
            editAoiFailure: (state) => {
                state.notification = {
                    isError: false,
                    isSuccess: true,
                    message: 'Action Failed'
                }
            },

            deleteConstellationRequest: (state) => {
                state.deletingConstellation = true
            },
            deleteConstellationSuccess: (state, action) => {
                state.notification = {
                    isError: false,
                    isSuccess: true,
                    message: action.payload === 'Single' ? 'Satellite Deleted' : 'Constellation Deleted'
                }
                state.deletingConstellation = false
            },
            deleteConstellationFailure: (state, action) => {
                state.deletingConstellation = false
                state.notification = {
                    isError: true,
                    isSuccess: false,
                    message: action.payload === 'Single' ? 'Failed to Delete Satellite' : 'Failed to Delete Constellation'
                }
            },

            getAllGroundStationRequest: (state) => { },
            getAllGroundStationSuccess: (state, action) => {
                state.allGroundStationList = action.payload.data
            },
            getAllGroundStationFailure: (state) => { },


            getGlobalGroundStationRequest: (state) => { },
            getGlobalGroundStationSuccess: (state, action) => {
                state.allGlobalGsList = action.payload.data
            },
            getGlobalGroundStationFailure: (state) => { },

            createGroundStationRequest: (state) => { },
            createGroundStationSuccess: (state, action) => {
                state.notification = {
                    isError: false,
                    isSuccess: true,
                    message: 'Ground Station Created'
                }
            },
            createGroundStationFailure: (state) => {
                state.notification = {
                    isError: false,
                    isSuccess: true,
                    message: 'Failed to create Ground Station'
                }
            },

            duplicateConstellationRequest: (state) => { },
            duplicateConstellationSuccess: (state) => {
                state.notification = {
                    isError: false,
                    isSuccess: true,
                    message: 'Constellation Created'
                }
                state.isAllReportCompleted = false
            },
            duplicateConstellationFailure: (state) => {
                state.notification = {
                    isError: false,
                    isSuccess: true,
                    message: 'Failed to Clone Constellation'
                }
            },

            renameConstellationRequest: (state) => { },
            renameConstellationSuccess: (state) => {
                state.notification = {
                    isError: false,
                    isSuccess: true,
                    message: 'Constellation Renamed'
                }
            },
            renameConstellationFailure: (state) => {
                state.notification = {
                    isError: false,
                    isSuccess: true,
                    message: 'Failed to Rename Constellation'
                }
            },

            editConstellationRequest: (state) => {
                state.creatingConstellation = true
            },
            editConstellationSuccess: (state) => {
                state.isAllReportCompleted = false
                state.notification = {
                    isError: false,
                    isSuccess: true,
                    message: 'Constellation Edited'
                }
                state.creatingConstellation = true
            },
            editConstellationFailure: (state) => {
                state.notification = {
                    isError: true,
                    isSuccess: false,
                    message: 'Failed to Edit Constellation'
                }
                state.creatingConstellation = true
            },

            editConstellationConfigRequest: (state) => {
                state.creatingConstellation = true
            },
            editConstellationConfigSuccess: (state, aoiCollection) => {
                state.notification = {
                    isError: false,
                    isSuccess: true,
                    message: 'Constellation Config Edited'
                }
                state.creatingConstellation = false
            },
            editConstellationConfigFailure: (state) => {
                state.creatingConstellation = false
                state.notification = {
                    isError: true,
                    isSuccess: false,
                    message: 'Failed to Edit Configuration of Consteallltion'
                }
            },


            renameGroundStationRequest: (state) => { },
            renameGroundStationSuccess: (state) => {
                state.notification = {
                    isError: false,
                    isSuccess: true,
                    message: 'Ground Station Renamed'
                }
            },
            renameGroundStationFailure: (state) => {
                state.notification = {
                    isError: true,
                    isSuccess: false,
                    message: 'Failed to Rename Ground Station'
                }
            },

            editGroundStationRequest: (state) => { },
            editGroundStationSuccess: (state) => {
                state.notification = {
                    isError: false,
                    isSuccess: true,
                    message: 'Ground Station Edited'
                }
            },
            editGroundStationFailure: (state) => {
                state.notification = {
                    isError: true,
                    isSuccess: false,
                    message: 'Failed to Edit Ground Station'
                }
            },

            promoteToMissionRequest: (state) => { },

            promoteToMissionFailure: (state) => { },

            deleteGroundStationRequest: (state) => { },
            deleteGroundStationSuccess: (state) => {
                state.notification = {
                    isError: false,
                    isSuccess: true,
                    message: 'Ground Station Deleted'
                }
            },
            deleteGroundStationFailure: (state) => {
                state.notification = {
                    isError: true,
                    isSuccess: false,
                    message: 'Failed to Delete Ground Station'
                }
            },

            setEditConstellationObject: (state, action) => {
                state.editConstellationObject = action.payload
            },
            setEditGroundStationObject: (state, action) => {
                state.editGroundStationObject = action.payload
            },
            setEditAreaOfInterestObject: (state, action) => {
                state.editAreaOfInterestObject = action.payload
            },

            setEditConstellationConfig: (state, action) => {
                state.editConstellationConfig = action.payload
            },
            // coverage analysis functions
            setSelectedConstellationsForCoverage: (state, action) => {
                let isAlreadyConstellation = false;
                let constellations = [];
                constellations = state.selectedConstellationsForCoverage

                constellations?.map((constellation, index) => {
                    if (constellation.constellationId === action.payload.constellationId) {
                        isAlreadyConstellation = true
                    }
                })
                if (!isAlreadyConstellation) {
                    constellations.push(action.payload)
                    state.selectedConstellationsForCoverage = constellations;
                }
            },
            setSelectedGeoFeatureForCoverage: (state, action) => {
                let isAlreadyExistingFeature = false;
                let geofense = [];
                geofense = state.selectedGeoFeaturesForCoverage

                geofense?.map((item, index) => {
                    if (item.areaOfInterestId === action.payload.areaOfInterestId) {
                        isAlreadyExistingFeature = true
                    }
                })

                if (!isAlreadyExistingFeature) {
                    geofense.push(action.payload)
                    state.selectedGeoFeaturesForCoverage = geofense;
                }
            },
            setSelectedGroundStationForCoverage: (state, action) => {
                let isAlreadyExistingGroundStation = false;
                let groundStations = [];
                groundStations = state.selectedGroundStationForCoverage;

                groundStations?.map((groundStation, index) => {
                    if (groundStation.groundStationId === action.payload.groundStationId) {
                        isAlreadyExistingGroundStation = true;
                    }
                })

                if (!isAlreadyExistingGroundStation) {
                    groundStations.push(action.payload)
                    state.selectedGroundStationForCoverage = groundStations;
                }
            },

            removeSelectedConstellationsForCoverage: (state, action) => {
                let constellations = [];
                constellations = state.selectedConstellationsForCoverage;
                constellations = constellations.filter(item => item.constellationId !== action.payload.constellationId);
                state.selectedConstellationsForCoverage = constellations;
            },
            removeAllSelectedConstellationsForCoverage: (state) => {
                let constellations = [];
                state.selectedConstellationsForCoverage = constellations;
            },
            removeSelectedGeoFeatureForCoverage: (state, action) => {
                let geofense = [];
                geofense = state.selectedGeoFeaturesForCoverage;
                geofense = geofense.filter(item => item.areaOfInterestId !== action.payload.areaOfInterestId);
                state.selectedGeoFeaturesForCoverage = geofense;
            },
            removeAllSelectedGeoFeatureForCoverage: (state) => {
                let geofense = [];
                state.selectedGeoFeaturesForCoverage = geofense;
            },
            removeSelectedGroundStationForCoverage: (state, action) => {
                let groundStations = [];
                groundStations = state.selectedGroundStationForCoverage;
                groundStations = groundStations.filter(item => item.groundStationId !== action.payload.groundStationId);
                state.selectedGroundStationForCoverage = groundStations;
            },
            removeAllSelectedGroundStationForCoverage: (state) => {
                let groundStations = [];
                state.selectedGroundStationForCoverage = groundStations;
            },

            coverageAnalysisRequest: (state) => {
                state.creatingConstellation = true
            },
            coverageAnalysisSuccess: (state, action) => {
                // state.notification = {
                //     isError: false,
                //     isSuccess: true,
                //     message: 'Coverage Analysis Completed, Generating Report ...'
                // }

                state.coverageAnalysisId = action.payload.coverageAnalysisId
                state.isCoverageAnalysisReportCompleted = false;
            },
            coverageAnalysisFailure: (state) => {
                state.notification = {
                    isError: true,
                    isSuccess: false,
                    message: 'Coverage Analysis Failed'
                }
                state.creatingConstellation = false
            },

            coverageAnalysisReportRequest: (state) => {

            },
            coverageAnalysisReportSuccess: (state, action) => {
                state.coverageAnalysisReport = action.payload
            },
            coverageAnalysisReportFailure: (state) => {

            },

            getCoverageOrbitDataRequest: (state) => { },
            getCoverageOrbitDataSuccess: (state, action) => {
                state.coverageAnalysisOrbit = action.payload.data
            },
            getCoverageOrbitDataFailure: (state) => { },

            getGroundStationReportRequest: (state) => {
                state.creatingConstellation = true
            },
            getGroundStationReportSuccess: (state, action) => {
                state.notification = {
                    isError: false,
                    isSuccess: true,
                    message: 'Ground Station Analysis Success'
                }
                state.creatingConstellation = false
            },
            getGroundStationReportFailure: (state) => {
                state.creatingConstellation = false
            },

            getGroundStationReportDataRequest: (state) => {
                state.creatingConstellation = true
            },
            getGroundStationReportDataSuccess: (state, action) => {
                state.notification = {
                    isError: false,
                    isSuccess: true,
                    message: 'Ground Station Report Fetched'
                }

                console.log("HEllo everyone -> ", action.payload)

                state.groundStationAnalysisReport = action.payload

                state.creatingConstellation = false
            },
            getGroundStationReportDataFailure: (state) => {
                state.creatingConstellation = false
            },

            getGSVisualisationDataRequest: (state) => {
                state.creatingConstellation = true
            },
            getGSVisualisationDataSuccess: (state, action) => {
                state.creatingConstellation = false
                state.gsVisualisationData = action.payload
            },
            getGSVisualisationDataFailure: (state) => {
                state.creatingConstellation = false
            },

            setNotification: (state, action) => {
                console.log("Action --> ", action.payload)
                state.notification = action.payload
            },
            clearNotification: (state) => {
                state.notification = {
                    isError: false,
                    isSuccess: false,
                    message: ''
                }
            },

            getStatusOverviewOfOrbitEclipseRequest: (state) => { },

            getStatusOverviewOfOrbitEclipseSuccess: (state, action) => {
                let statusFlag = true;
                state.orbitEclipseReportStatus = action.payload
                action.payload.map((item, index) => {
                    if (item?.overall_status === "FAILED") {
                        statusFlag = true
                    } else if (item?.overall_status !== "COMPLETED") {
                        statusFlag = false
                    }
                })
                state.isAllReportCompleted = statusFlag
            },
            getStatusOverviewOfOrbitEclipseFailure: (state) => { },

            getCoverageAnalysisReportStatusRequest: (state) => { },
            getCoverageAnalysisReportStatusSuccess: (state, action) => {
                if (action.payload.coverage_analysis_status === "COMPLETED") {
                    state.isCoverageAnalysisReportCompleted = true;
                }
            },
            getCoverageAnalysisReportStatusFailue: (state) => { },


            getAllOrbitRequest: (state) => { },
            getAllOrbitSuccess: (state, action) => {
                state.allOrbit = action.payload.data
            },
            getAllOrbitFailue: (state) => { },

            setReportCompletionStatus: (state, action) => {
                state.isAllReportCompleted = action.payload
            },
            setLatestReport: (state, action) => {
                state.latestReport = action.payload
            },
            setIsModalMinimized: (state) => {
                state.isModalMinimized = true;
            },
            resetIsModalMinimized: (state) => {
                state.isModalMinimized = false;
            },
            setCoverageAnalysisId: (state, action) => {
                state.coverageAnalysisId = action.payload
                state.creatingConstellation = false
            }
        }
    }
)

export const {
    setGeoFeatureView,
    resetGeoFeatureView,
    setSelectedFeature,
    resetSelectedFeature,

    getAoiRequest,
    getAoiFailure,
    getAllAoiSucess,

    createAoiRequest,
    createAoiSuccess,
    createAoiFailure,

    deleteAoiRequest,
    deleteAoiSuccess,
    deleteAoiFailure,

    renameAoiRequest,
    renameAoiSuccess,
    renameAoiFailure,

    editAoiRequest,
    editAoiSuccess,
    editAoiFailure,


    getDeorbitEclipseReportRequest,
    getDeorbitEclipseReportSuccess,
    getDeorbitEclipseReportFailure,

    createConstellationsRequest,
    createConstellationsSuccess,
    createConstellationsFailure,

    getConstellationsRequest,
    getConstellationsSuccess,
    getConstellationsFailure,

    deleteConstellationRequest,
    deleteConstellationSuccess,
    deleteConstellationFailure,

    duplicateConstellationRequest,
    duplicateConstellationSuccess,
    duplicateConstellationFailure,

    renameConstellationRequest,
    renameConstellationSuccess,
    renameConstellationFailure,

    editConstellationRequest,
    editConstellationSuccess,
    editConstellationFailure,

    editConstellationConfigRequest,
    editConstellationConfigSuccess,
    editConstellationConfigFailure,

    renameGroundStationRequest,
    renameGroundStationSuccess,
    renameGroundStationFailure,

    createGroundStationRequest,
    createGroundStationSuccess,
    createGroundStationFailure,

    getAllGroundStationRequest,
    getAllGroundStationSuccess,
    getAllGroundStationFailure,

    getGlobalGroundStationRequest,
    getGlobalGroundStationSuccess,
    getGlobalGroundStationFailure,

    deleteGroundStationRequest,
    deleteGroundStationSuccess,
    deleteGroundStationFailure,

    promoteToMissionRequest,
    promoteToMissionSuccess,
    promoteToMissionFailure,

    editGroundStationRequest,
    editGroundStationSuccess,
    editGroundStationFailure,

    setEditConstellationObject,
    setEditGroundStationObject,
    setEditAreaOfInterestObject,
    setEditConstellationConfig,

    setSelectedConstellationsForCoverage,
    setSelectedGeoFeatureForCoverage,
    setSelectedGroundStationForCoverage,
    removeSelectedConstellationsForCoverage,
    removeAllSelectedConstellationsForCoverage,
    removeSelectedGeoFeatureForCoverage,
    removeAllSelectedGeoFeatureForCoverage,
    removeSelectedGroundStationForCoverage,
    removeAllSelectedGroundStationForCoverage,

    coverageAnalysisRequest,
    coverageAnalysisSuccess,
    coverageAnalysisFailure,

    coverageAnalysisReportRequest,
    coverageAnalysisReportSuccess,
    coverageAnalysisReportFailure,

    getStatusOverviewOfOrbitEclipseRequest,
    getStatusOverviewOfOrbitEclipseSuccess,
    getStatusOverviewOfOrbitEclipseFailure,

    getAllOrbitRequest,
    getAllOrbitSuccess,
    getAllOrbitFailue,

    getCoverageOrbitDataRequest,
    getCoverageOrbitDataSuccess,
    getCoverageOrbitDataFailure,
    setDeorbirEclipseReportName,

    getGroundStationReportRequest,
    getGroundStationReportSuccess,
    getGroundStationReportFailure,

    getGroundStationReportDataRequest,
    getGroundStationReportDataSuccess,
    getGroundStationReportDataFailure,
    setLatestReport,

    getGSVisualisationDataRequest,
    getGSVisualisationDataSuccess,
    getGSVisualisationDataFailure,

    getCoverageAnalysisReportStatusRequest,
    getCoverageAnalysisReportStatusSuccess,
    getCoverageAnalysisReportStatusFailue,

    setNotification,
    clearNotification,
    setReportCompletionStatus,

    setIsModalMinimized,
    resetIsModalMinimized,
    setCoverageAnalysisId

} = MissionModellingSlice.actions

export default MissionModellingSlice.reducer;