import { useState, useRef, useEffect } from "react";
import ModalDropdown from "./ModalDropdown";
import FeaturesBar from "./FeaturesBar";
import UCPModalTable from "./UCPModalTable";
import StrategiesBar from "./StrategiesBar";
import PolicyCreationModal from "./PolicyCreationModal";

import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "./UcpComponents.css";
import { useDispatch, useSelector } from "react-redux";
import { setShowMapBoxMap } from "../../../store/UnifiedControlPlaneSlice";
import * as orderOptions from "../UnifiedControlPlane.constants";
import { getValue } from "./Utils/HelperFunctions";
import { resetMapSlice } from "../../../store/MapSlice";
import UnifiedControlPlaneService from "../UnifiedControlPlane.service";

const OrderCreationModal = ({ props }) => {

  const dispatch = useDispatch();

  const coordinates = useSelector(state => state.map.coordinates);
  const mode = useSelector(state => state.map.mode);
  const uploadedCollection = useSelector(state => state.unifiedControlPlane.uploadedCollection);

  const [isModalMinimized, setIsModalMinimized] = useState(false);
  const [orderCreationStage, setOrderCreationStage] = useState({ details: true, imaging: false, cue: false });

  const [cueForSatellite, setCueForSatellite] = useState({ highResImages: false, frequency: false });
  const [cueForGround, setCueForGround] = useState({ alerts: false, telemetry: false, imagery: false });

  const [orderData, setOrderData] = useState({
    name: '',
    aoi: {},
    aoiLabel: '',
    geoFeatureLabel: '',
    priorityLane: orderOptions.priorityLane[0],
    imagingSpectrum: orderOptions.imagingSpectrum[0].label,
    coverageWindowValue: 1,
    coverageWindowValueType: orderOptions.coverageWindowValueType[1],
    isRecurring: false,
    isAdvancedDetails: false,
    // gsdMin: 0,
    // gsdMax: 100,
    nadirMin: 0,
    nadirMax: 90,
    sunAngleMin: 0,
    sunAngleMax: 90,
    cloudCoverage: 0,
    selectedRecurringType: orderOptions.recurringType[0],
    resolution: orderOptions.resolution[0],
  });

  const closeOrderModal = () => {
    props.setIsOrdersBarVisible(true);
    props.setIsOrderCreationModal(false);
    dispatch(setShowMapBoxMap(false));
    dispatch(resetMapSlice());
  };

  const goToNextPage = () => {
    if (orderCreationStage.details) {
      setOrderCreationStage({ details: false, imaging: true, cue: false });
    } else if (orderCreationStage.imaging) {
      setOrderCreationStage({ details: false, imaging: false, cue: true });
    } else if (orderCreationStage.cue) {
    }
  };

  const prevButtonFunc = () => {
    if (orderCreationStage.cue) {
      setOrderCreationStage({ details: false, imaging: true, cue: false });
    } else if (orderCreationStage.imaging) {
      setOrderCreationStage({ details: true, imaging: false, cue: false });
    } else if (orderCreationStage.cue) {
    }
  };

  const handleChange = (e, item) => {
    const { name, value } = e.target || e;
    setOrderData((prevData) => {
      return {
        ...prevData,
        [name]: typeof item !== 'undefined' ? item : value,
      };
    });
  };

  const onSelectChange = (e, fieldName) => {
    e.name = fieldName;
    handleChange(e);
  };

  const isFileUploaded = () => {
    return Object.keys(uploadedCollection).length !== 0
  };

  const showAoiMessage = () => {
    return (mode.length === 0 || mode === 'simple_select' || mode === 'delete_aoi') ||
      (mode === 'upload_file' && !isFileUploaded()) ||
      ((mode === 'draw_polygon' || mode === 'draw_point') && coordinates.length === 0);
  };

  const isRequiredFields = () => {
    if (orderData.name.length === 0 || orderData.geoFeatureLabel.length === 0) {
      return true;
    }
    return false;
  }

  const disableNextButton = () => {
    if (orderCreationStage.details) {
      return showAoiMessage() || isRequiredFields();
    }
    return false;
  };

  const getDrawnFeature = () => {
    let featureShape = {
      properties: {},
      geometry: {
        type: '',
        coordinates: mode === 'draw_polygon' ? coordinates[0] : [coordinates],
      },
    };
    if (mode === 'draw_polygon') {
      featureShape.geometry.type = 'Polygon';
    } else if (mode === 'draw_point') {
      featureShape.geometry.type = 'Point';
    };
    return [featureShape];
  };

  const adjustCoordinatesOfUploadedFeatures = () => {
    const updatedData = uploadedCollection.features.map((feature) => {
      const newFeature = { ...feature };
      delete newFeature.type;
      if (feature.geometry && feature.geometry.coordinates) {
        newFeature.geometry = {
          ...feature.geometry,
          coordinates: feature.geometry.coordinates[0],
        };
      }
      return newFeature;
    });
    return updatedData;
  };

  const getFeatureCollection = () =>
    isFileUploaded() ? adjustCoordinatesOfUploadedFeatures() : getDrawnFeature();

  const transformOrderData = (d) => {
    const transformData = {
      name: d.name,
      geofeature: {
        features: getFeatureCollection(),
        label: d.geoFeatureLabel,
      },
      attributes: {
        priorityLane: d.priorityLane,
        imagingSpectrum: getValue(orderOptions.imagingSpectrum, d.imagingSpectrum),
        imagerADCSDetails: {
          // gsd: {
          //   min: parseInt(d.gsdMin),
          //   max: parseInt(d.gsdMax),
          // },
          nadirAngle: {
            min: parseInt(d.nadirMin),
            max: parseInt(d.nadirMax),
          },
          sunAngle: {
            min: parseInt(d.sunAngleMin),
            max: parseInt(d.sunAngleMax),
          },
        },
        cloudCoverage: parseInt(d.cloudCoverage),
        resolution: d.resolution,
        recurring: d.isRecurring,
        // recurringType: d.selectedRecurringType,
      },
      tipncue: {},
    };
    return transformData;
  }

  const placeOrder = () => {
    const transformedData = transformOrderData(orderData);
    dispatch(UnifiedControlPlaneService.createOrders(transformedData, closeOrderModal));
  };

  return (
    <>
      {/* {isAdvancedDetails && <UCPModalTable props={props} />} */}
      {/* {orderData.isAdvancedDetails && <StrategiesBar props={props} />} */}
      {orderCreationStage.details && <FeaturesBar props={props} />}
      <div className="orderCreationModal">
        <div className="orderCreationModalHeader bottomBorder">
          <div className="orderCreationModalHeaderText">Order</div>
          <button onClick={closeOrderModal}>
            <img src={require("../ImgAssets/CloseBtn.svg").default} />
          </button>
          <button onClick={() => setIsModalMinimized(!isModalMinimized)} className="minimizeIcon">
            {!isModalMinimized && <img src={require("../ImgAssets/MinimizeIcon.svg").default} />}
            {isModalMinimized && <img src={require("../ImgAssets/MaximizeIcon.svg").default} />}
          </button>
        </div>
        {!isModalMinimized && (
          <>
            <div className={orderCreationStage.details ? "activeSectionModal" : "inActiveSectionModal"}>
              <div className="sectionDivLine">
                <button onClick={() => setOrderCreationStage({ details: true, imaging: false, cue: false })} className="dropdownIconWhite">
                  <img
                    className={orderCreationStage.details ? "dropdownIcon open" : "dropdownIcon close"}
                    src={orderCreationStage.details ? require("../ImgAssets/DropdownWhiteOpen.svg").default : require("../ImgAssets/DropdownWhiteClose.svg").default}
                  />
                </button>
                <div className="sectionHeaderText">
                  Details
                  {!orderCreationStage.details && <img className="brightGreenTickIcon" src={require("../ImgAssets/BrightGreenTickIcon.svg").default} />}
                </div>
              </div>
              {orderCreationStage.details && (
                <>
                  {/* {isRequiredFields() &&
                    <div className="orangeNotificationDiv errorMessageDiv">
                      <img src={require("../ImgAssets/HelpCircleWhiteIcon.svg").default} />
                      Order name and AOI label are required fields
                    </div>
                  } */}
                  <div className="UcpModalSection modalInputFieldAlign lowTopPadding">
                    <div className="modalInputFieldText">Name</div>
                    <input
                      name="name"
                      className="modalInputFieldFullLen modalInputFieldRightFloat"
                      placeholder="Order Name"
                      value={orderData.name}
                      onChange={handleChange}
                      autoComplete="off"
                      maxLength={30}
                    >
                    </input>
                  </div>
                  <div className="UcpModalSection modalInputFieldAlign lowTopPadding">
                    <div className="modalInputFieldText">Geo Feature</div>
                    <input
                      className="modalInputFieldFullLen modalInputFieldRightFloat"
                      name="geoFeatureLabel"
                      value={orderData.geoFeatureLabel}
                      placeholder="Geo Feature"
                      onChange={handleChange}
                      autoComplete="off"
                      maxLength={30}
                    >
                    </input>
                  </div>
                  {showAoiMessage() &&
                    <div className="orangeNotificationDiv blueNotificationDiv">
                      <img src={require("../ImgAssets/HelpCircleWhiteIcon.svg").default} />
                      Use drawing tool or upload file in the right panel
                    </div>
                  }
                </>
              )}
            </div>

            <div className={orderCreationStage.imaging ? "activeSectionModal" : "inActiveSectionModal"}>
              <div className="sectionDivLine topBorder">
                <button onClick={() => setOrderCreationStage({ details: false, imaging: true, cue: false })} className="dropdownIconWhite">
                  <img
                    className={orderCreationStage.imaging ? "dropdownIcon open" : "dropdownIcon close"}
                    src={orderCreationStage.imaging ? require("../ImgAssets/DropdownWhiteOpen.svg").default : require("../ImgAssets/DropdownWhiteClose.svg").default}
                  />
                </button>
                <div className="sectionHeaderText">
                  Imaging Requirements
                  {orderCreationStage.cue &&
                    <img className="brightGreenTickIcon" src={require("../ImgAssets/BrightGreenTickIcon.svg").default} />}
                </div>
              </div>
              {/* style={{ padding: "12px 0px" }}  */}
              {orderCreationStage.imaging && (
                <div className="modalScrollableSection">
                  <div className="UcpModalSection resolutionSection modalInputFieldAlign lowTopPadding">
                    <div className="modalInputBoxText" style={{ fontSize: "14px" }}>
                      Resolution
                    </div>
                    <Dropdown
                      className="reactDropdownRootClass halfWidth orderDropdownFields"
                      controlClassName="reactDropdownClass halfWidth orderDropdownClass"
                      options={orderOptions.resolution}
                      value={orderData.resolution}
                      menuClassName="reactDropdownMenuClass halfWidth orderDropdownMenuClass"
                      arrowClassName="reactDropdownArrowClassName"
                      placeholder="Select an option"
                      name="resolution"
                      onChange={(e) => onSelectChange(e, 'resolution')}
                    />
                  </div>
                  <div className="UcpModalSection lowTopPadding">
                    <div className="modalInputTitleWhite">Priority Lane</div>
                    <div className="modalDivLine">
                      {orderOptions.priorityLane.map((item, i) => (
                        <div className="modalInputBox" key={i}>
                          <button
                            name="priorityLane"
                            onClick={(e) => handleChange(e, item)}
                            className={orderData.priorityLane === item ? "modalInputCircularButton active" : "modalInputCircularButton"}
                          />
                          <div className="modalInputBoxText" style={orderData.priorityLane === item ? {} : { opacity: "0.5" }}>
                            {item}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* {(orderData.priorityLane === "Medium" || orderData.priorityLane === "High") && (
                    <div className="UcpModalSection lowTopPadding">
                      <div className="orangeNotificationDiv">
                        <img src={require("../ImgAssets/OrangePolicyIcon.svg").default} />
                        Fault Tolerance Policy applied to {orderData.priorityLane} priority orders
                      </div>
                    </div>
                  )} */}
                  <div className="UcpModalSection lowTopPadding">
                    <div className="modalInputTitleWhite">Imaging Spectrum</div>
                    <div className="modalDivLine">
                      {orderOptions.imagingSpectrum.map((item, i) => (
                        <div className="modalInputBox">
                          <button
                            name="imagingSpectrum"
                            onClick={(e) => handleChange(e, item.label)}
                            className={orderData.imagingSpectrum === item.label ? "modalInputCircularButton active" : "modalInputCircularButton"}
                          />
                          <div className="modalInputBoxText" style={orderData.imagingSpectrum === item.label ? {} : { opacity: "0.5" }}>
                            {item.label}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="modalDivLine">
                      <button
                        name="isAdvancedDetails"
                        className={orderData.isAdvancedDetails ? "modalGreenTransparentBtn active" : "modalGreenTransparentBtn"}
                        onClick={(e) => handleChange(e, !orderData.isAdvancedDetails)}
                      >
                        Advanced details
                      </button>
                    </div>
                    {orderData.isAdvancedDetails && (
                      <>
                        <div className="modalDivLine">
                          <div className="modalInputTitleWhite">Acquisition Requirement</div>
                        </div>

                        {/* <div className="modalDivLine">
                          <div className="modalInputTitleWhite">GSD</div>
                        </div>

                        <div className="modalDivLine modalInputFieldAlign">
                          <div className="modalInputFieldText">
                            <div className="modalInputBoxText">Min</div>
                            <input
                              name="gsdMin"
                              type="number"
                              min={0}
                              value={orderData.gsdMin}
                              className="modalInputNumField"
                              placeholder="in m"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="modalInputFieldText" style={{ opacity: "0.5" }}>
                            To
                          </div>
                          <div className="modalInputFieldText">
                            <div className="modalInputBoxText">Max</div>
                            <input name="gsdMax"
                              type="number"
                              min={0}
                              value={orderData.gsdMax}
                              className="modalInputNumField"
                              placeholder="in m"
                              onChange={handleChange}
                            />
                          </div>
                        </div> */}

                        <div className="modalDivLine">
                          <div className="modalInputTitleWhite">Nadir Angle</div>
                        </div>
                        <div className="modalDivLine modalInputFieldAlign">
                          <div className="modalInputFieldText">
                            <div className="modalInputBoxText">Min</div>
                            <input name="nadirMin"
                              type="number"
                              min={-10}
                              max={10}
                              value={orderData.nadirMin}
                              className="modalInputNumField"
                              placeholder="in deg"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="modalInputFieldText" style={{ opacity: "0.5" }}>
                            To
                          </div>
                          <div className="modalInputFieldText">
                            <div className="modalInputBoxText">Max</div>
                            <input name="nadirMax"
                              type="number"
                              min={-30}
                              max={30}
                              value={orderData.nadirMax}
                              className="modalInputNumField"
                              placeholder="in deg"
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="modalDivLine">
                          <div className="modalInputTitleWhite">Sun Angle</div>
                        </div>
                        <div className="modalDivLine modalInputFieldAlign">
                          <div className="modalInputFieldText">
                            <div className="modalInputBoxText">Min</div>
                            <input name="sunAngleMin"
                              type="number"
                              min={10}
                              value={orderData.sunAngleMin}
                              className="modalInputNumField"
                              placeholder="in deg"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="modalInputFieldText" style={{ opacity: "0.5" }}>
                            To
                          </div>
                          <div className="modalInputFieldText">
                            <div className="modalInputBoxText">Max</div>
                            <input name="sunAngleMax"
                              type="number"
                              max={90}
                              value={orderData.sunAngleMax}
                              className="modalInputNumField"
                              placeholder="in deg"
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="UcpModalSection modalInputFieldAlign noPadding" style={{ padding: "12px 0px" }}>
                          <div className="modalInputBoxText" style={{ fontSize: "14px" }}>
                            Maximum Cloud Coverage
                          </div>
                          <input name="cloudCoverage"
                            type="number"
                            min={0}
                            value={orderData.cloudCoverage}
                            className="modalInputNumField"
                            placeholder="in %"
                            onChange={handleChange}
                            style={{ fontSize: "12px" }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="UcpModalSection noTopPadding">
                    <div className="modalDivLine noTopPadding">
                      <div className="modalInputBox">
                        <button className="modalInputRoundedBoxButton"
                          name="isRecurring"
                          onClick={(e) => handleChange(e, !orderData.isRecurring)}
                        />
                        {orderData.isRecurring &&
                          <img className="singleTickIcon"
                            src={require("../ImgAssets/SingletickIcon.svg").default}
                            alt="single-tick" />}
                        <div className={!orderData.isRecurring ? "modalInputBoxText" : "modalInputBoxText noPadding"}>Recurring</div>
                      </div>
                    </div>
                    <div className="noTopPadding">
                      <Dropdown
                        className="reactDropdownRootClass orderDropdownFields"
                        controlClassName="reactDropdownClass orderDropdownClass"
                        options={orderOptions.recurringType}
                        value={orderData.selectedRecurringType}
                        menuClassName="reactDropdownMenuClass orderDropdownMenuClass"
                        arrowClassName="reactDropdownArrowClassName"
                        placeholder="Select an option"
                        name="selectedRecurringType"
                        onChange={(e) => onSelectChange(e, 'selectedRecurringType')}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className={orderCreationStage.cue ? "activeSectionModal" : "inActiveSectionModal"}>
              <div className="sectionDivLine topBorder">
                <button onClick={() => setOrderCreationStage({ details: false, imaging: false, cue: true })} className="dropdownIconWhite">
                  <img
                    className={orderCreationStage.cue ? "dropdownIcon open" : "dropdownIcon close"}
                    src={orderCreationStage.cue ? require("../ImgAssets/DropdownWhiteOpen.svg").default : require("../ImgAssets/DropdownWhiteClose.svg").default}
                  />
                </button>
                <div className="sectionHeaderText">Tip & Cue</div>
              </div>
              {orderCreationStage.cue && (
                <>
                  <div className="UcpModalSection modalScrollableSection lowTopPadding">
                    {/* <div className="orangeNotificationDiv">
                        <img src={require("../ImgAssets/OrangePolicyIcon.svg").default} />4 Policies have been applied to this order
                      </div> */}
                    <button
                      className="addObjectsBtn unAddedObjectsBtn"
                      style={{ marginBottom: "12px" }}
                      onClick={() => props.setIsNewPolicyCreationModal(true)}
                    >
                      + Add Policy
                    </button>
                    {/* <div className="modalInputTitleWhite">Tip</div>
                      <div className="modalDivLine modalInputTitleWhite subHeader">Objects</div>
                      <button className="addObjectsBtn unAddedObjectsBtn"> Add Objects</button>
                      <div className="modalDivLine modalInputTitleWhite subHeader">Events</div>
                      <button className="addObjectsBtn"> Select Events </button>
                      <div className="modalInputTitleWhite" style={{ marginTop: "12px" }}>
                        Cue
                      </div>

                      <div className="modalDivLine modalInputTitleWhite subHeader">Cue for Satellite</div>
                      <div className="modalInputBox" style={{ margin: "8px 0px" }}>
                        <div className={cueForSatellite.highResImages ? "floatLeft" : "floatLeft lowerOpacity"}>
                          <button
                            className="modalInputRoundedBoxButton"
                            onClick={() => setCueForSatellite({ ...cueForSatellite, highResImages: !cueForSatellite.highResImages })}
                          ></button>
                          {cueForSatellite.highResImages && <img className="singleTickIcon" src={require("../ImgAssets/SingletickIcon.svg").default} />}
                          <div className={!cueForSatellite.highResImages ? "modalInputBoxText" : "modalInputBoxText noPadding"}>High Resolution Images</div>
                        </div>
                        <div className={cueForSatellite.frequency ? "floatLeft" : "floatLeft lowerOpacity"} style={{ marginLeft: "12px" }}>
                          <button className="modalInputRoundedBoxButton" onClick={() => setCueForSatellite({ ...cueForSatellite, frequency: !cueForSatellite.frequency })}></button>
                          {cueForSatellite.frequency && <img className="singleTickIcon" src={require("../ImgAssets/SingletickIcon.svg").default} />}
                          <div className={!cueForSatellite.frequency ? "modalInputBoxText" : "modalInputBoxText noPadding"}>Frequency</div>
                        </div>
                      </div>

                      <div className="modalInputTitleWhite subHeader">Cue for Ground</div>
                      <div className="modalInputBox" style={{ margin: "8px 0px" }}>
                        <div className={cueForGround.alerts ? "floatLeft" : "floatLeft lowerOpacity"}>
                          <button className="modalInputRoundedBoxButton" onClick={() => setCueForGround({ ...cueForGround, alerts: !cueForGround.alerts })}></button>
                          {cueForGround.alerts && <img className="singleTickIcon" src={require("../ImgAssets/SingletickIcon.svg").default} />}
                          <div className={!cueForGround.alerts ? "modalInputBoxText" : "modalInputBoxText noPadding"}>Alerts</div>
                        </div>
                        <div className={cueForGround.telemetry ? "floatLeft" : "floatLeft lowerOpacity"} style={{ marginLeft: "12px" }}>
                          <button className="modalInputRoundedBoxButton" onClick={() => setCueForGround({ ...cueForGround, telemetry: !cueForGround.telemetry })}></button>
                          {cueForGround.telemetry && <img className="singleTickIcon" src={require("../ImgAssets/SingletickIcon.svg").default} />}
                          <div className={!cueForGround.telemetry ? "modalInputBoxText" : "modalInputBoxText noPadding"}>Telemetry</div>
                        </div>
                        <div className={cueForGround.imagery ? "floatLeft" : "floatLeft lowerOpacity"} style={{ marginLeft: "12px" }}>
                          <button className="modalInputRoundedBoxButton" onClick={() => setCueForGround({ ...cueForGround, imagery: !cueForGround.imagery })}></button>
                          {cueForGround.imagery && <img className="singleTickIcon" src={require("../ImgAssets/SingletickIcon.svg").default} />}
                          <div className={!cueForGround.imagery ? "modalInputBoxText" : "modalInputBoxText noPadding"}>Imagery</div>
                        </div>
                      </div>

                      <div className="modalDivLine modalInputTitleWhite subHeader">
                        Frequency
                        <Dropdown
                          className="reactDropdownRootClass halfWidth"
                          controlClassName="reactDropdownClass halfWidth"
                          options={options}
                          value={defaultOption}
                          menuClassName="reactDropdownMenuClass halfWidth"
                          arrowClassName="reactDropdownArrowClassName"
                          placeholder="Select an option"
                        />
                      </div>*/}
                  </div>
                </>
              )}
            </div>
          </>
        )}

        <div className="orderCreationModalFooter topBorder">
          <button className={!orderCreationStage.details ? "modalFooterCloseBtn" : "modalFooterCloseBtn disabledBtn"} onClick={() => prevButtonFunc()}>
            Back
          </button>
          {!orderCreationStage.cue ?
            (
              <button
                className={disableNextButton() ?
                  "modalFooterApplyBtn disabledBtn" : "modalFooterApplyBtn"}
                disabled={disableNextButton()}
                onClick={() => goToNextPage()}>
                Next
              </button>
            ) :
            (
              <button className="modalFooterApplyBtn" onClick={() => placeOrder()}>
                Place Order
              </button>
            )
          }
        </div>
      </div>
    </>
  );
};

export default OrderCreationModal;
