import { useEffect } from 'react';
import { CoverageAnalysisIcon, GroundStationAnalysisIcon } from '../../commonMMSvgs/MissionModelingSvgs';
import './bottomBar.css'
const BottomBar = ({ openedCreationModal, setOpenedCreationModal }) => {
    return (
        <div className='bottombar_container'>
            <div className={`svgContainerBottomBar ${openedCreationModal.coverageAnalysisModal ? `activeAnalysis coverage_analysis` : ``}`} onClick={() => {
                setOpenedCreationModal({
                    coverageAnalysisModal: true
                })
            }}>
                <CoverageAnalysisIcon />
                <span className="tooltipText">Coverage Analysis</span>
            </div>

            <div className={`svgContainerBottomBar ${openedCreationModal.groundStationanlysisModal ? `activeAnalysis gs_analysis` : ``}`} onClick={() => {
                setOpenedCreationModal({
                    groundStationanlysisModal: true
                })
            }}>
                <GroundStationAnalysisIcon />
                <span className="tooltipText">Ground Contact Analysis</span>
            </div>
        </div>
    );
};

export default BottomBar;
