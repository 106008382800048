import { Form } from "react-bootstrap";
import ModalInput from "../../../commonComponents/ModalInput";
import { useState } from "react";
import { toast } from "react-toastify";


const WalkerDeltaConstellation = ({ walkerDeltaConstellation, setWalkerDeltaConstellation }) => {

    const [planeSaperation, setPlaneSaperation] = useState(false)
    const [internalPlanePhasing, setInternalPlanePhasing] = useState(false)


    const handleNoOfPlaneChange = (value) => {
        setWalkerDeltaConstellation({
            ...walkerDeltaConstellation,
            noOfPlanes: parseFloat(value),
            separationAngleBetweenPlane: planeSaperation ? (360 / parseFloat(value)) : walkerDeltaConstellation?.['separationAngleBetweenPlane']
        })
    }

    const handleSatPerPlaneChange = (value) => {
        setWalkerDeltaConstellation({
            ...walkerDeltaConstellation,
            noOfSatellitesPerPlane: parseFloat(value),
            interAnglePhasingForWalkerDelta: internalPlanePhasing ? (360 / parseFloat(value)) : walkerDeltaConstellation?.['interAnglePhasingForWalkerDelta']
        })
    }

    const handlePlaneSeparationAngleChange = (value) => {
        setWalkerDeltaConstellation({
            ...walkerDeltaConstellation,
            separationAngleBetweenPlane: parseFloat(value)
        })
    }

    const handleIppChange = (value) => {
        setWalkerDeltaConstellation({
            ...walkerDeltaConstellation,
            interAnglePhasingForWalkerDelta: parseFloat(value)
        })
    }

    return (
        <div style={{ width: "100%" }}>
            <div className='orbital_body_item'>
                <span>Number of Planes</span>
                <div>
                    <ModalInput
                        inputType="number"
                        handleChange={handleNoOfPlaneChange}
                        value={walkerDeltaConstellation.noOfPlanes} />
                </div>
            </div>
            <div className='orbital_body_item'>
                <span>Satellites per Plane</span>
                <div>
                    <ModalInput
                        disabled={!walkerDeltaConstellation.noOfPlanes}
                        inputType="number"
                        handleChange={handleSatPerPlaneChange}
                        value={walkerDeltaConstellation.noOfSatellitesPerPlane} />
                </div>
            </div>
            <div className='orbital_body_item'>
                <div>
                    <span>Plane Separation Angle(Δθ)</span>
                    <div className='wd_panel_band'>
                        <Form.Check
                            type="checkbox"
                            label=""
                            checked={planeSaperation}
                            onChange={(e) => {
                                if (planeSaperation === false) {
                                    if (walkerDeltaConstellation.noOfPlanes && parseFloat(walkerDeltaConstellation.noOfPlanes) >= 2) {
                                        setPlaneSaperation(!planeSaperation)
                                        setWalkerDeltaConstellation({
                                            ...walkerDeltaConstellation,
                                            separationAngleBetweenPlane: (360 / parseFloat(walkerDeltaConstellation.noOfPlanes))
                                        })
                                    } else {
                                        toast.error("Please Enter No of plane")
                                    }
                                } else {
                                    setPlaneSaperation(!planeSaperation)
                                }
                            }}
                        />
                        <span>Spread across</span>
                    </div>
                </div>
                <div>
                    <ModalInput
                        inputType="number"
                        unit={'°'}
                        handleChange={handlePlaneSeparationAngleChange}
                        value={walkerDeltaConstellation.separationAngleBetweenPlane}
                        disabled={planeSaperation}
                    />
                </div>
            </div>

            <div className='orbital_body_item'>
                <div>
                    <span>Internal Plane Phasing</span>
                    <div className='wd_panel_band'>
                        <Form.Check
                            type="checkbox"
                            label=""
                            checked={internalPlanePhasing}
                            onChange={(e) => {
                                if (internalPlanePhasing === false) {
                                    if (walkerDeltaConstellation.noOfSatellitesPerPlane) {
                                        setInternalPlanePhasing(!internalPlanePhasing)
                                        setWalkerDeltaConstellation({
                                            ...walkerDeltaConstellation,
                                            interAnglePhasingForWalkerDelta: (360 / parseFloat(walkerDeltaConstellation.noOfSatellitesPerPlane))
                                        })
                                    } else {
                                        toast.error("Enter No of satellite per plane")
                                    }
                                } else {
                                    setInternalPlanePhasing(!internalPlanePhasing)
                                }
                            }}
                        />
                        <span>Spread across</span>
                    </div>
                </div>
                <div>
                    <ModalInput
                        inputType="number"
                        unit={'°'}
                        handleChange={handleIppChange}
                        value={walkerDeltaConstellation.interAnglePhasingForWalkerDelta}
                        disabled={internalPlanePhasing}
                    />
                </div>

            </div>
        </div>
    );
};

export default WalkerDeltaConstellation;
