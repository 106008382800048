import { useEffect } from "react";
import Chart from "chart.js/auto";
import { useChartOptions } from "./ChartOptions";
import AnalyticsBarFilters from "./AnalyticsBarFilters";

const RightSideAnalyticsBar = ({ props }) => {
  //Defining default values of chart

  Chart.defaults.elements.line.borderWidth = 1;
  Chart.defaults.elements.point.radius = 1;
  const chartOptionsSettings = useChartOptions();

  useEffect(() => {
    const yAxisData = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"];
    const interceptsData = [10, 20, 15, 25, 22, 30, 28];
    const overlapsData = [20, 40, 25, 37, 12, 8, 28];
    const extraneousData = [0, 20, 35, 25, 32, 5, 28];

    const chartDom = document.getElementById("imagingEffectivenessChart");
    const imagingChart = new Chart(chartDom, {
      type: "line",
      options: chartOptionsSettings,
      data: {
        labels: yAxisData,
        datasets: [
          {
            label: "Intercepts",
            data: interceptsData,
          },
          {
            label: "Overlaps",
            data: overlapsData,
          },
          {
            label: "Extraneous",
            data: extraneousData,
          },
        ],
      },
    });
    return () => {
      imagingChart.destroy();
    };
  }, []);

  useEffect(() => {
    const yAxisData = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"];
    const groundContactsData = [14, 7, 23, 34, 36, 9, 2];
    const powerData = [25, 30, 20, 5, 12, 6, 40];
    const storageData = [8, 35, 16, 37, 30, 17, 39];

    const chartDom = document.getElementById("operationalDisruptionChart");
    const opDisruptionChart = new Chart(chartDom, {
      type: "line",
      options: chartOptionsSettings,
      data: {
        labels: yAxisData,
        datasets: [
          {
            label: "Ground Contacts",
            data: groundContactsData,
          },
          {
            label: "Power",
            data: powerData,
          },
          {
            label: "Storage",
            data: storageData,
          },
        ],
      },
    });
    return () => {
      opDisruptionChart.destroy();
    };
  }, []);

  useEffect(() => {
    const yAxisData = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"];
    const constellationData = [32, 14, 27, 3, 23, 17, 4];
    const imagerData = [10, 31, 27, 39, 35, 15, 26];
    const edgeData = [23, 38, 15, 19, 24, 2, 40];
    const commsData = [12, 21, 7, 36, 18, 39, 27];

    const chartDom = document.getElementById("utilisationChart");
    const utilisationChart = new Chart(chartDom, {
      type: "line",
      options: chartOptionsSettings,
      data: {
        labels: yAxisData,
        datasets: [
          {
            label: "Constellation",
            data: constellationData,
          },
          {
            label: "Imager",
            data: imagerData,
          },
          {
            label: "Edge",
            data: edgeData,
          },
          {
            label: "Comms",
            data: commsData,
          },
        ],
      },
    });
    return () => {
      utilisationChart.destroy();
    };
  }, []);

  return (
    <div className="rightSideAnalyticsBar">
      {/* <div className="analyticsFeaturesBar">
        <SubOrdersBarFeaturesList props={props} />
      </div> */}
      <AnalyticsBarFilters props={props}/>
      {/* Imaging effectiveness chart */}
      <div className="analyticsTopRightChartDiv">
        <div className="anlyticsTopRightChartHeaderDiv">
          <div>Imaging Effectiveness</div>
          <div>98%</div>
        </div>
        <div className="anlyticsTopRightChart">
          <canvas id="imagingEffectivenessChart"></canvas>
        </div>
      </div>

      {/* Operational Disruption chart */}
      <div className="analyticsTopRightChartDiv">
        <div className="anlyticsTopRightChartHeaderDiv">
          <div>Operational Disruption</div>
          <div>5%</div>
        </div>
        <div className="anlyticsTopRightChart">
          <canvas id="operationalDisruptionChart"></canvas>
        </div>
      </div>

      {/* Utilisation chart */}
      <div className="analyticsTopRightChartDiv">
        <div className="anlyticsTopRightChartHeaderDiv">
          <div>Utilisation</div>
          <div style={{color:"#f33"}}>35%</div>
        </div>
        <div className="anlyticsTopRightChart">
          <canvas id="utilisationChart"></canvas>
        </div>
      </div>
    </div>
  );
};

export default RightSideAnalyticsBar;
